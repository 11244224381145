<div class="col-xs-12 padding-left-sm-xs padding-right-sm-xs padding-left-lg-sm padding-right-lg-sm">
  <p
    class="col-xs-12 padding-none-xs margin-top-md-xs margin-bottom-sm-xs dosis-bold text-18-xs text-24-sm text-left text-bold text-blue3-xs"
  >
    {{ 'home.contracts.clause.title' | translate }}
  </p>
  <div class="col-xs-12 padding-none-xs">
    <div class="col-xs-12 padding-md-xs background-white-xs border-grey3-xs-xs">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
