import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { User } from '@core/models';
import { DataStatus } from '@core/models/data-status';
import { ROLE } from '@core/models/role.model';
import { AlphabeticValidatorDirective } from '@core/validators/alphabetic-validator.directive';
import { EmailValidatorDirective } from '@core/validators/email-validator.directive';
import { MobilePhoneValidatorDirective } from '@core/validators/mobile-phone-validator.directive';
import { PhoneValidatorDirective } from '@core/validators/phone-validator.directive';
import { AccountsService } from '@shared/services/accounts.service';

@Component({
  selector: 'n9-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss']
})
export class AccountDetailsComponent implements OnInit {
  user: User;
  isAdmin: boolean;
  userEditForm: FormGroup;
  dataSent: DataStatus;
  ds: typeof DataStatus = DataStatus;
  keys: any = Object.keys;
  availableRoles: object = {};

  constructor(
    private formBuilder: FormBuilder,
    private accountsService: AccountsService,
    private route: ActivatedRoute
  ) {
    this.userEditForm = this.formBuilder.group({
      idGest: [null],
      lastname: [null, [Validators.required, AlphabeticValidatorDirective.validInput]],
      firstname: [null, [Validators.required, AlphabeticValidatorDirective.validInput]],
      phoneNumber: [null, [PhoneValidatorDirective.validInput]],
      mobilePhoneNumber: [null, [MobilePhoneValidatorDirective.validInput]],
      email: [null, [Validators.required, EmailValidatorDirective.validEmail]],
      roles: [null]
    });

    for (const role in ROLE) {
      if (role !== ROLE.ROLE_USER) this.availableRoles[role] = role.substr(5);
    }
  }

  public ngOnInit(): void {
    this.route.params.subscribe((param) =>
      this.accountsService.getUserBOByLogin(param['idGest']).subscribe(
        (user: User) => {
          this.user = user;
          this.userEditForm.patchValue(this.user);
        },
        (err) => {}
      )
    );
    this.dataSent = DataStatus.WAITING;
  }

  public onSubmit(event: any): void {
    if (this.userEditForm.valid) {
      const data: User = { ...this.user, ...this.userEditForm.getRawValue() };

      this.dataSent = DataStatus.PENDING;
      this.accountsService.updateUserBo(data).subscribe(
        (res) => {
          // this.adminNavigationService.navigateToRoute("/admin/home/accounts")
          this.dataSent = DataStatus.SUCCESS;
        },
        (err) => {
          this.dataSent = DataStatus.ERROR;
        }
      );
    }
  }

  get userControls(): object {
    return {
      lastname: this.userEditForm.get('lastname'),
      firstname: this.userEditForm.get('firstname'),
      birthDate: this.userEditForm.get('birthDate'),
      mobilePhoneNumber: this.userEditForm.get('mobilePhoneNumber'),
      phoneNumber: this.userEditForm.get('phoneNumber'),
      email: this.userEditForm.get('email'),
      roles: this.userEditForm.get('roles')
    };
  }
}
