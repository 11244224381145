import { Injectable } from '@angular/core';
import { LogService } from '../services/log/log.service';
import { User } from '../models';
import { StoreService } from '../store/store.service';
import { Session } from './session.models';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SessionService {
  public static readonly sessionKey: string = 'session';

  constructor(private logService: LogService, private store: StoreService, private http: HttpClient) {}

  load(): void {
    const session: Session = this.store.get(SessionService.sessionKey);
    if (!session) return;
    this.openSession(session);
  }

  logout(): void {
    this.closeSession();
  }

  getSession(): Session {
    return this.store.get(SessionService.sessionKey);
  }

  setUser(user: User): Session {
    const session = this.getSession();
    if (user && session) {
      session.profile = user;
      this.store.set(SessionService.sessionKey, session);
    }
    return session;
  }

  setThreadMessagePage(page: number): Session {
    const session = this.getSession();
    if (page && session) {
      session.threadMessagePage = page;
      this.store.set(SessionService.sessionKey, session);
    }
    return session;
  }

  setCurrentlyUsedManager(manager: User): Session {
    const session = this.getSession();
    if (manager && session) {
      session.manager = manager;
      this.store.set(SessionService.sessionKey, session);
    }
    return session;
  }

  getCurrentlyUsedManager(): User {
    const session =
      (this.store.get(SessionService.sessionKey) && (this.store.get(SessionService.sessionKey) as Session)) || null;
    if (session) {
      return session.manager;
    }
  }

  getUser(): User {
    const session =
      (this.store.get(SessionService.sessionKey) && (this.store.get(SessionService.sessionKey) as Session)) || null;
    if (session) {
      return session.profile;
    }
  }

  getThreadMessagePage(): number {
    const session =
      (this.store.get(SessionService.sessionKey) && (this.store.get(SessionService.sessionKey) as Session)) || null;
    if (session) {
      return session.threadMessagePage;
    }
  }

  openSession(session: Session): void {
    this.store.set(SessionService.sessionKey, session);
  }

  private closeSession(): void {
    this.http.post(`/me/logout`, '').subscribe();
    this.store.del(SessionService.sessionKey);
  }
}
