import { MandateDeclaration } from './mande-declaration.model';
import { User } from './user.model';

export enum ActionType {
  USER_EDIT,
  CONTRIBUTION_RATE,
  MANDATE_DECLARE,
  LOG_IN,
  LOG_OUT
}

export interface Action {
  id: string;
  contractNumber: number;
  oldContributionRate: number;
  newContributionRate: number;
  userId: string;
  type: string;
  createdDateTime: Date;
  newUserData: User;
  mandateDeclaration: MandateDeclaration;
}
