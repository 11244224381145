export class FeatureUtils {
  public static getFeatureTabNumber(featureName: string): number {
    switch (featureName) {
      case 'personal-data':
        return 1;
      case 'contracts':
        return 2;
      case 'contact':
        return 3;
      case 'messages':
        return 4;
    }
  }

  public static getFeatureRoute(featureName: string): string {
    switch (featureName) {
      case 'personal-data':
        return '/home/profile';
      case 'contributions':
        return '/home';
      case 'messages':
        return '/home/threads';
      default:
        return `/home/${featureName}`;
    }
  }
}
