export class Notification {
  id: string;
  recipients: string[];
  type: NotificationType;
  subject: string;
  content: string;
  isTemplate: boolean;
  createdDateTime: Date;
  createdBy: string;
  lastModifiedDateTime: Date;
  updatedBy: string;
}

export enum NotificationType {
  DEFAULT = 'DEFAULT',
  LOGIN = 'LOGIN',
  EMAIL = 'EMAIL'
}
