import { Component } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'n9-admin',
  templateUrl: 'admin.component.html'
})
export class AdminComponent {
  constructor(private router: Router, private readonly route: ActivatedRoute) {
    this.router.navigate(['/admin/login']);
  }
}
