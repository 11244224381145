import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PasswordValidatorDirective } from '@core/validators/password-validator.directive';
import { PasswordSetService } from './password-set.service';
import { Router } from '@angular/router';

@Component({
  selector: 'n9-password-set',
  templateUrl: 'password-set.component.html'
})
export class PasswordSetComponent implements OnInit {
  public passwordResetForm: FormGroup;

  public constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private passwordService: PasswordSetService
  ) {
    this.passwordResetForm = this.formBuilder.group({
      newPassword: ['', [Validators.required, PasswordValidatorDirective.validInput]],
      confirmPassword: ['', [Validators.required]]
    });

    this.passwordResetForm.valueChanges.subscribe(() => this.validateForm());
  }

  public ngOnInit(): void {}

  public onSubmit(): void {
    this.passwordService.setPassword(this.passwordResetForm.get('newPassword').value).subscribe(
      () => {
        this.router.navigate(['/contracts']);
      },
      (err) => {}
    );
  }

  private validateForm(): void {
    const newPasswordValue = this.passwordResetForm.get('newPassword').value;
    const confirmPasswordValue = this.passwordResetForm.get('confirmPassword').value;
    let errors = this.passwordResetForm.controls['confirmPassword'].errors;

    if (newPasswordValue && confirmPasswordValue) {
      if (newPasswordValue !== confirmPasswordValue) errors = { pass: { valid: false } };
      else errors = null;

      this.passwordResetForm.controls['confirmPassword'].setErrors(errors);
    }
  }
}
