<form
  novalidate
  [formGroup]="userEditForm"
  (ngSubmit)="onSubmit($event)"
  class="form form-horizontal col-xs-12 padding-none-xs margin-none-xs"
>
  <div
    class="has-feedback col-xs-12 padding-none-xs margin-top-sm-xs"
    [ngClass]="{
      'has-error': userControls['lastname'].value && userControls['lastname'].errors,
      'has-success': userControls['lastname'].value && !userControls['lastname'].errors
    }"
  >
    <label
      class="col-xs-12 col-sm-5 padding-left-sm-xs padding-right-sm-xs margin-top-sm-xs text-left-xs text-right-sm text-14-xs text-12-sm text-blue6-xs openSans-bold"
      for="common-name"
    >
      {{ 'home.personalData.display.lastname' | translate }}
    </label>
    <div class="col-xs-12 col-sm-4 padding-left-sm-xs padding-right-sm-xs">
      <input class="form-control" type="text" id="common-name" formControlName="lastname" />
      <i
        *ngIf="!userControls['lastname'].errors"
        class="fa fa-check text-green2-xs form-control-feedback"
        aria-hidden="true"
      ></i>
      <i
        *ngIf="userControls['lastname'].errors"
        class="fa fa-times text-red1-xs form-control-feedback"
        aria-hidden="true"
      ></i>
    </div>
    <div *ngIf="userEditForm.controls.lastname.errors" class="col-xs-12 col-xs-offset-0 col-sm-4 col-sm-offset-5">
      <div
        [hidden]="!userEditForm.controls.lastname.errors.required"
        class="col-xs-12 padding-none-xs margin-top-xs-xs text-left text-10-xs text-red1-xs"
      >
        {{ 'home.personalData.form.errors.lastName.required' | translate }}
      </div>
      <div
        [hidden]="!userEditForm.controls.lastname.errors.n9Alphabetic"
        class="col-xs-12 padding-none-xs margin-top-xs-xs text-left text-10-xs text-red1-xs"
      >
        {{ 'home.personalData.form.errors.lastName.alphabetic' | translate }}
      </div>
    </div>
  </div>

  <div
    class="has-feedback col-xs-12 padding-none-xs margin-top-sm-xs"
    [ngClass]="{
      'has-error': userControls['firstname'].value && userControls['firstname'].errors,
      'has-success': userControls['firstname'].value && !userControls['firstname'].errors
    }"
  >
    <label
      class="col-xs-12 col-sm-5 padding-left-sm-xs padding-right-sm-xs margin-top-sm-xs text-left-xs text-right-sm text-14-xs text-12-sm text-blue6-xs openSans-bold"
      for="prenom"
    >
      {{ 'home.personalData.display.firstname' | translate }}
    </label>
    <div class="col-xs-12 col-sm-4 padding-left-sm-xs padding-right-sm-xs">
      <input class="form-control" type="text" id="prenom" formControlName="firstname" />
      <i
        *ngIf="!userControls['firstname'].errors"
        class="fa fa-check text-green2-xs form-control-feedback"
        aria-hidden="true"
      ></i>
      <i
        *ngIf="userControls['firstname'].errors"
        class="fa fa-times text-red1-xs form-control-feedback"
        aria-hidden="true"
      ></i>
    </div>

    <div *ngIf="userEditForm.controls.firstname.errors" class="col-xs-12 col-xs-offset-0 col-sm-4 col-sm-offset-5">
      <div
        [hidden]="!userEditForm.controls.firstname.errors.required"
        class="col-xs-12 padding-none-xs margin-top-xs-xs text-left text-10-xs text-red1-xs"
      >
        {{ 'home.personalData.form.errors.firstName.required' | translate }}
      </div>
      <div
        [hidden]="!userEditForm.controls.firstname.errors.n9Alphabetic"
        class="col-xs-12 padding-none-xs margin-top-xs-xs text-left text-10-xs text-red1-xs"
      >
        {{ 'home.personalData.form.errors.firstName.alphabetic' | translate }}
      </div>
    </div>
  </div>

  <div
    class="has-feedback col-xs-12 padding-none-xs margin-top-sm-xs"
    [ngClass]="{
      'has-error': userControls['mobilePhoneNumber'].value && userControls['mobilePhoneNumber'].errors,
      'has-success': userControls['mobilePhoneNumber'].value && !userControls['mobilePhoneNumber'].errors
    }"
  >
    <label
      class="col-xs-12 col-sm-5 padding-left-sm-xs padding-right-sm-xs margin-top-sm-xs text-left-xs text-right-sm text-14-xs text-12-sm text-blue6-xs openSans-bold"
      for="mobilephone-number"
    >
      {{ 'home.personalData.display.mobilePhoneNumber' | translate }}
    </label>
    <div class="col-xs-12 col-sm-4 padding-left-sm-xs padding-right-sm-xs">
      <input class="form-control" type="tel" id="mobilephone-number" formControlName="mobilePhoneNumber" />
      <i
        *ngIf="!userControls['mobilePhoneNumber'].errors"
        class="fa fa-check text-green2-xs form-control-feedback"
        aria-hidden="true"
      ></i>
      <i
        *ngIf="userControls['mobilePhoneNumber'].errors"
        class="fa fa-times text-red1-xs form-control-feedback"
        aria-hidden="true"
      ></i>
    </div>

    <div *ngIf="userEditForm.controls.mobilePhoneNumber.errors" class="col-xs-12 col-sm-4 col-sm-offset-5">
      <div
        [hidden]="!userEditForm.controls.mobilePhoneNumber.errors.required"
        class="col-xs-12 padding-none-xs margin-top-xs-xs text-left text-14-xs text-red1-xs"
      >
        {{ 'home.personalData.form.errors.mobilePhoneNumber.required' | translate }}
      </div>
      <div
        [hidden]="!userEditForm.controls.mobilePhoneNumber.errors.n9MobilePhone"
        class="col-xs-12 padding-none-xs margin-top-xs-xs text-left text-14-xs text-red1-xs"
      >
        {{ 'home.personalData.form.errors.mobilePhoneNumber.pattern' | translate }}
      </div>
    </div>
  </div>

  <div
    class="has-feedback col-xs-12 padding-none-xs margin-top-sm-xs"
    [ngClass]="{
      'has-error': userControls['phoneNumber'].value && userControls['phoneNumber'].errors,
      'has-success': userControls['phoneNumber'].value && !userControls['phoneNumber'].errors
    }"
  >
    <label
      class="col-xs-12 col-sm-5 padding-left-sm-xs padding-right-sm-xs margin-top-sm-xs text-left-xs text-right-sm text-14-xs text-12-sm text-blue6-xs openSans-bold"
      for="phone-number"
    >
      {{ 'home.personalData.display.phoneNumber' | translate }}
    </label>
    <div class="col-xs-12 col-sm-4 padding-left-sm-xs padding-right-sm-xs">
      <input class="form-control" type="tel" id="phone-number" formControlName="phoneNumber" />
      <i
        *ngIf="userControls['phoneNumber'].errors"
        class="fa fa-times text-red1-xs form-control-feedback"
        aria-hidden="true"
      ></i>
    </div>

    <div *ngIf="userEditForm.controls.phoneNumber.errors" class="col-xs-12 col-sm-4 col-sm-offset-5">
      <div
        [hidden]="!userEditForm.controls.phoneNumber.errors.n9Phone"
        class="col-xs-12 padding-none-xs margin-top-xs-xs text-left text-14-xs text-red1-xs"
      >
        {{ 'home.personalData.form.errors.phoneNumber.pattern' | translate }}
      </div>
    </div>
  </div>

  <div
    class="has-feedback col-xs-12 padding-none-xs margin-top-sm-xs"
    [ngClass]="{
      'has-error': userControls['email'].value && userControls['email'].errors,
      'has-success': userControls['email'].value && !userControls['email'].errors
    }"
  >
    <label
      class="col-xs-12 col-sm-5 padding-left-sm-xs padding-right-sm-xs margin-top-sm-xs text-left-xs text-right-sm text-14-xs text-12-xs text-blue6-xs openSans-bold"
      for="word-email"
    >
      {{ 'home.personalData.display.personalEmail' | translate }}
    </label>
    <div class="col-xs-12 col-sm-4 padding-left-sm-xs padding-right-sm-xs">
      <input class="form-control" type="email" id="word-email" formControlName="email" />
      <i
        *ngIf="!userEditForm.controls.email.errors; else emailError"
        class="fa fa-check text-green2-xs form-control-feedback"
        aria-hidden="true"
      ></i>
      <ng-template #emailError>
        <i class="fa fa-times text-red1-xs form-control-feedback" aria-hidden="true"></i>
      </ng-template>
    </div>

    <div *ngIf="userEditForm.controls.email.errors" class="col-xs-12 col-xs-offset-0 col-sm-4 col-sm-offset-5">
      <div
        [hidden]="!userEditForm.controls.email.errors.n9Email"
        class="col-xs-12 padding-none-xs margin-top-xs-xs text-left text-10-xs text-red1-xs"
      >
        {{ 'home.personalData.form.errors.userEmail.email' | translate }}
      </div>
    </div>
  </div>

  <div class="col-xs-12 margin-top-sm-xs">
    <label
      for="roles"
      class="col-xs-12 col-sm-5 padding-left-sm-xs padding-right-sm-xs margin-top-sm-xs text-left-xs text-right-sm text-14-xs text-12-xs text-blue6-xs openSans-bold"
      >{{ 'admin.accounts.form.roles' | translate }}:
    </label>
    <div class="col-xs-12 col-sm-4 padding-left-sm-xs padding-right-sm-xs">
      <select id="roles" formControlName="roles" class="form-control">
        <option *ngFor="let role of keys(availableRoles)" value="{{ role }}">
          {{ role }}
        </option>
      </select>
    </div>
  </div>

  <div class="col-xs-12 margin-top-sm-xs">
    <p
      *ngIf="dataSent !== ds.WAITING"
      class="openSans-bold margin-bottom-sm-xs text-center text-16-xs"
      [ngClass]="
        dataStatus == ds.PENDING ? 'text-grey4-xs' : dataSent == ds.SUCCESS ? 'text-green2-xs' : 'text-red1-xs'
      "
    >
      {{
        'admin.accounts.details.submit.' +
          (dataSent == ds.PENDING
            ? 'pending'
            : dataSent == ds.SUCCESS
            ? 'success'
            : dataSent == ds.FORBIDDEN
            ? 'errorDuplicate'
            : 'error') | translate
      }}
    </p>
  </div>

  <div class="col-xs-12 padding-none-xs margin-bottom-md-xs margin-top-sm-xs margin-top-md-xs">
    <div class="col-xs-12 col-xs-offset-0 col-sm-offset-5 col-sm-4 padding-left-sm-xs padding-right-sm-xs">
      <button
        type="submit"
        class="col-xs-12 btn btn-default dosis-bold padding-sm-xs text-uppercase text-white-xs background-red1-xs border-radius-none-xs"
        [disabled]="!userEditForm.valid"
      >
        {{ 'home.personalData.form.submit.button' | translate }}
      </button>
    </div>
  </div>
</form>
