import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { BehaviorSubject } from 'rxjs';
import { User } from '@core/models';
import { Notification } from '@core/models/notification.model';
import { MandatesService } from '@modules/subscription/mandates/mandates.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'n9-multi-contact',
  templateUrl: './multi-contact.component.html',
  styleUrls: ['./multi-contact.component.scss']
})
export class MultiContactComponent implements OnInit {
  @Input() users$: BehaviorSubject<User[]>;

  usersSearch: string[];
  modalUsers: User[];
  multiContactForm: FormGroup;
  isNotificationSent: boolean = null;
  pageableSavedNotifications: object;
  modalRef: BsModalRef;

  constructor(
    public mandatesService: MandatesService,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private modalService: BsModalService
  ) {
    this.multiContactForm = this.fb.group({
      userInput: [''],
      filters: this.fb.array([]),
      type: ['', Validators.required],
      recipients: this.fb.array([], Validators.required),
      subject: ['', Validators.required],
      content: ['', Validators.required]
    });
  }

  public ngOnInit(): void {
    this.getSavedNotification();
    this.mandatesService.getReferential('departements');
    this.users$.subscribe(() => {
      if (this.users$.value.length > 0) {
        this.usersSearch = this.users$.value.map((u) => this.fromUserToTypeahead(u));
      }
    });

    this.multiContactForm.get('filters').valueChanges.subscribe((values) => {
      this.filterByDepartments();
    });

    this.multiContactForm.get('recipients').valueChanges.subscribe((values: string[]) => {
      this.modalUsers = values.map((u) => this.fromTypeaheadToUser(u));
    });
  }

  public onSubmit(): void {
    this.notificationService
      .postNotificationForUsers(this.multiContactForm.getRawValue())
      .take(1)
      .subscribe(
        (res) => (this.isNotificationSent = true),
        (err) => (this.isNotificationSent = false)
      );
  }

  public onSelect(event: TypeaheadMatch): void {
    const user = event.value;

    this.addUser(user);
    this.multiContactForm.get('userInput').setValue('');
  }

  public getUsersArray(): FormArray {
    return this.multiContactForm.get('recipients') as FormArray;
  }

  public fromUserToTypeahead(user: User): string {
    return `${user.firstname} ${user.lastname} (n°${user.eluNumber})`;
  }

  public fromTypeaheadToUser(user: string): User {
    return this.users$.value.find((u) => u.eluNumber === user.match(/\d+/g).toString());
  }

  public addUser(user: string): void {
    const usersArray: FormArray = this.getUsersArray();

    if (!usersArray.getRawValue().includes(user)) {
      usersArray.push(new FormControl(user));
      this.usersSearch = this.usersSearch.filter((u) => u !== user);
    }
  }

  public removeUser(user: string): void {
    const usersArray: FormArray = this.getUsersArray();

    if (usersArray.getRawValue().includes(user)) {
      const id = usersArray.getRawValue().indexOf(user);

      usersArray.removeAt(id);
      this.usersSearch.push(user);
    }
  }

  public fillForm(notification: Notification): void {
    this.multiContactForm.get('type').setValue(notification.type);
    this.multiContactForm.get('subject').setValue(notification.subject);
    this.multiContactForm.get('content').setValue(notification.content);
    this.multiContactForm.updateValueAndValidity();
  }

  public filterByDepartments(): void {
    const departments: string[] = this.multiContactForm.get('filters').value;

    this.users$.value.map((u: User) => {
      if (u.postalCode) {
        const user: string = this.fromUserToTypeahead(u);

        if (departments.includes(u.postalCode.substr(0, 2))) {
          this.addUser(user);
        } else {
          this.removeUser(user);
        }
      }
    });
  }

  public saveNotification(): void {
    const values: Notification = this.multiContactForm.getRawValue() as Notification;

    if (values.content && values.type && values.subject) {
      values.isTemplate = true;
      values.recipients = [];
      this.notificationService
        .postNotificationForUsers(values)
        .take(1)
        .subscribe(() => this.getSavedNotification());
    }
  }

  public getSavedNotification(): void {
    this.notificationService
      .getSavedNotifications()
      .take(1)
      .subscribe((res) => (this.pageableSavedNotifications = res));
  }

  public deleteNotificationById(id: string): void {
    this.notificationService
      .deleteNotificationById(id)
      .take(1)
      .subscribe(() => this.getSavedNotification());
  }

  public openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template);
  }
}
