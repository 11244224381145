import { Message } from './message.models';
import { USER_REQUEST_STATUS } from '@core/models/request.model';

export class ThreadMessage {
  threadId?: string;
  eluNumber?: string;
  idGest?: number;
  objectId?: string;
  subject: string;
  mandateId?: string;
  state?: USER_REQUEST_STATUS;
  opened?: boolean;
  discussions: Message[];
  createdDateTime?: Date;
  lastModifiedDateTime?: Date;
  participant?: object;
  sameAuthor?: boolean;
  dateDiff?: boolean;

  constructor(copy: ThreadMessage) {
    this.threadId = copy.threadId;
    this.eluNumber = copy.eluNumber;
    this.idGest = copy.idGest;
    this.objectId = copy.objectId;
    this.subject = copy.subject;
    this.mandateId = copy.mandateId;
    this.state = copy.state;
    this.opened = copy.opened;
    this.discussions = copy.discussions;
    this.createdDateTime = copy.createdDateTime;
    this.lastModifiedDateTime = copy.createdDateTime;
    this.participant = copy.participant;
    this.sameAuthor = copy.sameAuthor;
    this.dateDiff = copy.dateDiff;
  }
}
