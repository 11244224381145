<div dropdown>
  <a
    href
    dropdownToggle
    aria-controls="basic-link-dropdown"
    (click)="(false)"
    class="openSans-semibold background-white-xs rounded padding-left-md-xs padding-right-md-xs padding-top-sm-xs padding-bottom-sm-xs border-grey3-xs-xs"
  >
    <span class="openSans-bold text-black-xs" *ngIf="type">{{ type }} : </span>
    <span
      *ngIf="!this.selectedOptionDisplay"
      [ngClass]="{
        'text-grey4-xs': title === 'SENT',
        'text-blue3-xs': title === 'IN_PROGRESS',
        'text-red3-xs': title === 'REJECTED',
        'text-green2-xs': title === 'DONE'
      }"
    >
      {{ i18nTitle + (title ? '.' + title : '.NONE') | translate }}
    </span>
    <span *ngIf="this.selectedOptionDisplay">
      {{
        optionFormat
          ? formatOption(this.selectedOptionDisplay)
          : (i18nOptions + '.' + this.selectedOptionDisplay | translate)
      }}
    </span>
    <span *ngIf="!cantSet" class="caret"></span>
  </a>

  <ul *dropdownMenu class="dropdown-menu">
    <li *ngFor="let option of options" class="col-xs-12 padding-none-xs" (click)="selectOption(option)">
      <a class="dropdown-item">
        {{ optionFormat ? formatOption(option) : (i18nOptions + '.' + option | translate) }}
      </a>
    </li>
  </ul>
</div>
