import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Mandate } from '@core/models';
import { BaseService } from '@core/services/base.service';

@Injectable()
export class MandateService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  public addMandate(userId: string, mandate: Mandate): Observable<any> {
    return this.http
      .post(`/users/${userId}/mandate`, JSON.stringify(mandate), this.getOptions())
      .map((res) => res)
      .catch((error) => Observable.throwError(error));
  }

  public getUserMandates(userId: string): Observable<any> {
    return this.http
      .get(`/users/${userId}/mandates`, this.getOptions())
      .map((res) => res)
      .catch((error) => Observable.throwError(error));
  }

  public listMandates(): Observable<any> {
    return this.http
      .get(`/mandates`, this.getOptions())
      .map((res) => res)
      .catch((error) => Observable.throwError(error));
  }

  public downloadFilewithDamId(id: string, type: string = 'BASE'): Observable<any> {
    return this.http.get(`/documents/download/${id}/type/${type}`, {
      responseType: 'blob',
      observe: 'response'
    });
  }

  public downloadTaxCertificate(contractId: string): Observable<any> {
    return this.http.get(`/contracts/${contractId}/attestationfiscale`, {
      responseType: 'blob',
      observe: 'response'
    });
  }

  public downloadMembershipCertificate(contractId: string): Observable<any> {
    return this.http.get(`/contracts/${contractId}/certificate`, {
      responseType: 'blob',
      observe: 'response'
    });
  }

  public downloadAccountSituation(contractId: string, annee: string): Observable<any> {
    return this.http.get(`/contracts/${contractId}/situation/${annee}`, {
      responseType: 'blob',
      observe: 'response'
    });
  }
}
