<div class="col-xs-12 padding-none-xs margin-top-md-xs margin-bottom-md-xs">
  <span class="col-xs-12 padding-none-xs background-white-xs border-xs-grey6-xs min-height-50-xs">
    <a
      class="col-xs-6 padding-none-xs min-height-50-xs line-height-50 border-right-xs-grey3-xs text-black-xs text-center text-18-xs text-bold cursor-hand"
      [ngClass]="{ 'tab-selected': selectedTab === 1 }"
      (click)="switchTab(1)"
    >
      {{ 'admin.users.detail.title' | translate }}
    </a>
    <a
      class="col-xs-6 padding-none-xs min-height-50-xs text-black-xs line-height-50 text-18-xs text-center text-bold cursor-hand"
      [ngClass]="{ 'tab-selected': selectedTab === 2 }"
      (click)="switchTab(2)"
    >
      Actions
    </a>
  </span>
  <div class="col-xs-12 background-white-xs border-xs-grey6-xs margin-top-md-xs">
    <n9-user-info *ngIf="selectedTab === 1" class="padding-none-xs" [user]="user"></n9-user-info>
    <n9-user-actions *ngIf="selectedTab === 2" class="padding-none-xs" [user]="user"></n9-user-actions>
  </div>
</div>
