import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { SidebarElement } from '@core/models/sidebar-element.model';

@Component({
  selector: 'n9-sidebar-element',
  templateUrl: './sidebar-element.component.html',
  styleUrls: ['./sidebar-element.component.scss']
})
export class SidebarElementComponent implements OnInit {
  @Input() element: SidebarElement;
  @Output() selected: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  public onClick(event: any): void {
    event.stopPropagation();

    this.element.collapsed = !this.element.collapsed;

    if (this.element.callback) {
      this.element.callback();
    }

    this.selected.emit(this.element.path);
  }

  public recursiveEmitter(value: any): void {
    this.selected.emit(value);
  }
}
