<div class="col-xs-12 padding-xs-xs background-white-xs">
  <div class="col-xs-7 padding-none-xs" style="display: inline-flex">
    <img src="../../../../assets/img/avatar-grey.png" width="20px" height="20px" />
    <span class="col-xs-12 text-blue3-xs text-bold text-left text-8-xs">
      {{ user.firstname }} {{ user.lastname }}
    </span>
  </div>
  <div class="col-xs-5 padding-none-xs">
    <span class="col-xs-12 padding-none-xs text-blue3-xs text-left text-8-xs">
      <strong>N'adherent :</strong>
      {{ user.eluNumber }}
    </span>
  </div>
</div>
