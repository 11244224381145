<p class="col-xs-12 dosis-bold text-blue3-xs text-14-xs text-18-sm">Outil de création de compte CAREL</p>
<form [formGroup]="accountForm" (ngSubmit)="onSubmit()" class="openSans-regular margin-top-xs-xs">
  <div class="col-xs-6 margin-top-sm-xs">
    <label for="lastname" class="openSans-bold">{{ 'admin.accounts.form.lastname' | translate }}: </label>
    <input id="lastname" formControlName="lastname" class="form-control" />
  </div>

  <div class="col-xs-6 margin-top-sm-xs">
    <label for="firstname" class="openSans-bold"
      >{{ 'admin.accounts.form.firstname' | translate }}
      :
    </label>
    <input id="firstname" formControlName="firstname" class="form-control" />
  </div>

  <div class="col-xs-6 margin-top-sm-xs">
    <label for="email" class="openSans-bold">{{ 'admin.accounts.form.email' | translate }}: </label>
    <input id="email" formControlName="email" class="form-control" placeholder="moncompte@carelmutuelle.fr" />
  </div>

  <div class="col-xs-6 margin-top-sm-xs">
    <label for="password" class="openSans-bold">{{ 'admin.accounts.form.password' | translate }}: </label>
    <input type="password" id="password" formControlName="password" class="form-control" />
  </div>

  <div class="col-xs-6 margin-top-sm-xs">
    <label for="roles" class="openSans-bold">{{ 'admin.accounts.form.roles' | translate }}: </label>
    <select id="roles" formControlName="roles" class="form-control">
      <option *ngFor="let role of keys(availableRoles)" value="{{ role }}">
        {{ role }}
      </option>
    </select>
  </div>

  <div class="col-xs-12 margin-top-xs-xs">
    <label
      for="create-user"
      class="margin-top-md-xs padding-md-xs background-red1-xs text-white-xs text-16-xs dosis-bold cursor-hand"
      [ngClass]="accountForm.invalid ? 'disabled' : ''"
    >
      {{ 'admin.accounts.form.submit' | translate }}
    </label>
    <input
      type="submit"
      id="create-user"
      class="hidden-xs hidden-sm hidden-md hidden-lg"
      [disabled]="accountForm.invalid"
    />
  </div>

  <p *ngIf="localUserCreated === false" class="col-xs-12 openSans-bold text-red1-xs margin-top-sm-xs">
    {{ this.errorMessage | translate }}
  </p>

  <p *ngIf="localUserCreated" class="col-xs-12 openSans-bold text-green2-xs margin-top-sm-xs">
    {{ 'admin.accounts.form.success' | translate }}
  </p>
</form>
