<div class="col-xs-12 padding-left-sm-xs padding-right-sm-xs padding-left-lg-sm padding-right-lg-sm">
  <p
    class="col-xs-12 padding-none-xs margin-top-md-xs margin-bottom-sm-xs dosis-bold text-18-xs text-24-sm text-left text-bold text-blue3-xs"
  >
    {{ 'home.contracts.signature.title' | translate }}
  </p>
  <div class="col-xs-12 padding-none-xs">
    <div class="col-xs-12 padding-md-xs background-white-xs border-grey3-xs-xs">
      <section
        class="col-xs-12 background-grey2-xs border-blue5-xs-xs margin-top-md-md margin-top-sm-xs margin-bottom-lg-md margin-bottom-md-xs padding-none-xs padding-sm-sm"
      >
        <div id="yousign-box">
          <n9-loader
            *ngIf="!this.signatureService.signUrl"
            class="spinner-border margin-top-md-xs margin-bottom-md-xs"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </n9-loader>
        </div>

        <iframe
          *ngIf="this.signatureService.signUrl"
          name="signature"
          [src]="this.signatureService.signUrl"
          class="border-none-xs"
          height="1315px"
          width="100%"
        >
        </iframe>
      </section>
    </div>
  </div>
</div>
