import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SessionService } from '@core/session/session.service';
import { SharedModule } from '@shared/shared.module';
import { LoginService } from '../login/login.service';
import { AdminRoutesModule } from './admin-routing.module';
import { AdminComponent } from './admin.component';
import { AdminHomeModule } from './home/admin-home.module';
import { AdminLoginComponent } from './login/admin-login.component';

@NgModule({
  providers: [SessionService, LoginService],
  imports: [CommonModule, FormsModule, SharedModule.forRoot(), AdminHomeModule, AdminRoutesModule],
  declarations: [AdminComponent, AdminLoginComponent],
  exports: [AdminComponent, AdminRoutesModule]
})
export class AdminModule {}
