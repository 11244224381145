import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarElement } from '@core/models/sidebar-element.model';

@Component({
  selector: 'n9-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.scss']
})
export class AdminSidebarComponent implements OnInit {
  @Input() features: SidebarElement[];

  constructor(private router: Router) {}

  ngOnInit(): void {
    const path: string[] = this.router.url.split('/');

    this.onSelection(this.features, path[path.length - 1]);
  }

  private onSelection(features: SidebarElement[], path: string): void {
    features.map((f) => {
      if (f.children) {
        this.onSelection(f.children, path);
      } else {
        f.selected = f.path === path;
      }
    });
  }
}
