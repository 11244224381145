<div class="container-fluid">
  <p class="dosis-bold text-24-xs text-blue3-xs margin-none-xs padding-bottom-md-xs">
    {{ 'admin.membership.list.title' | translate }}
  </p>
  <div class="margin-bottom-md-xs">
    <n9-carel-dropdown
      [options]="keys(status)"
      [title]="currentStatus"
      [type]="'Statut'"
      [i18nTitle]="'admin.membership.list.status'"
      [i18nOptions]="'admin.membership.list.status'"
      (selectedOption)="getAllMemberships($event)"
    >
    </n9-carel-dropdown>
  </div>
  <div *ngIf="pendingMemberships; else noMembership">
    <table class="col-xs-12">
      <tbody>
        <tr class="text-center dosis-bold text-white-xs background-blue3-xs">
          <td></td>
          <td>
            {{ 'admin.membership.list.tableLabels.status' | translate }}
          </td>
          <td>
            {{ 'subscription.subscriber.form.lastname' | translate }}
          </td>
          <td>
            {{ 'subscription.subscriber.form.firstnames.label' | translate }}
          </td>
          <td>
            {{ 'admin.membership.detail.general.infos.createdDateTime' | translate }}
          </td>
          <td>
            {{
              'admin.membership.detail.general.infos.' +
                (currentStatus === status.MEMBERSHIP_COMPLETE ? 'membershipComplete' : 'lastModifiedDateTime')
                | translate
            }}
          </td>
          <td>
            {{ 'admin.membership.list.downloadDoc' | translate }}
          </td>
        </tr>

        <tr
          *ngFor="let membership of pendingMemberships as Membership"
          class="text-center background-white-xs memberships-table openSans-regular"
        >
          <td [routerLink]="[membership.marketingId]">
            <span class="glyphicon glyphicon-eye-open"></span>
          </td>
          <td>
            {{ 'admin.membership.list.status.' + membership.status | translate }}
          </td>
          <td>{{ membership.subscriber.lastname }}</td>
          <td>{{ membership.subscriber.firstnames }}</td>
          <td>
            {{
              membership.createdDateTime | date: 'd MMMM yyyy ' + ('admin.membership.common.at' | translate) + ' HH:mm'
            }}
          </td>
          <td>
            {{
              membership.lastModifiedDateTime
                | date: 'd MMMM yyyy ' + ('admin.membership.common.at' | translate) + ' HH:mm'
            }}
          </td>
          <td>
            <span
              *ngIf="
                membership.status === status.MEMBERSHIP_COMPLETE ||
                  membership.status === status.SIGNATURE_COMPLETE ||
                  membership.status === status.MEMBERSHIP_TREATED;
                else noDoc
              "
              class="cursor-hand"
              (click)="downloadDocummentWithId(membership.signatureInformations.damId, membership)"
            >
              <i class="fa fa-download fa-2x" aria-hidden="true"></i
            ></span>
            <ng-template #noDoc>
              <span>...</span>
            </ng-template>
          </td>
        </tr>
      </tbody>
    </table>

    <pagination
      [boundaryLinks]="false"
      [directionLinks]="true"
      [itemsPerPage]="size"
      [totalItems]="nbElements"
      [(ngModel)]="page"
      previousText="&lsaquo;"
      nextText="&rsaquo;"
      (pageChanged)="getAllMemberships(currentStatus, $event)"
    >
    </pagination>
  </div>

  <ng-template #noMembership>
    <p class="openSans-regular text-14-xs">
      {{ 'admin.membership.list.noMembership' | translate
      }}{{ 'admin.membership.list.status.' + currentStatus | translate }}
    </p>
  </ng-template>
</div>
