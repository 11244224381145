<div
  class="col-xs-12 padding-left-md-xs padding-right-md-xs padding-left-lg-sm padding-right-lg-sm padding-top-sm-xs padding-bottom-md-xs"
>
  <!-- User information display for xs devices  -->
  <n9-user-info class="col-xs-12 padding-none-xs hidden-sm hidden-md hidden-lg"> </n9-user-info>

  <p
    class="col-xs-12 padding-none-xs margin-bottom-sm-xs margin-top-sm-xs dosis-bold text-14-xs text-18-sm text-left text-blue3-xs"
  >
    {{ 'home.contact.title' | translate }}
  </p>
  <n9-contact-form class="col-xs-12 padding-none-xs"> </n9-contact-form>
</div>
