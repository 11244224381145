import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { Injectable, LOCALE_ID, NgModule, ValueProvider } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { FooterComponent } from '@core/components/footer/footer.component';
import { HeaderModule } from '@core/components/header/header.module';
import { CoreModule } from '@core/core.module';
import { ApiHttpClient } from '@core/interceptors/api-http-client.service';
import { BaseHrefApiPreInterceptor } from '@core/interceptors/base-href-api.pre-interceptor';
import { DefaultHeadersApiPreInterceptor } from '@core/interceptors/default-headers-api.pre-interceptor';
import { ProfileResolver } from '@core/resolvers/profile.resolver';
import { LoggedInGuard } from '@core/session/logged-in.guard';
import { MaintenanceGuard } from '@core/session/maintenance.guard';

import { AdminModule } from '@modules/admin/admin.module';
import { MaintenanceModule } from '@modules/maintenance/maintenance.module';
import { SubscriptionModule } from '@modules/subscription/subscription.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ThreadService } from '@shared/services/thread.service';
import { SharedModule } from '@shared/shared.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AppComponent } from './app.component';

registerLocaleData(localeFr);

@Injectable()
export class WindowWrapper extends Window {}

export function getWindow(): any {
  return window;
}

const WINDOW_PROVIDER: ValueProvider = {
  provide: WindowWrapper,
  useValue: getWindow
};

const routes: Routes = [
  {
    path: '',
    loadChildren: '@modules/login/login.module#LoginModule',
    canActivate: [MaintenanceGuard]
  },
  {
    path: 'maintenance',
    loadChildren: '@modules/maintenance/maintenance.module#MaintenanceModule'
  },
  {
    path: 'home',
    loadChildren: '@modules/home/home.module#HomeModule',
    canActivate: [LoggedInGuard, MaintenanceGuard],
    resolve: [ProfileResolver]
  },
  {
    path: 'souscription',
    loadChildren: '@modules/subscription/subscription.module#SubscriptionModule'
  }
];

@NgModule({
  providers: [
    WINDOW_PROVIDER,
    ApiHttpClient,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseHrefApiPreInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultHeadersApiPreInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    ThreadService
  ],
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpModule,
    HttpClientModule,
    MaintenanceModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http, '/assets/i18n/', '.json'),
        deps: [HttpClient]
      }
    }),
    SharedModule.forRoot(),
    AdminModule,
    SubscriptionModule,
    HeaderModule,
    RouterModule.forRoot(routes),
    BsDatepickerModule
  ],
  declarations: [AppComponent, FooterComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
