import { Directive, forwardRef } from '@angular/core';
import { FormControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[n9BeforeNow][ngModel],[n9BeforeNow][formControl]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DateBeforeNowValidatorDirective),
      multi: true
    }
  ]
})
export class DateBeforeNowValidatorDirective implements Validator {
  static validDate(control: FormControl): object {
    const date: Date = new Date(control.value);
    const maxDate: Date = new Date(Date.now());

    return maxDate <= date ? { n9BeforeNow: { valid: false } } : null;
  }

  constructor() {}

  validate(control: FormControl): object {
    return DateBeforeNowValidatorDirective.validDate(control);
  }
}
