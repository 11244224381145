<div id="message-list" class="">
  <p class="dosis-bold text-24-xs text-blue3-xs">
    {{ 'admin.messages.list.title' | translate }}
  </p>

  <div class="row">
    <div class="col-xs-3 padding-top-md-xs padding-bottom-md-xs">
      <n9-carel-dropdown [options]="keys(subjects)" [title]="this.messageThreadsService.selectedSubject$ | async" [type]="'Objet'"
              [i18nTitle]="'admin.history.objects'" [i18nOptions]="'admin.history.objects'"
              (selectedOption)="this.messageThreadsService.setSubjectFilter($event)"></n9-carel-dropdown>
    </div>
    <div class="col-xs-3 padding-top-md-xs padding-bottom-md-xs">
      <n9-carel-dropdown [options]="keys(userRequestStatus)" [title]="this.messageThreadsService.selectedStatus$ | async" [type]="'Statut'"
              [i18nTitle]="'admin.history.switch'" [i18nOptions]="'admin.history.switch'"
              (selectedOption)="this.messageThreadsService.setStatusFilter($event)"></n9-carel-dropdown>
    </div>
  </div>

  <!-- Sort -->
  <div class="row">
    <div class="col-xs-9">
      <n9-sort-arrows
              class="col-xs-4"
              [order]="getSortOrderByFilterType((this.messageThreadsService.sortStatus$ | async), FILTER_TYPE.ELU_NUMBER)"
              (sort)="this.messageThreadsService.updateSort(FILTER_TYPE.ELU_NUMBER, $event)"
      ></n9-sort-arrows>
      <n9-sort-arrows
              class="col-xs-4"
              [order]="getSortOrderByFilterType((this.messageThreadsService.sortStatus$ | async), FILTER_TYPE.OBJECT_ID)"
              (sort)="this.messageThreadsService.updateSort(FILTER_TYPE.OBJECT_ID, $event)"
      ></n9-sort-arrows>
      <n9-sort-arrows
              class="col-xs-4"
              [order]="getSortOrderByFilterType((this.messageThreadsService.sortStatus$ | async), FILTER_TYPE.CREATED_DATE_TIME)"
              (sort)="this.messageThreadsService.updateSort(FILTER_TYPE.CREATED_DATE_TIME, $event)"
      ></n9-sort-arrows>
    </div>
  </div>

  <div class="row">
    <div *ngIf="threads" class="col-xs-12">
      <div *ngFor="let thread of threads; let i = index"
              class="hover-shadow background-white-xs border-grey3-xs-xs margin-top-sm-xs cursor-hand">
        <div class="row">
          <div class="col-xs-9 padding-top-md-xs padding-bottom-md-xs" [routerLink]="[thread['threadId']]">
            <div *ngIf="lastMessages[i] && lastMessages[i].message; else noContent">
              <ng-container *ngIf="!isAdmin; else adminUser">
                <div class="col-xs-4 openSans-bold">
                  {{ 'admin.messages.list.eluNumber' | translate }}{{ thread['eluNumber'] }}
                </div>
                <div class="col-xs-4 openSans-regular">
                  {{ lastMessages[i]?.message?.content }}
                </div>
                <div class="col-xs-4 text-italic openSans-regular">
                  {{ lastMessages[i]?.message?.createdDateTime | date: 'medium' }}
                </div>
              </ng-container>
              <ng-template #adminUser>
                <div class="col-xs-4">
                  <span class="openSans-bold">{{ managerName(thread['idGest']) }}</span>
                  {{ 'admin.messages.list.eluNumber' | translate }}
                  <span class="openSans-bold">{{ thread['eluNumber'] }}</span>
                </div>
                <div class="col-xs-4 openSans-bold">
                  {{ thread['objectId'] }}
                </div>
                <div class="col-xs-4 text-italic openSans-regular">
                  {{ lastMessages[i]?.message?.createdDateTime | date: 'dd/MM/yyyy' }} à {{ lastMessages[i].message.createdDateTime | date: 'H:mm' }}
                </div>
              </ng-template>
            </div>
            <ng-template #noContent>
              <div class="row">
                <div class="col-xs-12 openSans-regular text-center text-italic">
                  {{ 'admin.messages.list.message.noContent' | translate }}
                </div>
              </div>
            </ng-template>
          </div>
          <div class="col-xs-3 padding-top-md-xs py-0">
            <div class="row">
              <div *ngIf="!cantSet; else user" class="col-xs-12 py-0">
                <n9-carel-dropdown [options]="availableStatus()" [title]="threadStatus[i]" [type]="'Statut'"
                        [i18nTitle]="'admin.history.table.statusTypes'"
                        [i18nOptions]="'admin.history.table.statusTypes'"
                        (selectedOption)="editRequestStatus($event, thread['threadId'], i)"></n9-carel-dropdown>
              </div>
              <ng-template #user>
                <div class="col-xs-12" [routerLink]="[thread['threadId']]">
                  <n9-carel-dropdown [options]="availableStatus()" [title]="threadStatus[i]" [type]="'Statut'"
                          [cantSet]="cantSet" [i18nTitle]="'admin.history.table.statusTypes'"
                          [i18nOptions]="'admin.history.table.statusTypes'"
                          (selectedOption)="editRequestStatus($event, thread['threadId'], i)"></n9-carel-dropdown>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xs-12 text-center">
    <pagination (pageChanged)="getThreadList($event.page)" [boundaryLinks]="false" [directionLinks]="true"
            [itemsPerPage]="size" [(ngModel)]="page" [totalItems]="nbElements" nextText="&rsaquo;"
            previousText="&lsaquo;"></pagination>
  </div>

  <p *ngIf="lastMessages.length === 0" class="openSans-regular text-16-xs text-center text-italic">
    {{ 'admin.messages.list.noMessage' | translate }}
  </p>
</div>
