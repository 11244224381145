<div
  *ngIf="this.isModalDisplayed"
  [config]="{ show: true, backdrop: 'static' }"
  bsModal
  #notificationModal="bs-modal"
  (onHidden)="toggleModal()"
  class="modal fade"
>
  <div class="modal-dialog modal-md-xs">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title dosis-bold text-blue3-xs text-18-xs">
          {{ 'home.notifications.title' | translate }}
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="this.toggleModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body openSans-regular">
        <p class="openSans-bold text-center">
          {{ this.loginNotifications[0].subject }}
        </p>
        <p class="margin-top-md-xs">
          {{ this.loginNotifications[0].content }}
        </p>
      </div>

      <div class="modal-footer dosis-bold text-blue3-xs text-16-xs">
        <span
          [ngClass]="this.page <= 0 ? 'text-grey3-xs' : 'cursor-hand'"
          (click)="
            this.page > 0 ? this.setPage(this.page - 1) || this.getNotificationsByType('LOGIN', this.page, 1) : 0
          "
        >
          {{ 'home.notifications.pagination.previous' | translate }}
        </span>
        <span>{{ 'home.notifications.pagination.page' | translate }} {{ this.page + 1 }} / {{ this.nbPages }}</span>
        <span
          *ngIf="this.page < this.nbPages - 1"
          class="cursor-hand"
          (click)="
            this.page < this.nbPages - 1
              ? this.setPage(this.page + 1) || this.getNotificationsByType('LOGIN', this.page, 1)
              : 0
          "
        >
          {{ 'home.notifications.pagination.next' | translate }}
        </span>
        <span
          *ngIf="this.page >= this.nbPages - 1"
          class="background-red1-xs padding-md-xs cursor-hand text-white-xs"
          (click)="removeUserFromAllNotifications(); toggleModal()"
        >
          {{ 'home.notifications.confirm' | translate }}
        </span>
      </div>
    </div>
  </div>
</div>
