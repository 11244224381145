import { Observable } from 'rxjs/Rx';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { AlphanumericValidatorDirective } from '@core/validators/alphanumeric-validator.directive';
import { Constants } from '@core/utils/constants';
import { DateAfterNowValidatorDirective } from '@core/validators/date-after-now-validator.directive';
import { FileValidatorDirective } from '@core/validators/file-validator.directive';
import { GeneralAssemblyService } from '@shared/services/general-assembly.service';
import { GeneralAssembly, VoteValue } from '@core/models';

import * as moment from 'moment';
import { AdminNavigationService } from '@modules/admin/home/shared';

interface PossibleVote {
  value: string;
  display: string;
}

@Component({
  selector: 'n9-assembly-new',
  templateUrl: 'assembly-new.component.html',
  styleUrls: ['assembly-new.component.scss']
})
export class AssemblyNewComponent implements OnInit {
  public assemblyForm: FormGroup;
  public votes: PossibleVote[];
  public voteMap: any;
  public resolutions: string[];
  public orderOfTheDayMessage: string;
  public orderOfTheDay: File;
  public centralBookMessage: string;
  public centralBook: File;
  public entryFormMessage: string;
  public entryForm: File;
  public ballotMessage: string;
  public ballot: File;
  public votingRulesMessage: string;
  public votingRules: File;
  private localFormat: string = 'YYYY-MM-DD[T]HH:mm:ss';
  private assemblyNumber: number;

  constructor(
    private assemblyService: GeneralAssemblyService,
    private navigation: AdminNavigationService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {
    this.assemblyForm = this.formBuilder.group({
      location: ['', [Validators.required, AlphanumericValidatorDirective.validInput]],
      conveningDate: [
        moment().format(this.localFormat),
        [Validators.required, DateAfterNowValidatorDirective.validDate]
      ],
      endVoteDate: [moment().format(this.localFormat), [Validators.required, DateAfterNowValidatorDirective.validDate]],
      resolution: [''],
      possibleVotes: ['', [Validators.required]],
      orderOfTheDay: ['', [Validators.required]],
      centralBook: ['', [Validators.required]],
      entryForm: ['', [Validators.required]],
      ballot: ['', [Validators.required]],
      votingRules: ['', [Validators.required]]
    });

    const possibleVotesOptions: PossibleVote[] = [
      {
        value: VoteValue[VoteValue.YES],
        display: 'Oui'
      },
      {
        value: VoteValue[VoteValue.NO],
        display: 'Non'
      },
      {
        value: VoteValue[VoteValue.NEUTRAL],
        display: 'Ne se prononce pas'
      },
      {
        value: VoteValue[VoteValue.WHITE],
        display: 'Blanc'
      }
    ];

    const voteMap: any = {
      YES: false,
      NO: false,
      NEUTRAL: false,
      WHITE: false
    };

    this.resolutions = [];
    this.votes = possibleVotesOptions;
    this.voteMap = voteMap;
    this.orderOfTheDayMessage = null;
    this.centralBookMessage = null;
    this.entryFormMessage = null;
    this.ballotMessage = null;
    this.votingRules = null;
  }

  public ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => (this.assemblyNumber = +params['assembly']));
  }

  public onSubmit(): void {
    const assembly: GeneralAssembly = this.assemblyForm.getRawValue();

    assembly.assemblyNumber = this.assemblyNumber;
    assembly.resolution = this.resolutions;
    assembly.possibleVotes = [];
    assembly.conveningDate = moment(assembly.conveningDate).format(this.localFormat);
    assembly.endVoteDate = moment(assembly.endVoteDate).format(this.localFormat);

    // tslint:disable-next-line:prefer-for-of
    for (let index: number = 0; index < this.votes.length; index++) {
      if (this.voteMap[this.votes[index].value]) {
        assembly.possibleVotes.push(this.votes[index].value);
      }
    }

    this.assemblyService.addAssembly(assembly).subscribe(
      (res) => {
        Observable.forkJoin(
          this.assemblyService.uploadDocument(res.id, 'AGENDA', this.orderOfTheDay),
          this.assemblyService.uploadDocument(res.id, 'CENTRAL_NOTEBOOK', this.centralBook),
          this.assemblyService.uploadDocument(res.id, 'PARTICIPATION_BALLOT', this.entryForm),
          this.assemblyService.uploadDocument(res.id, 'BALLOT', this.ballot),
          this.assemblyService.uploadDocument(res.id, 'VOTING_MODALITY', this.votingRules)
        ).subscribe(
          (res2) => this.navigation.navigate('assemblies'),
          (error) => this.handleError(error)
        );
      },
      (error) => this.handleError(error)
    );
  }

  public addResolution(): void {
    const newResolution: string = this.assemblyForm.get('resolution').value;

    if (newResolution !== '') {
      if (this.resolutions.findIndex((resolution) => resolution === newResolution) < 0)
        this.resolutions.push(newResolution);
    }

    this.validateForm(this.assemblyForm);
  }

  public deleteResolution(resolution: string): void {
    const index = this.resolutions.findIndex((savedResolution) => savedResolution === resolution);

    if (index >= 0) {
      if (index === 0) this.resolutions.splice(index, index + 1);
      else this.resolutions.splice(index, index);
    }

    this.validateForm(this.assemblyForm);
  }

  public updatePossibleVotes(voteValue: string): void {
    this.voteMap[voteValue] = !this.voteMap[voteValue];
    this.validateForm(this.assemblyForm);
  }

  public uploadFile(formName: string, event: any): void {
    if (event.target.files && event.target.files.length > 0) {
      const error: any = FileValidatorDirective.validateFile(event.target.files[0].name);
      let message: string = null;

      this.assemblyForm.get(formName).setErrors(error);

      if (error) message = Constants.errorsMessages['INVALID_FILE_FORMAT'];
      else message = `Fichier ajouté : ${event.target.files[0].name}`;

      switch (formName) {
        case 'orderOfTheDay':
          this.orderOfTheDayMessage = message;
          this.orderOfTheDay = event.target.files[0];
          break;
        case 'centralBook':
          this.centralBookMessage = message;
          this.centralBook = event.target.files[0];
          break;
        case 'entryForm':
          this.entryFormMessage = message;
          this.entryForm = event.target.files[0];
          break;
        case 'ballot':
          this.ballotMessage = message;
          this.ballot = event.target.files[0];
          break;
        case 'votingRules':
          this.votingRulesMessage = message;
          this.votingRules = event.target.files[0];
          break;
      }
    }
  }

  private handleError(error: any): void {
    if (error.status) {
      if (error.status === 403 || error.status === 401) {
        this.navigation.navigateToLogin();
        return;
      }
    }
  }

  private validateForm(formGroup: FormGroup): void {
    this.validateResolutions(formGroup);
    this.validatePossibleVotes(formGroup);
  }

  private validateResolutions(formGroup: FormGroup): void {
    if (!this.resolutions || this.resolutions.length === 0)
      formGroup.get('resolution').setErrors({ required: { valid: false } });
    else formGroup.get('resolution').setErrors(null);
  }

  private validatePossibleVotes(formGroup: FormGroup): void {
    if (
      (this.voteMap[VoteValue[VoteValue.YES]] &&
        this.voteMap[VoteValue[VoteValue.NO]] &&
        !this.voteMap[VoteValue[VoteValue.NEUTRAL]] &&
        !this.voteMap[VoteValue[VoteValue.WHITE]]) ||
      (this.voteMap[VoteValue[VoteValue.YES]] &&
        this.voteMap[VoteValue[VoteValue.NO]] &&
        this.voteMap[VoteValue[VoteValue.NEUTRAL]] &&
        this.voteMap[VoteValue[VoteValue.WHITE]])
    )
      formGroup.get('possibleVotes').setErrors(null);
    else formGroup.get('possibleVotes').setErrors({ choices: { valid: false } });
  }
}
