import { Component } from '@angular/core';

@Component({
  selector: 'n9-contact',
  templateUrl: 'contact.component.html',
  styles: [
    `
      div {
        display: flex;
        flex-direction: column;
      }
    `
  ]
})
export class ContactComponent {}
