import { Directive, forwardRef } from '@angular/core';
import { FormControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[n9BeforeNow][ngModel],[n9BeforeNow][formControl]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FileValidatorDirective),
      multi: true
    }
  ]
})
export class FileValidatorDirective implements Validator {
  static validateFile(filename: string): object {
    // TODO : Complete file regex
    const regex = /\.pdf|\.zip|\.jpeg|\.png|\.jpg|\.docx|\.pptx|\.xlsx$/;

    if (filename.search(regex) >= 0) return null;
    else return { n9File: { valid: false } };
  }

  validate(c: FormControl): { [p: string]: any } {
    return null;
  }
}
