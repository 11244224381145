import { Directive, forwardRef } from '@angular/core';
import { FormControl, FormGroup, NG_VALIDATORS, Validators } from '@angular/forms';
import { Phone } from '../models/phone.model';

@Directive({
  selector: '[n9-phone][ngModel],[n9-phone][formControl]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PhoneValidatorDirective),
      multi: true
    }
  ]
})
export class PhoneValidatorDirective implements Validators {
  public static validInput(control: FormControl): object {
    const phone: string = control.value;
    const regex: RegExp = /^0?[0-9]{9}$/g;

    if (!phone || phone === '') return null;

    return !regex.test(phone) ? { n9Phone: { valid: false } } : null;
  }
}
