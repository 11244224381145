import { Component } from '@angular/core';
import { User } from '@core/models';
import { DataStatus } from '@core/models/data-status';

import { SessionService } from '@core/session/session.service';
import { HomeNavigationService } from '@core/services/navigation.service';

@Component({
  selector: 'n9-personal-data',
  templateUrl: 'personal-data.component.html',
  styleUrls: ['personal-data.component.scss']
})
export class PersonalDataComponent {
  public dataStatus: DataStatus;
  public ds: typeof DataStatus = DataStatus;
  public user: User;

  constructor(private sessionService: SessionService, private navigation: HomeNavigationService) {
    this.dataStatus = DataStatus.WAITING;
    this.user = sessionService.getUser();
  }

  public doneEditing(event: DataStatus): void {
    this.dataStatus = event;
  }

  public handleError(error: any): void {
    if (error.status) {
      if (error.status === 404 || error.status === 403 || error.status === 401) {
        this.navigation.navigateToLogin();
        return;
      }
    }
  }
}
