import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import "rxjs-compat/add/operator/map";

const MAX_STEP = 4;

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  selectedStep: number;

  constructor(private http: HttpClient) {
    if (!localStorage.getItem('step')) {
      localStorage.setItem('step', '0');
    }

    this.selectedStep = parseInt(localStorage.getItem('step'), 10);
  }

  public validateSubscriber(subscriberValues: object): Observable<any> {
    // UPDATE member by marketingID
    if (localStorage.getItem('membershipMarketingId')) {
      return this.updateSubscriber(subscriberValues);
    }

    // Create member otherwise
    return this.http
      .post('/membership', subscriberValues)
      .map((response) => localStorage.setItem('membershipMarketingId', response['marketingId']))
      .catch((error) => Observable.throwError(error));
  }

  public validateMandates(mandatesValues: object): Observable<any> {
    if (localStorage.getItem('membershipMarketingId')) {
      return this.updateSubscriber(mandatesValues);
    }
  }

  public sendMembershipSummary(): Observable<any> {
    if (localStorage.getItem('membershipMarketingId')) {
      return this.http
        .get('/membership/summary/' + localStorage.getItem('membershipMarketingId'))
        .catch((error) => Observable.throwError(error));
    }
  }

  public getSubscriberValues(): Observable<any> {
    // Retrieve member data by marketingID
    if (localStorage.getItem('membershipMarketingId')) {
      return this.http
        .get('/membership/' + localStorage.getItem('membershipMarketingId'))
        .map((response) => response)
        .catch((error) => Observable.throwError(error));
    }

    return of({});
  }

  public updateSubscriber(values: object): Observable<any> {
    if (localStorage.getItem('membershipMarketingId')) {
      return this.http
        .put('/membership/' + localStorage.getItem('membershipMarketingId'), values)
        .catch((error) => Observable.throwError(error));
    }
  }

  public deleteMembership(membershipMarketingId: string): void {
    this.http
      .delete(`/membership/${membershipMarketingId}`)
      .take(1)
      .subscribe(
        () => {},
        (err) => Observable.throwError(err)
      );
  }

  public getSelectedStep(): number {
    return this.selectedStep;
  }

  public goBack(): void {
    if (this.selectedStep > 0) {
      this.selectedStep--;
      localStorage.setItem('step', this.selectedStep.toString());
    }
    window.scroll(0, 0);
  }

  public goForward(step: number): void {
    if (this.selectedStep < MAX_STEP) {
      this.selectedStep = step + 1;

      if (this.selectedStep.toString() >= localStorage.getItem('step'))
        localStorage.setItem('step', this.selectedStep.toString());
    } else {
      this.goToStep(0);
    }
    window.scroll(0, 0);
  }

  public goToStep(step: number): void {
    if (step >= 0 && step <= MAX_STEP) {
      localStorage.setItem('step', step.toString());
      this.selectedStep = step;
    }
  }
}
