import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class AdminNavigationService {
  // public tool$: Observable<string> = new BehaviorSubject(null);

  constructor(private router: Router) {}

  navigateToRoute(route: string): void {
    this.router.navigate([route]);
  }

  navigate(route: string): void {
    this.navigateToRoute('/admin/home/' + route);
  }

  public navigateToLogin(): void {
    this.router.navigate(['/admin/login']);
  }

  public getCurrentRoute(): string {
    return this.router.url;
  }
}
