import { AuditEntity } from './audit-entity.model';
import { MembershipStatus } from './membership-status.model';
import { SignatureInformations } from './signatureInformations.model';

export enum USER_REQUEST_TYPE {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  NEW_MANDATE = 'NEW_MANDATE',
  USER_MODIFY = 'USER_MODIFY',
  CONTRACT_CONTRIBUTION_RATE = 'CONTRACT_CONTRIBUTION_RATE',
  CONTRACT_BENEFICIARY_CLAUSE = 'CONTRACT_BENEFICIARY_CLAUSE',
  CONTRACT_RETROACTIVITY_DATE = 'CONTRACT_RETROACTIVITY_DATE',
  ALL = 'ALL'
}

export enum USER_REQUEST_STATUS {
  SENT = 'SENT',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  REJECTED = 'REJECTED',
  ALL = 'ALL'
}

export enum THREAD_STATUS {
  SENT = 'SENT',
  DONE = 'DONE',
  REJECTED = 'REJECTED',
  ALL = 'ALL'
}
export enum SUBJECT {
  CONTRAT = 'CONTRAT',
  COTISATION = 'COTISATION',
  DROITS = 'DROITS',
  AUTRES = 'AUTRES',
  ALL = 'ALL'
}

export interface UserRequest extends AuditEntity {
  id: string;
  eluNumber: string;
  type: USER_REQUEST_TYPE;
  state: USER_REQUEST_STATUS;
  gestionnaireId?: string;
  oldValue?: any;
  newValue?: any;
  token?: string;
  status?: MembershipStatus;
  signatureInformations?: SignatureInformations;
}
