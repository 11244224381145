<div *ngFor="let d of values; let i = index">
  <div *ngIf="typeof(d) !== 'object'; else recursive">
    <p class="openSans-bold">
      {{ i18n + '.' + keys[i] | translate }}
      <span class="openSans-regular">{{ d }}</span>
    </p>
  </div>

  <ng-template #recursive>
    <div class="padding-left-md-xs">
      <n9-data-summary [data]="d" [i18n]="i18n + '.' + keys[i]"></n9-data-summary>
    </div>
  </ng-template>
</div>
