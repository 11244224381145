import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from '../models';
import { ROLE } from '../models/role.model';
import { SessionService } from './session.service';

@Injectable()
export class RoleGuard implements CanActivate {
  constructor(
    private sessionService: SessionService, // Will need modification due to WS
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const user: User = this.sessionService.getUser(); // Will need modification due to WS

    if (!user) {
      this.router.navigate(['/login']);

      return true;
    }

    if (user && route.data && route.data['authorizedRoles']) {
      const authorized: ROLE[] = route.data['authorizedRoles'];

      if (authorized) {
        if (authorized.some((a) => user.roles.includes(a))) return true;

        this.sessionService.logout();

        if (user.roles.includes(ROLE.ROLE_USER))
          this.router.navigate(['/login'], {
            queryParams: { role: true }
          });
        else
          this.router.navigate(['/admin/login'], {
            queryParams: { role: true }
          });

        return false;
      }
    }

    return false;
  }
}
