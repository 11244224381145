import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

import { Contract, User } from '@core/models';
import { SessionService } from '@core/session/session.service';

@Component({
  selector: 'n9-user-info',
  templateUrl: 'user-info.component.html'
})
export class UserInfoComponent implements OnInit {
  public user: User;
  public mandate: string;

  public constructor(private session: SessionService) {
    this.user = this.session.getUser();
  }

  public ngOnInit(): void {
    this.mandate = this.determineUserMandate();
  }

  private determineUserMandate(): string {
    if (this.user.contracts) {
      const pendingContracts: Contract[] = this.user.contracts.filter((contract) => contract.status === 'PENDING');

      if (!pendingContracts || pendingContracts.length === 0) return 'Rentier';
      else {
        let minContract: Contract = pendingContracts[0];
        let minDate: Date = moment(minContract.subscriptionDate).toDate();
        let challengerDate: Date;

        for (let index: number = 1; index < pendingContracts.length; index++) {
          challengerDate = moment(pendingContracts[index].subscriptionDate).toDate();

          if (challengerDate < minDate) {
            minContract = pendingContracts[index];
            minDate = challengerDate;
          }
        }

        return minContract.mandate;
      }
    }
  }
}
