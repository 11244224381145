import * as _ from 'lodash';
import { environment } from '../../../../environments/environment';

export class ConfigurationService {
  private conf: any;

  constructor() {
    this.conf = environment || {};
    // console.log('--configuration.service.ts  this.conf--', this.conf);
  }

  get(key: string): any {
    return _.get(this.conf, key);
  }

  getAsString(key: string): string {
    return this.get(key);
  }

  set(key: string, value: any): void {
    return _.set(this.conf, key, value);
  }
}
