<div class="openSans-regular">
  <div *ngIf="membership" class="col-xs-12 padding-none-xs padding-bottom-md-xs">
    <span class="col-xs-10 dosis-bold padding-none-xs text-blue3-xs text-24-xs">
      {{ 'admin.membership.detail.title' | translate }}
      {{ membership.marketingId }}
    </span>
    <button
      *ngIf="membership.status === 'SIGNATURE_COMPLETE'"
      class="col-xs-2 btn btn-default dosis-bold padding-left-md-xs padding-right-md-xs padding-top-sm-xs padding-bottom-sm-xs background-red1-xs text-14-xs text-uppercase text-white-xs border-radius-none-xs"
      (click)="validateMembership()"
      [routerLink]="['../']"
    >
      Valider
    </button>
  </div>

  <div *ngIf="membership as Membership">
    <div
      class="col-xs-12 background-white-xs border-grey3-xs-xs padding-md-xs"
      *ngIf="this.membership.status === 'SIGNATURE_REFUSED'"
    >
      <p class="openSans-bold">L'utilisateur n'a pas souhaité signer pour les raisons suivantes :</p>
      <p class="text-red3-xs text-14-xs margin-right-xs-xs">
        {{ membership.signatureInformations.refusalComment }}
      </p>
    </div>
    <div class="col-xs-12 background-white-xs border-grey3-xs-xs padding-md-xs">
      <div id="general-container" class="col-xs-4 infos-container">
        <div class="col-xs-12 padding-top-sm-xs">
          <p class="dosis-bold text-blue3-xs text-18-xs margin-bottom-md-xs">
            {{ 'admin.membership.detail.general.title' | translate }}
          </p>

          <p>
            <span class="openSans-bold margin-right-xs-xs"
              >{{ 'admin.membership.detail.general.infos.createdDateTime' | translate }} :</span
            >
            <span>{{
              membership.createdDateTime | date: 'd MMMM yyyy ' + ('admin.membership.common.at' | translate) + ' HH:mm'
            }}</span>
          </p>
          <p>
            <span class="openSans-bold margin-right-xs-xs">
              {{
                'admin.membership.detail.general.infos.' +
                  (membership.status === 'MEMBERSHIP_COMPLETE' ? 'membershipComplete' : 'lastModifiedDateTime')
                  | translate
              }}
              :
            </span>
            <span>{{
              membership.lastModifiedDateTime
                | date: 'd MMMM yyyy ' + ('admin.membership.common.at' | translate) + ' HH:mm'
            }}</span>
          </p>
          <p>
            <span class="openSans-bold margin-right-xs-xs"
              >{{ 'admin.membership.detail.general.infos.marketingId' | translate }} :</span
            >
            <span>{{ membership.marketingId }}</span>
          </p>
          <p>
            <span class="openSans-bold margin-right-xs-xs"
              >{{ 'admin.membership.detail.general.infos.status' | translate }} :</span
            >
            <span>{{ 'admin.membership.list.status.' + membership.status | translate }}</span>
          </p>
        </div>

        <div id="subscriber" class="col-xs-12">
          <p class="dosis-bold text-blue3-xs text-18-xs margin-bottom-md-xs">
            {{ 'admin.membership.detail.subscriber.title' | translate }}
          </p>

          <p>
            <span class="openSans-bold"
              >{{ 'subscription.subscriber.form.lastname' | translate }}
              :
            </span>
            <span>{{ membership.subscriber.lastname }}</span>
          </p>
          <p>
            <span class="openSans-bold"
              >{{ 'subscription.subscriber.form.maidenname' | translate }}
              :
            </span>
            <span>{{ membership.subscriber.maidenname }}</span>
          </p>
          <p>
            <span class="openSans-bold"
              >{{ 'subscription.subscriber.form.firstnames.label' | translate }}
              :
            </span>
            <span>{{ membership.subscriber.firstnames }}</span>
          </p>
          <p>
            <span class="openSans-bold"
              >{{ 'subscription.subscriber.form.birthDate' | translate }}
              :
            </span>
            <span>{{ membership.subscriber.birthDate | date: 'd MMMM yyyy' }}</span>
          </p>
          <p>
            <span class="openSans-bold"
              >{{ 'subscription.subscriber.form.cityOfBirth' | translate }}
              :
            </span>
            <span>{{ membership.subscriber.cityOfBirth }}</span>
          </p>

          <p class="openSans-bold">
            {{ 'admin.membership.detail.general.infos.address' | translate }}
            :
          </p>

          <div class="col-xs-12 padding-left-lg-xs">
            <p>
              <span class="openSans-bold"
                >{{ 'subscription.subscriber.form.address' | translate }}
                :
              </span>
              <span>{{ membership.subscriber.address.address }}</span>
            </p>
            <p>
              <span class="openSans-bold"
                >{{ 'subscription.subscriber.form.postalCode' | translate }}
                :
              </span>
              <span>{{ membership.subscriber.address.postalCode }}</span>
            </p>
            <p>
              <span class="openSans-bold"
                >{{ 'subscription.subscriber.form.city' | translate }}
                :
              </span>
              <span>{{ membership.subscriber.address.city }}</span>
            </p>
            <p>
              <span class="openSans-bold"
                >{{ 'subscription.subscriber.form.complement' | translate }}
                :
              </span>
              <span>{{
                membership.subscriber.address.complement ? membership.subscriber.address.complement : 'N/A'
              }}</span>
            </p>
          </div>

          <p>
            <span class="openSans-bold"
              >{{ 'subscription.subscriber.form.email' | translate }}
              :
            </span>
            <span>{{ membership.subscriber.email }}</span>
          </p>
          <p>
            <span class="openSans-bold"
              >{{ 'subscription.subscriber.form.phone' | translate }}
              :
            </span>
            <span *ngIf="!membership.subscriber.phone.number">{{
              membership.subscriber.phone.number ? membership.subscriber.phone.number : 'N/A'
            }}</span>

            <span *ngIf="membership.subscriber.phone.number">
              {{ membership.subscriber.phone.prefix ? membership.subscriber.phone.prefix : 'N/A' }}
              {{ membership.subscriber.phone.number ? membership.subscriber.phone.number : 'N/A' }}</span
            >
          </p>
          <p>
            <span class="openSans-bold"
              >{{ 'subscription.subscriber.form.mobilePhone' | translate }}
              :
            </span>
            <span>{{ membership.subscriber.mobilePhone.prefix }}{{ membership.subscriber.mobilePhone.number }}</span>
          </p>
          <p>
            <span class="openSans-bold"
              >{{ 'subscription.subscriber.form.maritalStatus.label' | translate }}
              :
            </span>
            <span>{{ membership.subscriber.maritalStatus ? membership.subscriber.maritalStatus : 'N/A' }}</span>
          </p>
          <p>
            <span class="openSans-bold"
              >{{ 'subscription.subscriber.form.job' | translate }}
              :
            </span>
            <span>{{ membership.subscriber.job ? membership.subscriber.job : 'N/A' }}</span>
          </p>
        </div>
      </div>

      <p class="dosis-bold text-blue3-xs text-18-xs">
        {{ 'admin.membership.detail.mandates.title' | translate }}
      </p>

      <div class="col-xs-8" *ngIf="this.membership != null">
        <n9-mandate-infos [mandates$]="this.mandates$"></n9-mandate-infos>
      </div>

      <hr width="100%" color="blue" />
      <p class="dosis-bold text-blue3-xs text-18-xs">
        {{ 'admin.membership.detail.documents' | translate }}
      </p>
      <div class="col-xs-8" *ngIf="this.uploadedFiles && this.uploadedFiles.length > 0">
        <div
          *ngFor="let file of this.uploadedFiles; let i = index"
          class="col-sm-6 col-xs-12 margin-top-sm-xs margin-bottom-sm-xs"
        >
          <div class="row">
            <button type="button" class="btn btn-success col-sm-4 col-md-2 col-xs-6" (click)="saveFile(i)">
              <i class="fa fa-download fa-2x" aria-hidden="true"></i>
            </button>
            <p class="col-sm-3 col-xs-6">{{ file.name }}</p>
          </div>
          <div class="row pol">
            <img *ngIf="file.type.match('image/.+')" src="{{ this.images[i] }}" class="rounded" alt="{{ file.name }}" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
