import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Feature } from '@core/models/feature.model';
import { SessionService } from '@core/session/session.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';

import { FeatureUtils } from '../utils/feature.utils';

export const features: Feature[] = [
  {
    icon: 'assets/img/avatar.png',
    name: 'personal-data',
    label: 'home.sidebar.personalDataTabName',
    activeTab: 1
  },
  {
    icon: 'assets/img/file.svg',
    name: 'contracts',
    label: 'home.sidebar.contractsTabName',
    activeTab: 2
  },
/*  {
    icon: 'assets/img/opened-email-outlined-interface-symbol.svg',
    name: 'contact',
    label: 'home.sidebar.contactTabName',
    activeTab: 5
  },
  {
    icon: 'assets/img/speech-bubbles.svg',
    name: 'messages',
    label: 'home.sidebar.messagesTabName',
    activeTab: 6
  },*/
  {
    icon: 'assets/img/shut-down-icon.svg',
    name: 'disconnect',
    label: 'home.sidebar.disconnect',
    activeTab: 7
  }
];

@Injectable()
export class HomeNavigationService {
  public feature$: Observable<string> = new BehaviorSubject('contracts');

  constructor(private readonly router: Router, private readonly sessionService: SessionService) {}

  public navigate(feature: string): void {
    (this.feature$ as BehaviorSubject<string>).next(feature);
    const route = FeatureUtils.getFeatureRoute(feature);

    if (route) this.router.navigate([route]);
  }

  public navigateToRoute(route: string, pathVariable: any): void {
    if (pathVariable) this.router.navigate([route, pathVariable]);
    else this.router.navigate([route]);
  }

  public navigateToLogin(): void {
    this.router.navigate(['/']);
  }

  public getRoute(): string {
    return this.router.url;
  }

  public selectFeature(featureName: string): void {
    if (featureName === 'disconnect') {
      this.sessionService.logout();
      this.navigateToLogin();
    } else this.navigate(featureName);
  }
}
