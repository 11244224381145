<div class="row padding-left-lg-md padding-right-lg-md">
  <p class="dosis-bold text-uppercase text-blue3-xs text-18-xs padding-left-sm-xs">
    {{ 'subscription.signature.title' | translate }}
  </p>

  <section
    class="col-xs-12 background-grey2-xs border-blue5-xs-xs margin-top-md-md margin-top-sm-xs margin-bottom-lg-md margin-bottom-md-xs padding-none-xs padding-sm-sm"
  >
    <div id="yousign-box">
      <n9-loader *ngIf="!this.signatureService.signUrl" class="margin-top-md-xs margin-bottom-md-xs" role="status">
        <span class="sr-only">Loading...</span>
      </n9-loader>
    </div>

    <iframe
      *ngIf="this.signatureService.signUrl"
      name="signature"
      [src]="this.signatureService.signUrl"
      class="border-none-xs"
      height="1315px"
      width="100%"
    >
    </iframe>
  </section>

  <p
    class="col-sm-2 col-xs-6 text-red1-xs dosis-semibold text-16-xs margin-bottom-md cursor-hand"
    (click)="this.subscriptionService.goBack()"
  >
    < {{ 'subscription.common.previous' | translate }}
  </p>
</div>
