<footer
  class="background-blue3-xs border-bottom-xs-grey6-xs border-top-xs-grey6-xs padding-left-lg-xs padding-right-lg-xs text-white-xs text-16-md text-12-xs"
>
  <span>{{ 'footer.copyrights' | translate }}</span>

  <ul class="margin-none-xs padding-none-xs text-16-md text-12-xs text-right dosis-semibold">
    <li>
      <a class="text-white-xs" href="http://www.carelmutuelle.fr/mentions">
        {{ 'footer.legalMentions' | translate }}
      </a>
    </li>
    <li>
      <a href="http://www.carelmutuelle.fr/liens">
        {{ 'footer.usefulLinks' | translate }}
      </a>
    </li>
    <li>
      <a href="http://www.carelmutuelle.fr/contact">
        {{ 'footer.contactCarel' | translate }}
      </a>
    </li>
  </ul>
</footer>
