import { Directive, forwardRef } from '@angular/core';
import { FormControl, NG_VALIDATORS, Validators } from '@angular/forms';

@Directive({
  selector: '[n9-zip-code][ngModel],[n9-zip-code][formControl]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ZipCodeValidatorDirective),
      multi: true
    }
  ]
})
export class ZipCodeValidatorDirective implements Validators {
  public static validInput(control: FormControl): object {
    const value: string = control.value;
    const regex: RegExp = /^[0-9]{5}$/g;

    if (!value || value === '') return null;

    return !regex.test(value) ? { N9ZipCode: { valid: false } } : null;
  }
}
