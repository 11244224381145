import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageableResult, User } from '@core/models';
import { SUBJECT, THREAD_STATUS } from '@core/models/request.model';
import { ThreadMessage } from '@shared/components/threads/thread-message.models';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { SORT_ORDER } from '../../sort-arrows/sort-arrows.component';
import { Message } from './message.models';

export enum FILTER_TYPE {
  ELU_NUMBER = 'eluNumber',
  OBJECT_ID = 'objectId',
  CREATED_DATE_TIME = 'createdDateTime'
}

export interface SortStatus {
  type: FILTER_TYPE;
  order: SORT_ORDER;
}

@Injectable({
  providedIn: 'root'
})
export class MessageThreadsService {
  public readonly selectedSubject$: Observable<SUBJECT>;
  public readonly selectedStatus$: Observable<THREAD_STATUS>;
  public readonly sortStatus$: Observable<SortStatus[]>;

  private selectedSubject: BehaviorSubject<SUBJECT> = new BehaviorSubject<SUBJECT>(SUBJECT.ALL);
  private selectedStatus: BehaviorSubject<THREAD_STATUS> = new BehaviorSubject<THREAD_STATUS>(THREAD_STATUS.ALL);
  private sortStatus: BehaviorSubject<SortStatus[]> = new BehaviorSubject<SortStatus[]>([]);

  constructor(private http: HttpClient) {
    this.selectedSubject$ = this.selectedSubject.asObservable();
    this.selectedStatus$ = this.selectedStatus.asObservable();
    this.sortStatus$ = this.sortStatus.asObservable();
  }

  public getMessagesList(page: number = 0, size: number = 200): Observable<PageableResult<ThreadMessage>> {
    const object = this.selectedSubject.getValue() !== SUBJECT.ALL ? `/object/${this.selectedSubject.getValue()}` : '';
    const status =
      this.selectedStatus.getValue() !== THREAD_STATUS.ALL ? `/status/${this.selectedStatus.getValue()}` : '';

    return this.http.get<PageableResult<ThreadMessage>>(`/threads${object}${status}?page=${page}&size=${size}`);
  }

  public changeThreadStatus(threadId: string, body: object): Observable<any> {
    return this.http.put(`/threads/` + threadId + '/state', body);
  }

  public getMessagesFromThreadId(threadId: string, page: number, size: number): Observable<object> {
    return this.http.get('/threads/' + threadId + '?page=' + page + '&size=' + size);
  }

  public postMessage(body: object): Observable<object> {
    return this.http.post('/messages', body);
  }

  public postMessageToThread(threadId: string, body: Message): Observable<object> {
    return this.http.post('/threads/message/' + threadId, body);
  }

  public uploadImageFile(file: File): Observable<any> {
    return this.http.post(
      `/documents/create/as-byte-array/BASE?name=${file.name}&label=${file.name}&content-type=${file.type}`,
      file
    );
  }

  public downloadImageFile(id: string, type: string = 'BASE'): Observable<any> {
    return this.http.get(`/documents/download/${id}/type/${type}`, {
      responseType: 'blob',
      observe: 'response'
    });
  }

  public getAllManagers(): Observable<User[]> {
    return this.http
      .get(`/users/managers`)
      .map((managers: User[]) => managers)
      .catch((err) => Observable.throwError(err));
  }

  public setSubjectFilter(selectedSubject: SUBJECT): void {
    this.selectedSubject.next(selectedSubject);
  }

  public setStatusFilter(selectedStatus: THREAD_STATUS): void {
    this.selectedStatus.next(selectedStatus);
  }

  public updateSort(type: FILTER_TYPE, order: SORT_ORDER): void {
    const value: SortStatus[] = this.sortStatus.getValue().filter((sortStatus: SortStatus) => sortStatus.type !== type);

    value.unshift({ type, order });

    this.sortStatus.next(value);
  }
}
