import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { GeneralAssembly } from '@core/models';
import { BaseService } from '@core/services/base.service';

@Injectable()
export class GeneralAssemblyService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  public getAssemblies(page: number, size: number): Observable<any> {
    return this.http
      .get(`/assemblies?page=${page}&size=${size}`)
      .map((res) => res)
      .catch((error) => Observable.throw(error.json() || { status: 500, error: 'Server error' }));
  }

  public getAssembly(id: string): Observable<any> {
    return this.http
      .get(`/assemblies/${id}`)
      .map((res) => res)
      .catch((error) => Observable.throw(error.json() || { status: 500, error: 'Server error' }));
  }

  public addAssembly(assembly: GeneralAssembly): Observable<any> {
    return this.http
      .post('/assemblies', JSON.stringify(assembly), this.getOptions())
      .map((res) => res)
      .catch((error) => Observable.throw(error.json() || { status: 500, error: 'Server error' }));
  }

  public uploadDocument(assemblyId: string, docType: string, doc: File): Observable<any> {
    const body = new FormData();
    body.append('file', doc, doc.name);
    const headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    return this.http
      .post(`/assemblies/${assemblyId}/doc/${docType}`, body, { headers })
      .map((res) => res)
      .catch((error) => Observable.throw(error.json() || { status: 500, error: 'Server error' }));
  }

  public vote(id: string, vote: Map<string, string>): Observable<any> {
    return this.http
      .put(`/assemblies/${id}`, JSON.stringify(vote), this.getOptions())
      .map((res) => res)
      .catch((error) => Observable.throw(error.json() || { status: 500, error: 'Server error' }));
  }
}
