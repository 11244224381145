<div class="xs-sidebar border-xs-black-xs background-blue4-xs">
  <div>
    <!-- User information -->
    <div class="user-info col-xs-12 padding-top-sm-xs padding-bottom-sm-xs min-height-100-xs hidden-xs">
      <img src="../../../../assets/img/avatar.png" height="70px" width="55px" />
      <div class="padding-left-md-xs padding-right-none-xs padding-right-sm-lg">
        <div class="col-xs-12 dosis-bold padding-none-xs margin-top-xs-xs text-18-xs text-white-xs text-left text-bold">
          {{ user.firstname }} {{ user.lastname }}
        </div>
        <div class="col-xs-12 dosis-medium padding-none-xs margin-top-xs-xs text-white-xs text-left">
          <strong class="dosis-bold">{{ 'home.sidebar.userId' | translate }}</strong>
          {{ user.eluNumber }}
        </div>
      </div>
    </div>
    <!-- SM, MD and LG sidebar -->
    <div
      *ngFor="let feature of features; let last = last"
      class="col-xs-12 hidden-xs padding-left-xs-xs min-height-50-xs line-height-50-xs padding-right-xs-xs text-white-xs border-top-blue"
      [ngClass]="{
        'sidebar-tab-selected': selectedTab === feature.activeTab,
        'border-bottom-blue': last
      }"
      (click)="navigation.selectFeature(feature.name)"
    >
      <span class="col-xs-12 padding-left-xs-xs padding-right-none-xs dosis-semibold text-left text-14-xs cursor-hand">
        <img
          src="{{ feature.icon }}"
          width="25px"
          height="25px"
          class="margin-left-xs-xs margin-left-sm-md margin-right-xs-xs margin-right-md-md"
        />
        {{ feature.label | translate }}
      </span>
    </div>
  </div>
</div>
