import { Directive, forwardRef } from '@angular/core';
import { FormControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[n9-birth][ngModel],[n9-birth][formControl]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BirthDateValidatorDirective),
      multi: true
    }
  ]
})
export class BirthDateValidatorDirective implements Validator {
  static validDate(control: FormControl): object {
    const date: Date = new Date(control.value);
    const minDate = new Date('1900-01-01');

    return minDate <= date ? null : { n9Birth: { valid: false } };
  }

  constructor() {}

  validate(control: FormControl): object {
    return BirthDateValidatorDirective.validDate(control);
  }
}
