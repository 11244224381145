import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User, UserDTO } from '@core/models';
import { Membership } from '@core/models/membership.model';
import { UserRequest } from '@core/models/request.model';

@Injectable()
export class RequestService {
  constructor(private http: HttpClient) {}

  public createMandateRequest(membership: Membership): Observable<any> {
    return this.http
      .post(`/membership/mandate/request`, membership)
      .take(1)
      .map((r) => r)
      .catch((err) => Observable.throwError(err));
  }

  public personalDataRequest(data: UserDTO): Observable<any> {
    return this.http
      .post('/users/request', data)
      .take(1)
      .map((r) => r)
      .catch((err) => Observable.throwError(err));
  }

  public contractContributionRateRequest(contractId: number, contributionRate: string): Observable<any> {
    return this.http
      .post(`/contracts/${contractId}/contribution/request`, {
        contributionRate
      })
      .take(1)
      .map((r) => r)
      .catch((err) => Observable.throwError(err));
  }

  public contractBeneficiaryClauseRequest(contractId: number, beneficiaryClause: object): Observable<any> {
    return this.http
      .post(`/contracts/${contractId}/clause/request`, beneficiaryClause)
      .take(1)
      .map((r) => r)
      .catch((err) => Observable.throwError(err));
  }

  public contractRetroactivityDateRequest(contractId: number, retroactivity: object): Observable<any> {
    return this.http
      .post(`/contracts/${contractId}/retroactivity/request`, retroactivity)
      .take(1)
      .map((r) => r)
      .catch((err) => Observable.throwError(err));
  }
}
