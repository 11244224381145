import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { BeneficiariesDivision } from '@core/models/beneficiaries-division.model';
import { Beneficiary } from '@core/models/beneficiary.model';
import { MembershipMandate } from '@core/models/membership-mandate.model';
import { LatinAlphabeticValidatorDirective } from '@core/validators/latin-alphabetic-validator.directive';
import { LatinAlphanumericValidatorDirective } from '@core/validators/latin-alphanumeric-validator.directive';
import { PhoneValidatorDirective } from '@core/validators/phone-validator.directive';
import { ZipCodeValidatorDirective } from '@core/validators/zip-code-validator.directive';
import { SubscriptionService } from '../subscription.service';
import { MandatesService } from './mandates.service';

const validatorsType = {
  collectivityType: [Validators.required],
  collectivityName: [Validators.required, Validators.pattern(/^[a-zà-ÿ0-9'-]+( ?[a-zà-ÿ0-9'-]+)*$/i)],
  type: [Validators.required],
  epciName: [Validators.required, LatinAlphabeticValidatorDirective.validInput],
  monthlyContribution: [Validators.required],
  isRetroactive: [Validators.required],
  retroactivityType: [Validators.required],
  dueDate: [Validators.required],
  optinContribution: [Validators.required],
  staggeringMonth: [Validators.required],
  inCaseOfDeath: [Validators.required],
  divisionType: [Validators.required],
  notaryLastname: [Validators.required, LatinAlphabeticValidatorDirective.validInput],
  notaryFirstname: [Validators.required, LatinAlphabeticValidatorDirective.validInput],
  notaryPhone: [PhoneValidatorDirective.validInput],
  address: [Validators.required, LatinAlphanumericValidatorDirective.validInput],
  postalCode: [Validators.required, ZipCodeValidatorDirective.validInput],
  city: [Validators.required, LatinAlphanumericValidatorDirective.validInput]
};

@Component({
  selector: 'n9-mandates',
  templateUrl: './mandates.component.html',
  styleUrls: ['./mandates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MandatesComponent implements OnInit {
  @Input() isMembership: boolean;

  validatorsType: object;
  beneficiariesOnStart: Beneficiary[];
  priorityOnStart: string[];
  membershipMandateOnStart: MembershipMandate;
  stepNumber: number = 2;
  mandateForm: FormGroup;
  undersignedForm: FormGroup;
  alteredMandate: object = {};
  mandates$: BehaviorSubject<MembershipMandate[]> = new BehaviorSubject<MembershipMandate[]>(
    JSON.parse(localStorage.getItem('mandates')) || []
  );
  uploadedFiles: File[] = [];
  images: string[] = [];
  uploadedFileIds: string[] = [];
  eventNewBeneficiary: Subject<number> = new Subject<number>();
  referentialTypes: object = {
    mairie: 'Mairie',
    epci: 'EPCI',
    departements: 'Conseil Départemental',
    regions: 'Conseil Régional / Territorial'
  };
  showErrors: boolean = false;
  fileError: string = '';

  constructor(
    private fb: FormBuilder,
    public subscriptionService: SubscriptionService,
    private mandatesService: MandatesService
  ) {
    this.mandateForm = this.fb.group({
      collectivityType: ['', validatorsType['collectivityType']],
      collectivityName: ['', validatorsType['collectivityName']],
      type: ['', validatorsType['type']],
      epciName: ['', []],
      monthlyContribution: ['', validatorsType['monthlyContribution']],
      isRetroactive: ['', validatorsType['isRetroactive']],
      retroactivityType: ['', validatorsType['retroactivityType']],
      mandateStartDate: [],
      dueDate: [],
      optinContribution: [],
      staggeringMonth: ['0', []],
      inCaseOfDeath: ['', validatorsType['inCaseOfDeath']],
      beneficiaries: this.fb.array([]),
      beneficiariesDivision: this.fb.group({
        divisionType: [''],
        beneficiaryPriorityOrder: this.fb.array([]),
        notaryFirstname: [''],
        notaryLastname: [''],
        notaryPhone: [''],
        notaryAddress: this.fb.group({
          address: [''],
          complement: [''],
          postalCode: [''],
          city: ['']
        })
      })
    });

    this.undersignedForm = this.fb.group({
      optinHonorCertification: ['', Validators.required],
      optinGeneralConditions: ['', Validators.required],
      optinCNIL: ['', Validators.required],
      idcard: ['', Validators.required]
    });

    this.validatorsType = validatorsType;
  }

  ngOnInit(): void {
    // Retrieves data from server
    if (localStorage.getItem('membershipMarketingId')) {
      this.subscriptionService.getSubscriberValues().subscribe(
        (values: MembershipMandate[]) => {
          if (values['mandates'] && values['mandates'].length >= 1) {
            this.mandates$.next([]);
            localStorage.removeItem('mandates');
            localStorage.removeItem('manadatePending');
            values['mandates'].map((m) => this.addMandate(m));
          }
          ['optinHonorCertification', 'optinGeneralConditions', 'optinCNIL'].forEach((code) => {
            if (values[code]) this.undersignedForm.controls[code].setValue(true);
          });
          if (values['identityDocumentIds'] && values['identityDocumentIds'].length > 0) {
            this.uploadedFileIds = values['identityDocumentIds'];
            this.uploadedFileIds.forEach((id: string, index: number) =>
              this.mandatesService.downloadImageFile(id).subscribe(
                (res) => {
                  res.body.name = res.headers.get('x-filename');
                  this.uploadedFiles.push(res.body as File);
                  this.toBase64(res.body, index);
                  this.undersignedForm.get('idcard').clearValidators();
                  this.undersignedForm.get('idcard').updateValueAndValidity();
                },
                (err) => {}
              )
            );
          }
        },
        (error) => Observable.throwError(error)
      );
    }

    if (localStorage.getItem('mandatePending')) {
      this.membershipMandateOnStart = JSON.parse(localStorage.getItem('mandatePending'));
      if (this.membershipMandateOnStart.dueDate) {
        this.membershipMandateOnStart.dueDate = new Date(this.membershipMandateOnStart.dueDate);
      }
      this.mandateForm.patchValue(this.membershipMandateOnStart);
      this.beneficiariesOnStart = this.membershipMandateOnStart.beneficiaries;
      this.mandateForm.get('beneficiaries').patchValue(this.beneficiariesOnStart);
      if (
        this.membershipMandateOnStart.beneficiariesDivision &&
        this.membershipMandateOnStart.beneficiariesDivision.beneficiaryPriorityOrder &&
        this.membershipMandateOnStart.beneficiariesDivision.beneficiaryPriorityOrder.length > 0
      ) {
        this.priorityOnStart = this.membershipMandateOnStart.beneficiariesDivision.beneficiaryPriorityOrder;
      }
    }

    this.mandates$.subscribe((mandates) => {
      localStorage.setItem('mandates', JSON.stringify(mandates));
    });

    this.mandateForm.valueChanges.subscribe((value: MembershipMandate) => {
      if (this.alteredMandate['index'] === undefined) localStorage.setItem('mandatePending', JSON.stringify(value));
    });
  }

  validateMandateForm(): void {
    Object.keys(this.mandateForm.controls).forEach((key) => {
      if (key !== 'inCaseOfDeath') {
        this.mandateForm.get(key).updateValueAndValidity();
      }
    });
  }

  async onSubmit(): Promise<any> {
    this.validateMandateForm();
    if (
      (!this.mandateForm.valid && this.mandates$.value.length < 1) ||
      !this.undersignedForm.valid ||
      (!this.checkIsFormVisibleValid() && !this.mandateForm.valid)
    ) {
      this.showErrors = true;
      return;
    }
    if (this.mandateForm.valid && !this.alteredMandate['index']) {
      this.addMandate(this.mandateForm.getRawValue());
    }
    this.uploadedFileIds = await this.uploadFiles();
    this.sendForm(this.uploadedFileIds);
  }

  async uploadFiles(): Promise<any> {
    const ids = [];
    for (const file of this.uploadedFiles) {
      ids.push(await this.uploadOneFile(file));
    }
    return ids;
  }

  async uploadOneFile(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      this.mandatesService.uploadImageFile(file).subscribe(
        (res) => resolve(res.id),
        (error) => {
          this.undersignedForm.get('idcard').setErrors({ fileUploadError: true });
          this.showErrors = true;
        }
      );
    });
  }

  sendForm(fileIds: string[]): void {
    this.subscriptionService.getSubscriberValues().subscribe((response) => {
      this.subscriptionService
        .validateMandates(
          Object.assign(response, {
            mandates: this.mandates$.value,
            optinHonorCertification: this.undersignedForm.get('optinHonorCertification').value,
            optinGeneralConditions: this.undersignedForm.get('optinGeneralConditions').value,
            optinCNIL: this.undersignedForm.get('optinCNIL').value,
            identityDocumentIds: fileIds
          })
        )
        .subscribe(
          () => {
            localStorage.removeItem('mandates');
            localStorage.removeItem('mandatePending');

            this.subscriptionService.goForward(this.stepNumber);
          },
          (error) => Observable.throwError(error)
        );
    });
  }

  public onAlterEvent(event: object): void {
    if (this.alteredMandate['index'] !== event['index']) {
      this.alteredMandate = event;

      switch (this.alteredMandate['type']) {
        case 'update':
          this.fromMandateToForm(this.alteredMandate['index']);
          break;
        case 'delete':
          this.mandates$.next(this.mandates$.value.filter((m, i) => i !== this.alteredMandate['index']));
          this.alteredMandate = {};
          break;
      }
    }
  }

  public fromMandateToForm(index: number): void {
    const mandate: MembershipMandate = this.mandates$.value[index];

    this.formatResponseData(mandate);

    if (mandate['collectivityType'] !== 'Mairie') {
      this.mandatesService.getReferential(
        Object.keys(this.referentialTypes).find((k) => this.referentialTypes[k] === mandate['collectivityType'])
      );
    }

    this.mandateForm.get('inCaseOfDeath').setValue(mandate.inCaseOfDeath);

    if (mandate.beneficiaries && mandate.inCaseOfDeath === 'Désignation expresse') {
      // tslint:disable-next-line:prefer-for-of
      for (let i: number = 0; i < mandate.beneficiaries.length; i++) {
        mandate.beneficiaries[i].company = mandate.beneficiaries[i].company === 'true';
        if (i > 0) this.eventNewBeneficiary.next();
      }
    }

    this.mandateForm.patchValue(mandate);
    const priorityOrder = mandate.beneficiariesDivision.beneficiaryPriorityOrder;

    if (priorityOrder) {
      const model = new Array();

      for (const value of priorityOrder) model.push({ priority: value });

      this.mandateForm.get('beneficiariesDivision.beneficiaryPriorityOrder').setValue(model);
    }
  }

  // Stringify data recursively and convert date to ISO format
  public formatResponseData(data: object): void {
    if (data) {
      for (const [key, value] of Object.entries(data)) {
        if (typeof value === 'string' && value.match(/\d{4}-\d{2}-\d{2}/g)) data[key] = new Date(value);
        else if (typeof value !== 'object') data[key] = value.toString();
        else this.formatResponseData(data[key]);
      }
    }
  }

  public addMandate(mandate: MembershipMandate, formDirective?: FormGroupDirective): void {
    let updated: MembershipMandate[] = this.mandates$.value;
    const division: BeneficiariesDivision = mandate.beneficiariesDivision;

    this.showErrors = false;

    // Add all three division types to response and set boolean according to form value
    for (const divisionType of ['equalShares', 'priorityOrder', 'perPercent']) {
      division[divisionType] = division['divisionType'] === divisionType;
    }

    if (mandate.beneficiaries) {
      if (mandate.beneficiariesDivision.equalShares) {
        delete division.beneficiaryPriorityOrder;
      }
      if (mandate.beneficiaries.length === 1) {
        delete division.divisionType;
        delete division.beneficiaryPriorityOrder;
      }
      if (mandate.beneficiaries.length < 1) {
        delete division.divisionType;
        delete mandate.beneficiaries;
      }
    }

    if (division.beneficiaryPriorityOrder && division.beneficiaryPriorityOrder.length > 0) {
      division.beneficiaryPriorityOrder = division.beneficiaryPriorityOrder.map((v) => v['priority'] || v);
    }

    if (mandate.beneficiariesDivision) {
      mandate.beneficiariesDivision.notary = mandate.inCaseOfDeath === 'Désignation notariale';
    }

    if (this.alteredMandate['index'] !== undefined) updated[this.alteredMandate['index']] = mandate;
    else updated = [...updated, mandate];

    this.alteredMandate = {};
    this.mandates$.next(updated);
    this.resetNewMandateForm(formDirective);
  }

  public resetNewMandateForm(formDirective?: FormGroupDirective): void {
    if (formDirective) formDirective.resetForm();
    this.mandateForm.reset();

    this.mandateForm.get('mandateStartDate').setValue('');
    this.mandateForm.get('dueDate').setValue('');
    this.mandateForm.controls['dueDate'].clearValidators(), this.mandateForm.get('dueDate').updateValueAndValidity();
  }

  public fileChange(newValue: any): string {
    return JSON.stringify(newValue);
  }

  public getFilesAsArray(files: FileList): void {
    const numberBase = this.uploadedFiles.length;

    if (numberBase + files.length > 4) {
      this.fileError = 'Attention, vous ne pouvez rajouter que 4 fichiers au maximum !';
      return;
    } else {
      this.fileError = '';
    }
    const newFile = Array.from(files).map((file: File, index: number) => {
      if (file.type.match('image/.+')) this.toBase64(file, index + numberBase);

      return file;
    });
    newFile.forEach((file) => this.uploadedFiles.push(file));
    if (files.length > 0) {
      this.undersignedForm.get('idcard').setErrors(null);
    }
  }

  public deleteFile(index: number): void {
    if (this.uploadedFiles && this.uploadedFiles[index]) {
      if (this.uploadedFileIds.length > 0) {
        this.mandatesService
          .deleteImageFile(localStorage.getItem('membershipMarketingId'), this.uploadedFileIds[index])
          .subscribe(
            (res) => {
              this.uploadedFileIds = this.uploadedFileIds.filter((file, i) => i !== index);
            },
            (err) => {}
          );
      }
      this.uploadedFiles = this.uploadedFiles.filter((file, i) => index !== i);

      this.images = this.images.filter((image, i) => i !== index);

      if (this.uploadedFiles.length <= 0) this.undersignedForm.get('idcard').reset();

      this.undersignedForm.get('idcard').setValidators(Validators.required);
      this.undersignedForm.get('idcard').updateValueAndValidity();
    }
  }

  public toBase64(file: File, index: number): void {
    const fileReader: FileReader = new FileReader();

    fileReader.onload = (e: any) => {
      this.images[index] = e.target.result;
    };
    fileReader.readAsDataURL(file);
  }

  public isMandateFormValid(): boolean {
    const mandateform: MembershipMandate = this.mandateForm.getRawValue();
    if (mandateform.inCaseOfDeath === 'Désignation expresse')
      return this.mandateForm.valid && mandateform.beneficiaries.length > 0;
    return this.mandateForm.valid;
  }

  public isMandateFormDirty(): boolean {
    return this.mandateForm.dirty;
  }

  public checkIsFormVisibleValid(): boolean {
    return (
      (this.mandateForm.value.collectivityType === null || this.mandateForm.value.collectivityType === '') &&
      (this.mandateForm.value.collectivityName === null || this.mandateForm.value.collectivityName === '') &&
      (this.mandateForm.value.type === null || this.mandateForm.value.type === '') &&
      this.mandateForm.value.monthlyContribution === null &&
      this.mandateForm.value.isRetroactive === null &&
      this.mandateForm.value.inCaseOfDeath === null
    );
  }
}
