import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Membership } from '@core/models/membership.model';

@Injectable()
export class MembershipService {
  constructor(private http: HttpClient) {}

  public getMembershipsByStatus(status: string, page?: number, size?: number): Observable<object> {
    return this.http
      .get('/membership/list?status=' + status + (page ? '&page=' + page : '') + (size ? '&size=' + size : ''))
      .map((memberships) => memberships)
      .catch((err) => Observable.throwError(err));
  }

  public getAllMemberships(page: number = 1, size: number = 10): Observable<object> {
    return this.http
      .get(`/membership/all?page=${page}&size=${size}`)
      .map((memberships) => memberships)
      .catch((err) => Observable.throwError(err));
  }

  public getMembershipByMarketingId(marketingId: string): Observable<object> {
    return this.http
      .get('/membership/' + marketingId)
      .map((membership) => membership)
      .catch((err) => Observable.throwError(err));
  }

  public updateMembershipStatus(membership: Membership, status: string): Observable<object> {
    return this.http
      .put('/membership/' + membership.marketingId + '/status/' + status, membership)
      .map((m) => m)
      .catch((err) => Observable.throwError(err));
  }
}
