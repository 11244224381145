import { Component, OnInit, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject } from 'rxjs';
import { PageableResult, User } from '@core/models';
import { MembershipMandate } from '@core/models/membership-mandate.model';
import { MembershipStatus } from '@core/models/membership-status.model';
import { UserRequest, USER_REQUEST_STATUS, USER_REQUEST_TYPE } from '@core/models/request.model';
import { SessionService } from '@core/session/session.service';
import { HistoryService } from '@shared/services/history.service';
import { SignatureService } from '../../../subscription/signature/signature.service';

@Component({
  selector: 'n9-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent {
  page: number;
  size: number;
  nbElements: number;
  keys: any;
  userRequests: UserRequest[];
  userRequestTypes: typeof USER_REQUEST_TYPE;
  userRequestStatus: typeof USER_REQUEST_STATUS;
  modalRef: BsModalRef;
  selectedType: USER_REQUEST_TYPE;
  selectedStatus: USER_REQUEST_STATUS;
  statusArray: string[];
  availableStatusChange: string[];
  array: any = Array;
  status: typeof MembershipStatus;
  manager: User;

  constructor(
    private historyService: HistoryService,
    private translateService: TranslateService,
    private modalService: BsModalService,
    private sessionService: SessionService,
    private signatureService: SignatureService
  ) {
    this.page = 0;
    this.size = 20;
    this.keys = Object.keys;
    this.userRequests = [];
    this.userRequestTypes = USER_REQUEST_TYPE;
    this.userRequestStatus = USER_REQUEST_STATUS;
    this.selectedType = USER_REQUEST_TYPE.ALL;
    this.selectedStatus = USER_REQUEST_STATUS.ALL;
    this.statusArray = Object.keys(USER_REQUEST_STATUS);
    this.availableStatusChange = [
      USER_REQUEST_STATUS.DONE,
      USER_REQUEST_STATUS.IN_PROGRESS,
      USER_REQUEST_STATUS.REJECTED,
      USER_REQUEST_STATUS.SENT
    ];
    this.status = MembershipStatus;
    this.manager = this.sessionService.getUser();
  }

  public editRequestStatus(status: USER_REQUEST_STATUS, requestId: string, index: number): void {
    this.historyService.editRequestStatus(requestId, status).subscribe((request) => {
      this.userRequests[index] = request;
    });
  }

  public downloadDocummentWithId(fileId: string): void {
    this.signatureService.downloadSignedFile(fileId).subscribe(
      (res) => {
        saveAs(res.body, res.headers.get('x-filename'));
      },
      (err) => {
        alert('error occured');
      }
    );
  }

  public getUsersRequestsFromManager(event?: any): void {
    this.page = event ? event.page - 1 : 0;
    const type = this.selectedType === USER_REQUEST_TYPE.ALL ? null : this.selectedType;
    const status = this.selectedStatus === USER_REQUEST_STATUS.ALL ? null : this.selectedStatus;

    this.historyService
      .searchUsersRequestsFromManager(this.manager.idGest, type, status, this.page, this.size)
      .subscribe((h: PageableResult<UserRequest>) => {
        this.nbElements = h.nbElements;
        this.userRequests = (h.content || []).filter((request: UserRequest) => {
          // Do not display memberships with these status
          return ![
            MembershipStatus.SIGNATURE_READY,
            MembershipStatus.SIGNATURE_EXPIRED,
            MembershipStatus.SIGNATURE_REFUSED
          ].includes(request.status);
        });
      });
  }

  public openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
  }

  public toBehaviorSubject(mandates: MembershipMandate[]): BehaviorSubject<MembershipMandate[]> {
    return new BehaviorSubject<MembershipMandate[]>(mandates);
  }
}
