<section class="col-xs-12 text-center margin-bottom-lg-xs">
  <p class="glyphicon glyphicon-ok-circle"></p>
  <p class="text-18-xs margin-top-md-xs">
    {{ 'subscription.confirmation.title1' | translate }}
    <br />
    {{ 'subscription.confirmation.title2' | translate }}
  </p>

  <button
    class="col-lg-4 col-lg-offset-4 col-sm-6 col-sm-offset-3 col-xs-12 margin-top-md-xs padding-top-md-xs padding-bottom-md-xs padding-left-md-xs padding-right-md-xs background-blue3-xs dosis-bold text-16-xs text-white-xs text-uppercase border-none-xs"
    (click)="this.downloadDocument()"
  >
    {{ 'subscription.confirmation.download' | translate }}
  </button>

  <button
    class="col-lg-4 col-lg-offset-4 col-sm-6 col-sm-offset-3 col-xs-12 margin-top-md-xs padding-top-md-xs padding-bottom-md-xs padding-left-md-xs padding-right-md-xs background-blue3-xs dosis-bold text-16-xs text-white-xs text-uppercase border-none-xs"
    (click)="this.subscriptionService.selectedStep = 0"
  >
    {{ 'subscription.confirmation.return' | translate }}
  </button>
</section>
