import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BeneficiariesDivision } from '@core/models/beneficiaries-division.model';
import { MembershipMandate } from '@core/models/membership-mandate.model';

@Component({
  selector: 'n9-mandate-infos',
  templateUrl: './mandate-infos.component.html',
  styleUrls: ['./mandate-infos.component.scss']
})
export class MandateInfosComponent implements OnInit {
  @Input() mandates$: BehaviorSubject<MembershipMandate[]>;
  @Input() isAlterable: boolean = false;
  @Output() alter: EventEmitter<object> = new EventEmitter<object>();

  isCollapsed: boolean[];

  constructor() {}

  public ngOnInit(): void {
    this.mandates$.subscribe((mandates: MembershipMandate[]) => {
      this.isCollapsed = Array(mandates.length).fill(true, 0, mandates.length);
    });
  }

  public onAlterClick(event: Event, index: number, type: string): void {
    event.preventDefault();
    event.stopPropagation();
    this.alter.emit({ index, type });
  }
}
