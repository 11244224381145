import { Component } from '@angular/core';

import { User } from '@core/models';
import { AdminNavigationService } from '@modules/admin/home/shared';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'n9-user-detail',
  templateUrl: 'user-detail.component.html',
  styleUrls: ['user-detail.component.scss']
})
export class UserDetailComponent {
  public user: User;
  public selectedTab: number;

  constructor(private navigation: AdminNavigationService, private userService: UserService) {
    this.selectedTab = 1;

    const urlComponents: string[] = this.navigation.getCurrentRoute().split('/');

    this.userService.getUser(urlComponents[urlComponents.length - 1]).subscribe(
      (res) => (this.user = res),
      (error) => this.handleError(error)
    );
  }

  public switchTab(tabNumber: number): void {
    this.selectedTab = tabNumber;
  }

  private handleError(error: any): void {
    if (error && error.status) {
      if (error.status === 404 || error.status === 500) {
        this.navigation.navigate('users');
        return;
      }
    }

    this.navigation.navigateToLogin();
  }
}
