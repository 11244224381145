import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { SessionService } from '../../session/session.service';
import { SharedModule } from '@shared/shared.module';

import { HeaderComponent } from './header.component';
import { HomeNavigationService } from '../../index';

@NgModule({
  providers: [HomeNavigationService, SessionService],
  imports: [CommonModule, SharedModule.forRoot(), BsDropdownModule],
  declarations: [HeaderComponent],
  exports: [HeaderComponent]
})
export class HeaderModule {}
