<div class="row" *ngIf="dataSent !== ds.SUCCESS && dataSent !== ds.FORBIDDEN">
  <div class="col-xs-5">
    <div class="padding-sm-xs background-blue2-xs margin-bottom-md-xs">
      <div class="media">
        <div class="media-left padding-sm-xs">
          <img src="../../../../../../assets/img/avatar.png" width="40px" />
        </div>
        <div class="media-body padding-left-sm-xs">
          <p class="margin-bottom-xs-xs">
            <span class="openSans-bold text-blue4-xs"
              >{{ 'home.contracts.contribution.mandate' | translate }}
              :
            </span>
            <span class="openSans-semibold text-blue4-xs">
              {{ contract?.mandate }}
            </span>
          </p>
          <p class="margin-bottom-xs-xs">
            <span class="openSans-bold text-blue4-xs"
              >{{ 'home.contracts.contribution.subscribeDate' | translate }}
              :
            </span>
            <span class="openSans-semibold text-blue4-xs">
              {{ contract?.subscriptionDate | date: 'dd/MM/yyyy' }}
            </span>
          </p>
          <p class="margin-none-xs">
            <span class="openSans-bold text-blue4-xs"
              >{{ 'home.contracts.contribution.contributionRate' | translate }}
              :
            </span>
            <span class="openSans-semibold text-blue4-xs">
              {{ contract?.contributionRate }}
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>

  <form novalidate [formGroup]="beneficiaryClauseForm" (ngSubmit)="onSubmit()" class="form">
    <div class="row col-xs-12">
      <div class="col-xs-12">
        <label class="padding-none-xs text-left openSans-bold text-bold text-black-xs">
          {{ 'home.contracts.clause.form.modifyClause' | translate }}
          :
        </label>

        <div id="inCaseOfDeath">
          <p>
            <label class="radio-label openSans-bold" for="successor">
              <input
                type="radio"
                name="inCaseOfDeath"
                id="successor"
                value="Régime général"
                class="margin-right-sm-xs"
                formControlName="inCaseOfDeath"
                #successor
              />
              {{ 'home.contracts.clause.clauses.general.title' | translate }}
              :
              <span class="openSans-regular not-bold">{{
                'home.contracts.clause.clauses.general.description' | translate
              }}</span>
            </label>
          </p>
          <p>
            <label class="radio-label openSans-bold" for="express">
              <input
                type="radio"
                name="inCaseOfDeath"
                id="express"
                value="Désignation expresse"
                class="margin-right-sm-xs"
                formControlName="inCaseOfDeath"
                #express
              />
              {{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.label' | translate }}
            </label>
          </p>
          <p>
            <label class="radio-label openSans-bold" for="notary">
              <input
                type="radio"
                name="inCaseOfDeath"
                id="notary"
                value="Désignation notariale"
                class="margin-right-sm-xs"
                formControlName="inCaseOfDeath"
                #notary
              />
              {{ 'subscription.mandates.form.inCaseOfDeath.notary.label' | translate }}
            </label>
          </p>
        </div>
      </div>

      <div [hidden]="!express.checked" class="col-xs-12">
        <ng-container #beneficiaries></ng-container>

        <button
          class="button-mandate add-beneficiary text-uppercase text-center openSans-semibold border-blue3-sm-xs margin-top-lg-xs margin-bottom-lg-xs"
          (click)="createNewBeneficiary()"
          style="width: 350px"
        >
          <span
            class="glyphicon glyphicon-plus borders-20-percent background-blue3-xs text-white-xs padding-xs-xs margin-right-xs-xs"
          ></span>
          {{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.addBeneficiary' | translate }}
        </button>

        <div
          *ngIf="beneficiaryContainer?.length > 1"
          formGroupName="beneficiariesDivision"
          class="inFormArray border-none-xs"
        >
          <p class="text-uppercase dosis-bold text-16-xs text-blue3-xs">
            {{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.division.title' | translate }}
          </p>

          <input type="radio" id="equalShares" name="divisionType" formControlName="divisionType" value="equalShares" />
          <label for="equalShares" class="padding-left-sm-xs text-14-xs opensans-bold">
            {{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.division.divisionTypes.0' | translate }}
          </label>
          <br />

          <input
            type="radio"
            id="priorityOrder"
            name="divisionType"
            formControlName="divisionType"
            value="priorityOrder"
            (change)="resetPriorityValues()"
            #priorityOrder
          />
          <label for="priorityOrder" class="padding-left-sm-xs text-14-xs opensans-bold">
            {{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.division.divisionTypes.1' | translate }}
          </label>
          <br />

          <div *ngIf="priorityOrder.checked" class="col-xs-12 margin-top-lg-xs margin-bottom-lg-xs">
            <div
              *ngFor="
                let b of beneficiaryClauseForm.get('beneficiariesDivision.beneficiaryPriorityOrder').value;
                let i = index;
                trackBy: trackByFn
              "
              class="col-xs-4 inFormArray"
              formArrayName="beneficiaryPriorityOrder"
            >
              <span class="text-14-xs opensans-bold">
                {{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.division.priorityOrder' | translate
                }}{{ i + 1 }}
              </span>
              <br />

              <div formGroupName="{{ i }}">
                <select id="priority-{{ i }}" class="form-control input-lg text-14-xs" formControlName="priority">
                  <option value="">
                    {{ 'subscription.common.form.select' | translate }}
                  </option>
                  <option
                    *ngFor="
                      let b of beneficiaryClauseForm.get('beneficiariesDivision.beneficiaryPriorityOrder').value;
                      let i = index
                    "
                    value="{{ i + 1 }}"
                  >
                    {{ i + 1 }}
                  </option>
                </select>
              </div>
            </div>
            <div
              *ngIf="
                !beneficiaryClauseForm.get('beneficiariesDivision.beneficiaryPriorityOrder').valid &&
                beneficiaryClauseForm.get('beneficiariesDivision.beneficiaryPriorityOrder').dirty
              "
              class="col-xs-12"
            >
              <span class="text-red1-xs opensans-bold text-12-xs">
                {{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.division.errors.order' | translate }}
              </span>
            </div>
          </div>

          <input
            type="radio"
            id="perPercent"
            name="divisionType"
            formControlName="divisionType"
            value="perPercent"
            (change)="resetPriorityValues()"
            #perPercent
          />
          <label for="perPercent" class="padding-left-sm-xs text-14-xs opensans-bold">
            {{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.division.divisionTypes.2' | translate }}
          </label>

          <div *ngIf="perPercent.checked">
            <div
              *ngFor="
                let b of beneficiaryClauseForm.get('beneficiariesDivision.beneficiaryPriorityOrder').value;
                let i = index;
                trackBy: trackByFn
              "
              class="col-xs-4 inFormArray"
              formArrayName="beneficiaryPriorityOrder"
            >
              <span class="text-14-xs opensans-bold">
                {{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.division.perPercent' | translate }}{{ i + 1 }}
              </span>
              <br />

              <div formGroupName="{{ i }}">
                <input id="percent-{{ i }}" class="form-control input-lg text-14-xs" formControlName="priority" />
              </div>
            </div>
            <div
              *ngIf="
                !beneficiaryClauseForm.get('beneficiariesDivision.beneficiaryPriorityOrder').valid &&
                beneficiaryClauseForm.get('beneficiariesDivision.beneficiaryPriorityOrder').dirty
              "
              class="col-xs-12"
            >
              <span class="text-red1-xs opensans-bold text-12-xs">
                {{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.division.errors.perPercent' | translate }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        [hidden]="!notary.checked"
        class="col-xs-12 margin-none-xs border-none-xs"
        formGroupName="beneficiariesDivision"
      >
        <div class="col-xs-12 padding-none-xs margin-top-lg-xs">
          <p class="dosis-bold text-16-xs text-uppercase text-blue3-xs padding-left-none-xs">
            {{ 'subscription.mandates.form.inCaseOfDeath.notary.title' | translate }}
          </p>
        </div>

        <div class="col-xs-6 padding-left-none-xs margin-top-xs-xs">
          <label
            for="notary-firstname"
            class="padding-none-xs text-left openSans-bold text-12-xs text-bold text-black-xs"
          >
            {{ 'subscription.mandates.form.inCaseOfDeath.notary.firstname' | translate }}
            <span class="text-red1-xs"> *</span>
            :
          </label>
          <input
            type="text"
            (input)="formatControlValue('beneficiariesDivision.notaryFirstname')"
            class="form-control input-lg text-14-xs"
            id="notary-firstname"
            formControlName="notaryFirstname"
          />
          <p
            *ngIf="
              beneficiaryClauseForm.get('beneficiariesDivision.notaryFirstname').errors &&
              beneficiaryClauseForm.get('beneficiariesDivision.notaryFirstname').dirty
            "
            class="text-red1-xs text-12-xs openSans-bold margin-none-xs margin-top-sm-xs"
          >
            {{ 'subscription.mandates.form.inCaseOfDeath.notary.errors.lettersOnly' | translate }}
          </p>
        </div>

        <div class="col-xs-6 margin-top-xs-xs">
          <label
            for="notary-lastname"
            class="padding-none-xs text-left openSans-bold text-12-xs text-bold text-black-xs"
          >
            {{ 'subscription.mandates.form.inCaseOfDeath.notary.lastname' | translate }}
            <span class="text-red1-xs"> *</span>
            :
          </label>
          <input
            type="text"
            (input)="formatControlValue('beneficiariesDivision.notaryLastname')"
            class="form-control input-lg text-14-xs"
            id="notary-lastname"
            formControlName="notaryLastname"
          />
          <p
            *ngIf="
              beneficiaryClauseForm.get('beneficiariesDivision.notaryLastname').errors &&
              beneficiaryClauseForm.get('beneficiariesDivision.notaryLastname').dirty
            "
            class="text-red1-xs text-12-xs openSans-bold margin-none-xs margin-top-sm-xs"
          >
            {{ 'subscription.mandates.form.inCaseOfDeath.notary.errors.lettersOnly' | translate }}
          </p>
        </div>

        <div formGroupName="notaryAddress" class="border-none-xs">
          <div class="col-xs-6 padding-left-none-xs margin-top-sm-xs">
            <label for="address" class="padding-none-xs text-left openSans-bold text-12-xs text-black-xs text-bold">
              {{ 'subscription.mandates.form.inCaseOfDeath.notary.address' | translate }}
              <span class="text-red1-xs"> *</span>
              :
            </label>
            <input
              type="text"
              (input)="formatControlValue('beneficiariesDivision.notaryAddress.address')"
              class="form-control input-lg text-14-xs"
              id="address"
              formControlName="address"
            />
            <p
              *ngIf="
                beneficiaryClauseForm.get('beneficiariesDivision.notaryAddress.address').errors &&
                beneficiaryClauseForm.get('beneficiariesDivision.notaryAddress.address').dirty
              "
              class="text-red1-xs text-12-xs openSans-bold margin-none-xs margin-top-sm-xs"
            >
              {{ 'subscription.mandates.form.inCaseOfDeath.notary.errors.lettersAndNumbersOnly' | translate }}
            </p>
          </div>

          <div class="col-xs-6 margin-top-sm-xs">
            <label for="postalCode" class="padding-none-xs text-left openSans-bold text-12-xs text-black-xs text-bold">
              {{ 'subscription.subscriber.form.postalCode' | translate }}
              <span class="text-red1-xs"> *</span>
              :
            </label>
            <input type="text" class="form-control input-lg text-14-xs" id="postalCode" formControlName="postalCode" />
            <p
              *ngIf="
                beneficiaryClauseForm.get('beneficiariesDivision.notaryAddress.postalCode').errors &&
                beneficiaryClauseForm.get('beneficiariesDivision.notaryAddress.postalCode').dirty
              "
              class="text-red1-xs text-12-xs openSans-bold margin-none-xs margin-top-sm-xs"
            >
              {{ 'subscription.mandates.form.inCaseOfDeath.notary.errors.numbersOnly' | translate }}
            </p>
          </div>

          <div class="col-xs-6 padding-left-none-xs margin-top-sm-xs">
            <label for="city" class="padding-none-xs text-left openSans-bold text-12-xs text-black-xs text-bold">
              {{ 'subscription.subscriber.form.city' | translate }}
              <span class="text-red1-xs"> *</span>
              :
            </label>
            <input
              type="text"
              (input)="formatControlValue('beneficiariesDivision.notaryAddress.city')"
              class="form-control input-lg text-14-xs col-xs-6"
              id="city"
              formControlName="city"
            />
            <p
              *ngIf="
                beneficiaryClauseForm.get('beneficiariesDivision.notaryAddress.city').errors &&
                beneficiaryClauseForm.get('beneficiariesDivision.notaryAddress.city').dirty
              "
              class="text-red1-xs text-12-xs openSans-bold margin-none-xs margin-top-sm-xs"
            >
              {{ 'subscription.mandates.form.inCaseOfDeath.notary.errors.lettersOnly' | translate }}
            </p>
          </div>
        </div>

        <div class="col-xs-6 margin-top-sm-xs">
          <label for="phone" class="padding-none-xs text-left openSans-bold text-12-xs text-black-xs text-bold">
            {{ 'subscription.subscriber.form.phone' | translate }}
            :
          </label>
          <input
            type="text"
            class="form-control input-lg text-14-xs col-xs-6"
            id="notaryPhone"
            formControlName="notaryPhone"
          />
        </div>
      </div>
    </div>

    <div class="padding-lg-xs col-xs-12 text-center">
      <button
        type="submit"
        class="btn btn-default dosis-bold padding-sm-xs text-uppercase text-white-xs background-red1-xs border-radius-none-xs padding-left-md-xs padding-right-md-xs"
        [disabled]="!beneficiaryClauseForm.valid || beneficiaryClauseForm.pristine || dataSent === ds.PENDING"
      >
        {{ 'home.contracts.clause.validate' | translate }}
      </button>
    </div>

    <div class="col-xs-12">
      <p class="openSans-bold margin-bottom-sm-xs text-center text-12-xs">
        <span *ngIf="dataSent === ds.PENDING" class="text-grey4-xs">{{
          'home.contracts.contribution.form.pending' | translate
        }}</span>
        <span *ngIf="dataSent === ds.ERROR" class="text-red1-xs">{{
          'home.contracts.contribution.form.error' | translate
        }}</span>
      </p>
    </div>
  </form>
</div>

<div class="row" *ngIf="dataSent === ds.SUCCESS">
  <div class="col-md-6 col-md-offset-3 margin-bottom-lg-xs margin-top-lg-xs text-center">
    <p>
      <i class="glyphicon glyphicon-ok text-green2-xs margin-lg-xs" style="font-size: 130px" aria-hidden="true"></i>
    </p>
    <p
      class="text-18-xs openSans-regular padding-bottom-lg-xs padding-left-lg-xs padding-right-lg-xs margin-bottom-lg-xs"
    >
      {{ 'home.contracts.clause.form.success' | translate }}
    </p>
    <a
      [routerLink]="['../../../']"
      class="btn btn-default dosis-bold padding-sm-xs text-uppercase text-white-xs background-blue3-xs border-radius-none-xs padding-left-md-xs padding-right-md-xs"
      >{{ 'home.contracts.contribution.back' | translate }}</a
    >
  </div>
</div>

<div class="row" *ngIf="dataSent === ds.FORBIDDEN">
  <div class="col-md-6 col-md-offset-3 margin-bottom-lg-xs margin-top-lg-xs text-center">
    <p>
      <i class="glyphicon glyphicon-remove text-red2-xs margin-lg-xs" style="font-size: 130px" aria-hidden="true"></i>
    </p>
    <p
      class="text-18-xs openSans-regular padding-bottom-lg-xs padding-left-lg-xs padding-right-lg-xs margin-bottom-lg-xs"
    >
      {{ 'home.contracts.clause.form.errorDuplicate' | translate }}
    </p>
    <a
      [routerLink]="['../../../']"
      class="btn btn-default dosis-bold padding-sm-xs text-uppercase text-white-xs background-blue3-xs border-radius-none-xs padding-left-md-xs padding-right-md-xs"
      >{{ 'home.contracts.contribution.back' | translate }}</a
    >
  </div>
</div>
