import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'n9-data-summary',
  templateUrl: './data-summary.component.html'
})
export class DataSummaryComponent implements OnInit {
  @Input() data: object;
  @Input() i18n: string;

  keys: string[];
  values: string[];

  constructor() {}

  public ngOnInit(): void {
    this.keys = Object.keys(this.data);
    this.values = Object.values(this.data);
  }

  private typeof(input: any): string {
    return typeof input;
  }
}
