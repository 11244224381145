import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageableResult, User } from '@core/models';
import { SUBJECT, THREAD_STATUS } from '@core/models/request.model';
import { ROLE } from '@core/models/role.model';
import { SessionService } from '@core/session/session.service';
import { SORT_ORDER } from '@shared/sort-arrows/sort-arrows.component';
import { combineLatest } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { Destroyed } from '../../../../../core/services/destroyed.service';
import { FILTER_TYPE, MessageThreadsService, SortStatus } from '../../message-threads.service';
import { Message } from '../../message.models';
import { ThreadMessage } from '../../thread-message.models';

const MAX_CHAR: number = 40;

@Component({
  selector: 'n9-message-list',
  templateUrl: 'message-list.component.html',
  styleUrls: ['message-list.component.scss']
})
export class MessageListComponent extends Destroyed implements OnInit, OnDestroy {
  public threads: ThreadMessage[];
  public managers: Map<number, string> = new Map();
  public managersUserObjects: User[];
  public lastMessages: object[] = [];
  public threadStatus: THREAD_STATUS[] = [];
  public cantSet: boolean = false;
  public isAdmin: boolean = false;
  public isManager: boolean = false;
  public userRequestStatus: typeof THREAD_STATUS;
  public subjects: typeof SUBJECT;
  public statusArray: string[];
  public subjectArray: string[];
  public size: number;
  public nbElements: number;
  public keys: any;
  public usedManager: User;
  public availableStatusChange: THREAD_STATUS[];
  public page: number;
  public FILTER_TYPE: typeof FILTER_TYPE = FILTER_TYPE;

  constructor(
    public readonly messageThreadsService: MessageThreadsService,
    private readonly sessionService: SessionService
  ) {
    super();
    this.userRequestStatus = THREAD_STATUS;
    this.subjects = SUBJECT;
    this.statusArray = Object.keys(THREAD_STATUS);
    this.subjectArray = Object.keys(SUBJECT);
    this.cantSet = sessionService.getUser().roles.includes(ROLE.ROLE_USER);
    this.isAdmin = this.sessionService.getUser().roles.includes(ROLE.ROLE_ADMIN);
    this.isManager =
      this.sessionService.getUser().roles.includes(ROLE.ROLE_ADMIN) ||
      this.sessionService.getUser().roles.includes(ROLE.ROLE_MANAGER);
    this.size = 10;
    this.keys = Object.keys;
    this.availableStatusChange = [THREAD_STATUS.DONE, THREAD_STATUS.REJECTED, THREAD_STATUS.SENT];

    if (this.isManager) {
      this.messageThreadsService.getAllManagers().subscribe((gestionnaires: User[]) => {
        this.managersUserObjects = gestionnaires;
        this.managersUserObjects.push({
          address: '',
          birthDate: undefined,
          city: '',
          complementaryAddress: '',
          country: '',
          createdBy: '',
          createdDateTime: undefined,
          email: '',
          lastModifiedDateTime: undefined,
          mobilePhoneNumber: {
            prefix: '+33',
            number: ''
          },
          password: '',
          postalCode: '',
          updatedBy: '',
          phoneNumber: {
            prefix: '',
            number: ''
          },
          marriedName: '',
          title: '',
          gestionnaire: null,
          roles: null,
          membershipDocumentId: '',
          mandates: null,
          contracts: null,
          eluNumber: '',
          id: '',
          firstname: 'TOUT',
          lastname: '',
          idGest: '-1'
        });
        gestionnaires.map((gestionnaire) => {
          this.managers.set(
            Number.parseInt(gestionnaire.idGest, 10),
            gestionnaire.firstname + ' ' + gestionnaire.lastname
          );
        });
      });
    }
  }

  ngOnInit(): void {
    this.page = this.sessionService.getThreadMessagePage() ? this.sessionService.getThreadMessagePage() : 1;
    this.usedManager = this.sessionService.getCurrentlyUsedManager();
    this.getThreadList(this.page);

    combineLatest([this.messageThreadsService.selectedSubject$, this.messageThreadsService.selectedStatus$])
      .pipe(
        takeUntil(this.destroyed$),
        switchMap(() =>
          combineLatest([
            this.messageThreadsService.getMessagesList(this.page - 1, this.size),
            this.messageThreadsService.sortStatus$
          ])
        )
      )
      .subscribe(([{ nbElements, content }, sortStatus]: [PageableResult<ThreadMessage>, SortStatus[]]) => {
        this.nbElements = nbElements;
        this.threads = content;
        this.sort(sortStatus);
      });

    this.messageThreadsService.sortStatus$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((sortStatus: SortStatus[]) => this.sort(sortStatus));
  }

  ngOnDestroy(): void {
    this.sessionService.setThreadMessagePage(this.page);
  }

  public getThreadList(nextPage: number): void {
    this.messageThreadsService
      .getMessagesList(nextPage - 1, this.size)
      .pipe(
        take(1),
        map(({ nbElements, content }: PageableResult<ThreadMessage>) => {
          this.nbElements = nbElements;
          this.threads = content;
          this.formatThreadToShow(this.threads, nextPage);
        })
      )
      .subscribe();
  }

  public formatThreadToShow(threads: any, nextPage?: number): void {
    if (threads) {
      this.threadStatus = [];
      this.lastMessages = [];
      threads.map((thread) => {
        if (thread.discussions) {
          const lastMessage: Message = thread.discussions[thread.discussions.length - 1];
          if (lastMessage) {
            if (lastMessage.content.length > MAX_CHAR)
              lastMessage.content = lastMessage.content.slice(0, MAX_CHAR - 1) + '...';

            lastMessage.createdDateTime = new Date(lastMessage.createdDateTime);
            lastMessage.lastModifiedDateTime = new Date(lastMessage.lastModifiedDateTime);

            this.lastMessages.push({
              eluNumber: thread['eluNumber'],
              message: lastMessage
            });
            this.threadStatus.push(thread.state);
          }
        }
      });
      if (nextPage) {
        setTimeout(() => (this.page = nextPage));
      }
    } else {
      this.nbElements = 0;
    }
  }

  public managerName(idGest: number): string {
    return this.managers.get(idGest);
  }

  public editRequestStatus(status: THREAD_STATUS, threadId: string, index: number): void {
    if (this.cantSet) return;
    this.messageThreadsService.changeThreadStatus(threadId, { state: status }).subscribe((response) => {
      this.threadStatus[index] = response.state;
    });
  }

  public availableStatus(): THREAD_STATUS[] {
    if (this.cantSet) return null;

    return this.availableStatusChange;
  }

  public getSortOrderByFilterType(sortStatus: SortStatus[], filterType: FILTER_TYPE): SORT_ORDER {
    const sort = sortStatus.find(({ type }) => filterType === type);
    return sort ? sort.order : SORT_ORDER.NONE;
  }

  private sort(sortStatus: SortStatus[]): void {
    sortStatus.forEach(({ type, order }: SortStatus) => {
      if (this.threads && order !== SORT_ORDER.NONE) {
        this.threads.sort((a, b) => {
          if (order === SORT_ORDER.DESC) {
            [a, b] = [b, a];
          }

          if (!isNaN(Number(a[type]))) {
            return Number(a[type]) - Number(b[type]);
          }

          return a[type].toString().localeCompare(b[type].toString());
        });
      }
    });

    this.formatThreadToShow(this.threads);
  }
}
