<div class="container-fluid">
  <div class="margin-bottom-md-xs">
    <p class="dosis-bold text-24-xs text-blue3-xs margin-none-xs">
      {{ 'admin.history.title' | translate }}
      <span class="dosis-semibold text-italic text-18-xs">
        ({{ manager.firstname }} {{ manager.lastname }})
      </span>
    </p>
  </div>

  <div class="col-xs-12 inline-dropdown margin-bottom-md-xs padding-none-xs">
    <n9-carel-dropdown
      [options]="keys(userRequestTypes)"
      [title]="'byType'"
      [i18nTitle]="'admin.history.filter'"
      [i18nOptions]="'admin.history.table.historyTypes'"
      (selectedOption)="
        selectedType = $event; this.getUsersRequestsFromManager($event)
      "
    >
    </n9-carel-dropdown>

    <n9-carel-dropdown
      [options]="keys(userRequestStatus)"
      [title]="'byStatus'"
      [i18nTitle]="'admin.history.filter'"
      [i18nOptions]="'admin.history.table.statusTypes'"
      (selectedOption)="
        selectedStatus = $event; this.getUsersRequestsFromManager($event)
      "
    >
    </n9-carel-dropdown>
  </div>

  <div *ngIf="userRequests && userRequests.length > 0; else noRequest">
    <table class="col-xs-12 border-grey3-xs-xs">
      <tbody class="openSans-regular">
        <tr class="dosis-bold text-white-xs background-blue3-xs">
          <td>{{ 'admin.history.table.eluNumber' | translate }}</td>
          <td>{{ 'admin.history.table.type' | translate }}</td>
          <td>{{ 'admin.history.table.status' | translate }}</td>
          <td>{{ 'admin.history.table.newValue' | translate }}</td>
          <td>{{ 'admin.history.table.updatedBy' | translate }}</td>
          <td>
            {{ 'admin.history.table.lastModifiedDateTime' | translate }}
          </td>
          <td>
            {{ 'admin.history.table.signatureStatus' | translate }}
          </td>
          <td>
            {{ 'admin.history.table.documentDownload' | translate }}
          </td>
        </tr>

        <tr
          *ngFor="let elem of userRequests; let iElem = index"
          class="background-white-xs openSans-regular"
          [ngClass]="
            (this.selectedType === userRequestTypes.ALL || elem.type === this.selectedType) &&
            (this.selectedStatus === userRequestStatus.ALL || elem.state === this.selectedStatus)
              ? ''
              : 'hide'
          "
        >
          <td>{{ elem.eluNumber }}</td>
          <td>
            {{ 'admin.history.table.historyTypes.' + elem.type | translate }}
          </td>
          <td>
            <n9-carel-dropdown
              [options]="this.availableStatusChange"
              [title]="elem.state"
              [i18nTitle]="'admin.history.table.statusTypes'"
              [i18nOptions]="'admin.history.table.statusTypes'"
              (selectedOption)="editRequestStatus($event, elem.id, iElem)"
            >
            </n9-carel-dropdown>
          </td>
          <td>
            <a *ngIf="elem.newValue" (click)="openModal(template)" class="cursor-hand"
              >{{ 'admin.history.table.details' | translate }}
            </a>
          </td>

          <ng-template #template>
            <div class="modal-header">
              <h4 class="modal-title pull-left dosis-bold text-blue3-xs">
                {{ 'admin.history.table.modal.titles.' + elem.type | translate }}
                {{ elem.eluNumber }}
              </h4>
              <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" [ngSwitch]="elem.type">
              <n9-mandate-infos
                *ngSwitchCase="userRequestTypes.NEW_MANDATE"
                [mandates$]="toBehaviorSubject(elem.newValue['mandates'])"
                class="fit-modal"
              >
              </n9-mandate-infos>

              <div *ngSwitchCase="userRequestTypes.USER_MODIFY" class="openSans-bold col-xs-12">
                <div class="col-xs-6">
                  <span class="dosis-bold text-18-xs text-blue3-xs">
                    {{ 'admin.history.table.modal.oldValue' | translate }}
                  </span>
                  <n9-data-summary
                    [data]="elem.oldValue"
                    [i18n]="'home.personalData.display'"
                    class="fit-modal margin-top-sm-xs"
                  >
                  </n9-data-summary>
                </div>

                <div class="col-xs-6">
                  <span class="dosis-bold text-18-xs text-blue3-xs">
                    {{ 'admin.history.table.modal.newValue' | translate }}
                  </span>
                  <n9-data-summary
                    [data]="elem.newValue"
                    [i18n]="'home.personalData.display'"
                    class="fit-modal margin-top-sm-xs"
                  >
                  </n9-data-summary>
                </div>
              </div>

              <div *ngSwitchCase="userRequestTypes.CONTRACT_CONTRIBUTION_RATE" class="openSans-bold col-xs-12">
                <div class="col-xs-12 margin-bottom-md-xs">
                  {{ 'admin.history.table.modal.contract' | translate }}
                  :
                  <span class="openSans-regular">{{ elem.token }}</span>
                </div>
                <div class="col-xs-6">
                  <span class="dosis-bold text-18-xs text-blue3-xs">
                    {{ 'admin.history.table.modal.oldValue' | translate }}
                  </span>
                  <n9-data-summary
                    [data]="elem.oldValue"
                    [i18n]="'home.contracts.contribution'"
                    class="fit-modal margin-top-sm-xs"
                  >
                  </n9-data-summary>
                </div>

                <div class="col-xs-6">
                  <span class="dosis-bold text-18-xs text-blue3-xs">
                    {{ 'admin.history.table.modal.newValue' | translate }}
                  </span>
                  <n9-data-summary
                    [data]="elem.newValue"
                    [i18n]="'home.contracts.contribution'"
                    class="fit-modal margin-top-sm-xs"
                  >
                  </n9-data-summary>
                </div>
              </div>

              <div *ngSwitchCase="userRequestTypes.CONTRACT_BENEFICIARY_CLAUSE" class="openSans-bold col-xs-12">
                <div class="col-xs-12 margin-bottom-md-xs">
                  {{ 'admin.history.table.modal.contract' | translate }}
                  :
                  <span class="openSans-regular">{{ elem.token }}</span>
                </div>
                <div class="col-xs-6">
                  <span class="dosis-bold text-18-xs text-blue3-xs">
                    {{ 'admin.history.table.modal.oldValue' | translate }}
                  </span>
                  <p class="margin-top-xs-xs openSans-semibold">
                    {{ elem.oldValue.inCaseOfDeath }}
                  </p>
                </div>

                <div class="col-xs-6 openSans-semibold">
                  <p class="dosis-bold text-18-xs text-blue3-xs">
                    {{ 'admin.history.table.modal.newValue' | translate }}
                  </p>
                  <n9-beneficiary-clause [mandate]="elem.newValue"></n9-beneficiary-clause>
                </div>
              </div>

              <div *ngSwitchCase="userRequestTypes.CONTRACT_RETROACTIVITY_DATE" class="openSans-bold col-xs-12">
                <div class="col-xs-12 margin-bottom-md-xs">
                  {{ 'admin.history.table.modal.contract' | translate }}
                  :
                  <span class="openSans-regular">{{ elem.token }}</span>
                </div>
                <div class="col-xs-6">
                  <span class="dosis-bold text-18-xs text-blue3-xs">
                    {{ 'admin.history.table.modal.oldValue' | translate }}
                  </span>
                  <n9-data-summary
                    [data]="elem.oldValue"
                    [i18n]="'home.contracts.retroactivity'"
                    class="fit-modal margin-top-sm-xs"
                  >
                  </n9-data-summary>
                </div>

                <div class="col-xs-6">
                  <span class="dosis-bold text-18-xs text-blue3-xs">
                    {{ 'admin.history.table.modal.newValue' | translate }}
                  </span>
                  <n9-data-summary
                    [data]="elem.newValue"
                    [i18n]="'home.contracts.retroactivity'"
                    class="fit-modal margin-top-sm-xs"
                  >
                  </n9-data-summary>
                </div>
              </div>
            </div>
          </ng-template>

          <td>{{ elem.updatedBy }}</td>
          <td>
            {{ elem.lastModifiedDateTime | date: 'dd MMMM yyyy à HH:mm' }}
          </td>
          <td>{{ elem.status ? ('admin.membership.list.status.' + elem.status | translate) : '' }}</td>
          <td>
            <span
              *ngIf="
                elem.status === this.status.MEMBERSHIP_COMPLETE || elem.status === this.status.SIGNATURE_COMPLETE;
                else noDoc
              "
              class="cursor-hand"
              (click)="downloadDocummentWithId(elem.signatureInformations.damId)"
            >
              <i class="fa fa-download fa-2x" aria-hidden="true"></i
            ></span>
            <ng-template #noDoc>
              <span>...</span>
            </ng-template>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="col-xs-12 text-center">
      <pagination
        [boundaryLinks]="false"
        [directionLinks]="true"
        [itemsPerPage]="size"
        [totalItems]="nbElements"
        [(ngModel)]="page"
        previousText="&lsaquo;"
        nextText="&rsaquo;"
        (pageChanged)="getUsersRequestsFromManager(selectedManager$.value.idGest, $event)"
      >
      </pagination>
    </div>
  </div>

  <ng-template #noRequest>
    <div class="openSans-semibold text-italic text-center">
      <p>Il n'y a pas de requête utilisateur disponible pour ce gestionnaire.</p>
    </div>
  </ng-template>
</div>
