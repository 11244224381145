import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

@Component({
  selector: 'n9-pagination',
  templateUrl: 'pagination.component.html'
})
export class PaginationComponent implements OnInit {
  @Input() public maxPages: number;
  @Output() public nextPage: EventEmitter<number>;
  public currentPage: number;
  public pages: number[];

  constructor() {
    this.currentPage = 1;
    this.nextPage = new EventEmitter();
    this.pages = [];
  }

  public ngOnInit(): void {
    for (let i: number = 1; i <= this.maxPages; i++) this.pages.push(i);
  }

  public clickOnPage(page: number): void {
    this.currentPage = page;
    this.nextPage.emit(this.currentPage);
  }

  public clickNext(): void {
    if (this.currentPage + 1 <= this.maxPages) {
      this.currentPage++;
      this.nextPage.emit(this.currentPage);
    }
  }

  public clickPrevious(): void {
    if (this.currentPage - 1 > 0) {
      this.currentPage--;
      this.nextPage.emit(this.currentPage);
    }
  }
}
