import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { User, UserDTO } from '@core/models';
import { DataStatus } from '@core/models/data-status';
import { AlphabeticValidatorDirective } from '@core/validators/alphabetic-validator.directive';
import { AlphanumericValidatorDirective } from '@core/validators/alphanumeric-validator.directive';
import { BirthDateValidatorDirective } from '@core/validators/birth-date-validator.directive';
import { EmailValidatorDirective } from '@core/validators/email-validator.directive';
import { MobilePhoneValidatorDirective } from '@core/validators/mobile-phone-validator.directive';
import { PhoneValidatorDirective } from '@core/validators/phone-validator.directive';
import { RequestService } from '@shared/services/request.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'n9-personal-data-form',
  templateUrl: 'personal-data-form.component.html'
})
export class PersonalDataFormComponent implements OnInit {
  @Input() user: User;
  @Output() doneEditing: EventEmitter<DataStatus> = new EventEmitter<DataStatus>();

  userEditForm: FormGroup;
  dataSent: DataStatus;
  ds: typeof DataStatus = DataStatus;

  constructor(private formBuilder: FormBuilder, private requestService: RequestService, private cd: ChangeDetectorRef) {
    this.userEditForm = this.formBuilder.group({
      title: [null],
      lastname: [null, [Validators.required, AlphabeticValidatorDirective.validInput]],
      marriedName: [null, [AlphabeticValidatorDirective.validInput]],
      firstname: [null, [Validators.required, AlphabeticValidatorDirective.validInput]],
      birthDate: [null, [Validators.required, BirthDateValidatorDirective.validDate]],
      phoneNumber: this.formBuilder.group({
        prefix: [null, []],
        number: [null, [PhoneValidatorDirective.validInput]]
      }),
      mobilePhoneNumber: this.formBuilder.group({
        prefix: [null, [Validators.required]],
        number: [null, [Validators.required, MobilePhoneValidatorDirective.validInput]]
      }),
      email: [null, [Validators.required, EmailValidatorDirective.validEmail]],
      address: [null, [Validators.required, AlphanumericValidatorDirective.validInput]],
      complementaryAddress: [null, [AlphanumericValidatorDirective.validInput]],
      postalCode: [null, [Validators.required, AlphanumericValidatorDirective.validInput]],
      city: [null, [Validators.required, AlphabeticValidatorDirective.validInput]],
      country: [null, [Validators.required, AlphabeticValidatorDirective.validInput]]
    });
  }

  ngOnInit(): void {
    this.userEditForm.patchValue(this.user);
    this.dataSent = DataStatus.WAITING;
  }

  public onSubmit(event: any): void {
    if (this.userEditForm.valid) {
      const data: User = Object.assign({ eluNumber: this.user.eluNumber }, this.userEditForm.getRawValue());
      const dataDTO: UserDTO = this.userEditForm.getRawValue() as UserDTO;

      if (data.phoneNumber.prefix && data.phoneNumber.number) {
        dataDTO.phoneNumber = data.phoneNumber.prefix.concat(data.phoneNumber.number);
      }

      dataDTO.mobilePhoneNumber = data.mobilePhoneNumber.prefix.concat(data.mobilePhoneNumber.number);
      dataDTO.eluNumber = this.user.eluNumber; // eluNumber must be set for API to authorize that current data matches the authenticated user

      this.dataSent = DataStatus.PENDING;
      this.doneEditing.emit(this.dataSent);

      this.requestService
        .personalDataRequest(dataDTO)
        .take(1)
        .subscribe(
          () => {
            this.dataSent = DataStatus.SUCCESS;
            this.doneEditing.emit(this.dataSent);
            this.dataSent = DataStatus.WAITING;
            this.userEditForm.markAsPristine();
          },
          (err) => {
            if (err.status === 409) this.dataSent = DataStatus.FORBIDDEN;
            else this.dataSent = DataStatus.ERROR;
            this.doneEditing.emit(this.dataSent);
            this.dataSent = DataStatus.WAITING;
            Observable.throwError(err);
          }
        );
    }
  }

  get userControls(): { [formControlName: string]: AbstractControl } {
    return {
      lastname: this.userEditForm.get('lastname'),
      marriedName: this.userEditForm.get('marriedName'),
      firstname: this.userEditForm.get('firstname'),
      birthDate: this.userEditForm.get('birthDate'),
      mobilePhoneNumber: this.userEditForm.get('mobilePhoneNumber'),
      phoneNumber: this.userEditForm.get('phoneNumber'),
      email: this.userEditForm.get('email'),
      address: this.userEditForm.get('address'),
      complementaryAddress: this.userEditForm.get('complementaryAddress'),
      postalCode: this.userEditForm.get('postalCode'),
      city: this.userEditForm.get('city'),
      country: this.userEditForm.get('country')
    };
  }
}
