<div class="col-xs-12 padding-none-xs margin-top-md-xs padding-bottom-lg-xs">
  <div
    class="col-xs-12 col-sm-10 col-sm-offset-1 padding-left-xs-xs padding-right-xs-xs padding-none-sm margin-bottom-md-xs"
  >
    <div class="col-xs-12 text-center openSans-bold text-12-xs text-14-md text-black-xs text-bold">
      {{ 'login.reset.create.introduction' | translate }}
    </div>
    <div
      class="col-xs-12 padding-left-md-xs padding-right-md-xs padding-none-sm openSans-semibold text-center text-grey4-xs text-9-xs text-bold"
    >
      {{ 'login.reset.create.constraints' | translate }}
    </div>
  </div>
  <form
    novalidate
    [formGroup]="passwordResetForm"
    (ngSubmit)="onSubmit()"
    class="form form-horizontal col-xs-10 col-xs-offset-1 col-sm-6 col-sm-offset-3 col-lg-4 col-lg-offset-4 padding-none-xs padding-left-sm-sm padding-right-sm-sm padding-bottom-lg-xs"
  >
    <div class="form-group">
      <div class="col-xs-12">
        <label
          for="new-password"
          class="col-xs-12 padding-none-xs text-left openSans-bold text-10-xs text-black-xs text-bold"
        >
          {{ 'login.reset.create.labels.newPassword' | translate }}
        </label>
        <input type="password" id="new-password" class="form-control" formControlName="newPassword" />
        <div
          *ngIf="passwordResetForm.controls.newPassword.errors && passwordResetForm.controls.newPassword.dirty"
          class="alert"
        >
          <div
            [hidden]="!passwordResetForm.controls.newPassword.errors.required"
            class="col-xs-12 padding-none-xs text-left text-10-xs text-red1-xs"
          >
            {{ 'login.commons.form.errors.required' | translate }}
          </div>
          <div
            [hidden]="!passwordResetForm.controls.newPassword.errors.n9Pass"
            class="col-xs-12 padding-none-xs text-left text-10-xs text-red1-xs"
          >
            {{ 'login.reset.create.errors.password' | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="col-xs-12">
        <label
          for="confirm-password"
          class="col-xs-12 padding-none-xs text-left openSans-semibold text-10-xs text-black-xs text-bold"
        >
          {{ 'login.reset.create.labels.confirmPassword' | translate }}
        </label>
        <input type="password" id="confirm-password" class="form-control" formControlName="confirmPassword" />
        <div
          *ngIf="passwordResetForm.controls.confirmPassword.errors && passwordResetForm.controls.confirmPassword.dirty"
          class="alert"
        >
          <div
            [hidden]="!passwordResetForm.controls.confirmPassword.errors.required"
            class="col-xs-12 padding-none-xs text-left text-10-xs text-red1-xs"
          >
            {{ 'login.commons.form.errors.required' | translate }}
          </div>
          <div
            [hidden]="!passwordResetForm.controls.confirmPassword.errors.pass"
            class="col-xs-12 padding-none-xs text-left text-10-xs text-red1-xs"
          >
            {{ 'login.reset.create.errors.confirm' | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="form-group margin-top-lg-xs">
      <button
        type="submit"
        [disabled]="!passwordResetForm.valid"
        [ngClass]="{ 'button-disabled': !passwordResetForm.valid }"
        class="btn btn-default dosis-bold col-xs-6 col-xs-offset-3 padding-left-md-xs padding-right-md-xs padding-top-sm-xs padding-bottom-sm-xs background-red1-xs text-10-xs text-uppercase text-white-xs border-radius-none-xs"
      >
        {{ 'login.reset.create.submit' | translate }}
      </button>
    </div>
  </form>
</div>
