<div class="col-xs-12 padding-left-md-sm padding-right-md-sm">
  <div class="row">
    <p class="col-xs-8 dosis-bold text-18-sm text-16-xs text-uppercase text-blue3-xs margin-top-50-xs">
      {{ 'subscription.subscriber.title' | translate }}
    </p>

    <p class="col-xs-4 openSans-semibold text-14-sm text-11-xs text-right padding-none-xs">
      <span class="text-red3-xs">*</span>
      {{ 'subscription.common.mandatory' | translate }}
    </p>
  </div>

  <form
    novalidate
    [formGroup]="this.subscriberForm"
    (ngSubmit)="onSubmit()"
    class="form form-horizontal col-xs-12 padding-none-xs padding-left-sm-sm padding-right-sm-sm padding-bottom-lg-xs margin-top-sm-sm margin-top-none-xs"
  >
      <div class="row">
        <div class="col-sm-4 col-xs-12">
          <label class="padding-none-xs text-left openSans-bold text-bold text-black-xs">
            {{ 'subscription.subscriber.form.civility.label' | translate }}
            <span class="text-red1-xs"> *</span>
            :
          </label>

          <div class="radio-group">
            <input type="radio" name="civility" id="miss" value="MRS" formControlName="civility" required />
            <label class="radio-label sticky col-xs-6 text-center border-blue3-xs-xs" for="miss">{{
              'subscription.subscriber.form.civility.MRS' | translate
            }}</label>

            <input type="radio" name="civility" id="mister" value="MR" formControlName="civility" required />
            <label class="radio-label sticky col-xs-6 text-center border-blue3-xs-xs" for="mister">{{
              'subscription.subscriber.form.civility.MR' | translate
            }}</label>
          </div>
          <p
            *ngIf="subscriberForm.controls.civility.errors && (subscriberForm.controls.civility.dirty || showErrors)"
            class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs"
          >
            {{ 'subscription.subscriber.form.errors.required' | translate }}
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 col-xs-12">
          <label for="lastname" class="padding-none-xs text-left openSans-bold text-bold text-black-xs">
            {{ 'subscription.subscriber.form.lastname' | translate }}
            <span class="text-red1-xs"> *</span>
            :
          </label>
          <input
            #lastname
            type="text"
            class="form-control input-lg text-14-xs"
            id="lastname"
            formControlName="lastname"
            (input)="
              formatControlValue('lastname');
              subscriberForm.get('civility').value === 'MR'
                ? subscriberForm.get('maidenname').setValue(lastname.value)
                : 0
            "
            maxlength="40"
            required
          />
          <p
            *ngIf="subscriberForm.controls.lastname.errors && (subscriberForm.controls.lastname.dirty || showErrors)"
            class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs"
          >
            {{ 'subscription.subscriber.form.errors.lettersOnly' | translate }}
          </p>
        </div>

        <div class="col-sm-6 col-xs-12">
          <label for="maidenname" class="padding-none-xs text-left openSans-bold text-black-xs text-bold">
            {{ 'subscription.subscriber.form.maidenname' | translate }}
            <span class="text-red1-xs"> *</span>
            :
          </label>
          <input
            type="text"
            class="form-control input-lg text-14-xs"
            id="maidenname"
            formControlName="maidenname"
            (input)="formatControlValue('maidenname')"
            maxlength="40"
            required
          />
          <p
            *ngIf="
              subscriberForm.controls.maidenname.errors && (subscriberForm.controls.maidenname.dirty || showErrors)
            "
            class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs"
          >
            {{ 'subscription.subscriber.form.errors.lettersOnly' | translate }}
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 col-xs-12">
          <label for="firstnames" class="padding-none-xs text-left openSans-bold text-black-xs text-bold">
            {{ 'subscription.subscriber.form.firstnames.label' | translate }}
            <span class="text-red1-xs"> *</span>
            :
            <div
              class="glyphicon glyphicon-info-sign text-blue3-xs"
              [tooltip]="tooltipFirstnames"
              placement="right"
            ></div>
            <ng-template #tooltipFirstnames>
              <div class="text-left openSans-regular text-10-xs margin-top-sm-xs margin-bottom-sm-xs">
                <p class="openSans-bold margin-none-xs">Pour ajouter plusieurs prénoms :</p>
                <p>Séparer les prénoms par des virgules (ex: André, Jean, Didier, ...)</p>

                <p class="openSans-bold margin-none-xs">Pour ajouter un prénom composé :</p>
                <p class="margin-none-xs">
                  Séparer les prénoms par un espace (ex: Marie Louise, Anne Marie, Françoise, ...)
                </p>
              </div>
            </ng-template>
          </label>
          <input
            type="text"
            class="form-control input-lg text-14-xs"
            id="firstnames"
            formControlName="firstnames"
            placeholder="{{ 'subscription.subscriber.form.firstnames.placeholder' | translate }}"
            (input)="formatControlValue('firstnames')"
            maxlength="40"
            required
          />
          <p
            *ngIf="
              subscriberForm.controls.firstnames.errors && (subscriberForm.controls.firstnames.dirty || showErrors)
            "
            class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs"
          >
            {{ 'subscription.subscriber.form.errors.firstnames' | translate }}
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 col-xs-12">
          <label
            for="birthDate"
            class="col-xs-12 margin-top-none-xs padding-none-xs text-left openSans-bold text-black-xs text-bold"
          >
            {{ 'subscription.subscriber.form.birthDate' | translate }}
            <span class="text-red1-xs"> *</span>
            :
          </label>

          <n9-form-datepicker
            [parentForm]="subscriberForm"
            [controlName]="'birthDate'"
            [minDate]="minDate"
            [maxDate]="maxDate"
          >
          </n9-form-datepicker>

          <p
            *ngIf="subscriberForm.controls.birthDate.errors && (subscriberForm.controls.birthDate.dirty || showErrors)"
            class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs"
          >
            {{ 'subscription.subscriber.form.errors.dateFormat' | translate }}
            {{ maxDate | date: 'dd/MM/yyyy' }}
          </p>
        </div>

        <div class="col-sm-6 col-xs-12">
          <label for="cityOfBirth" class="padding-none-xs text-left openSans-bold text-black-xs text-bold">
            {{ 'subscription.subscriber.form.cityOfBirth' | translate }}
            <span class="text-red1-xs"> *</span>
            :
          </label>
          <input
            #cityOfBirth
            type="text"
            class="form-control col-xs-6 input-lg text-14-xs"
            id="cityOfBirth"
            formControlName="cityOfBirth"
            (input)="formatControlValue('cityOfBirth')"
            maxlength="40"
            required
          />

          <p
            *ngIf="
              subscriberForm.controls.cityOfBirth.errors && (subscriberForm.controls.cityOfBirth.dirty || showErrors)
            "
            class="text-red1-xs openSans-bold margin-bottom-none-xs margin-top-sm-xs"
          >
            {{ 'subscription.subscriber.form.errors.lettersOnly' | translate }}
          </p>
        </div>
      </div>

      <div formGroupName="address" class="border-none-xs">
        <div class="row">
          <div class="col-sm-6 col-xs-12">
            <label for="address" class="padding-none-xs text-left openSans-bold text-black-xs text-bold">
              {{ 'subscription.subscriber.form.address' | translate }}
              <span class="text-red1-xs"> *</span>
              :
            </label>
            <input
              #address
              type="text"
              class="form-control input-lg text-14-xs"
              id="address"
              formControlName="address"
              (input)="formatControlValue('address.address')"
              maxlength="40"
              required
            />
            <p
              *ngIf="
                subscriberForm.get('address')['controls'].address.errors &&
                (subscriberForm.get('address')['controls'].address.dirty || showErrors)
              "
              class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs"
            >
              {{ 'subscription.subscriber.form.errors.lettersAndNumbersOnly' | translate }}
            </p>
          </div>

          <div class="col-sm-6 col-xs-12">
            <label for="complement" class="padding-none-xs text-left openSans-bold text-black-xs text-bold">
              {{ 'subscription.subscriber.form.complement' | translate }}
              :
            </label>
            <input
              type="text"
              class="form-control input-lg text-14-xs"
              id="complement"
              formControlName="complement"
              (input)="formatControlValue('address.complement')"
              maxlength="40"
            />
            <p
              *ngIf="
                subscriberForm.get('address')['controls'].complement.errors &&
                (subscriberForm.get('address')['controls'].complement.dirty || showErrors)
              "
              class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs"
            >
              {{ 'subscription.subscriber.form.errors.lettersAndNumbersOnly' | translate }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-3 col-xs-12">
            <label for="postalCode" class="padding-none-xs text-left openSans-bold text-black-xs text-bold">
              {{ 'subscription.subscriber.form.postalCode' | translate }}
              <span class="text-red1-xs"> *</span>
              :
            </label>
            <input
              type="text"
              class="form-control input-lg text-14-xs"
              id="postalCode"
              formControlName="postalCode"
              maxlength="5"
              required
            />
            <p
              *ngIf="
                subscriberForm.get('address')['controls'].postalCode.errors &&
                (subscriberForm.get('address')['controls'].postalCode.dirty || showErrors)
              "
              class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs"
            >
              {{ 'subscription.subscriber.form.errors.numbersOnly' | translate }}
            </p>
          </div>

          <div class="col-sm-6 col-sm-offset-3 col-xs-12">
            <label for="city" class="padding-none-xs text-left openSans-bold text-black-xs text-bold">
              {{ 'subscription.subscriber.form.city' | translate }}
              <span class="text-red1-xs"> *</span>
              :
            </label>
            <input
              type="text"
              class="form-control col-xs-6 input-lg text-14-xs"
              id="city"
              formControlName="city"
              (input)="formatControlValue('address.city')"
              maxlength="40"
              required
            />
            <p
              *ngIf="
                subscriberForm.get('address')['controls'].city.errors &&
                (subscriberForm.get('address')['controls'].city.dirty || showErrors)
              "
              class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs"
            >
              {{ 'subscription.subscriber.form.errors.lettersAndNumbersOnly' | translate }}
            </p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 col-xs-12">
          <label for="email" class="col-xs-12 padding-none-xs text-left openSans-bold text-black-xs text-bold">
            {{ 'subscription.subscriber.form.email' | translate }}
            <span class="text-red1-xs"> *</span>
            :
          </label>
          <input
            type="text"
            class="form-control input-lg text-14-xs"
            id="email"
            formControlName="email"
            maxlength="40"
            required
          />
          <p
            *ngIf="subscriberForm.controls.email.errors && (subscriberForm.controls.email.dirty || showErrors)"
            class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs"
          >
            {{ 'subscription.subscriber.form.errors.email' | translate }}
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 col-xs-12">
          <label for="mobilePhone" class="padding-none-xs text-left openSans-bold text-black-xs text-bold">
            {{ 'subscription.subscriber.form.mobilePhone' | translate }}
            <span class="text-red1-xs"> *</span>
            :
          </label>
          <n9-input-phone id="mobilePhone" formGroupName="mobilePhone"></n9-input-phone>
          <p
            *ngIf="
              subscriberForm.controls.mobilePhone['controls'].number.errors &&
              (subscriberForm.controls.mobilePhone['controls'].number.dirty || showErrors)
            "
            class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs"
          >
            {{ 'subscription.subscriber.form.errors.mobilePhone' | translate }}
          </p>
        </div>

        <div class="col-sm-6 col-xs-12">
          <label for="phone" class="padding-none-xs text-left openSans-bold text-black-xs text-bold">
            {{ 'subscription.subscriber.form.phone' | translate }}
            :
          </label>
          <n9-input-phone id="phone" formGroupName="phone"></n9-input-phone>
          <p
            *ngIf="
              subscriberForm.controls.phone['controls'].number.errors &&
              (subscriberForm.controls.phone['controls'].number.dirty || showErrors)
            "
            class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs"
          >
            {{ 'subscription.subscriber.form.errors.phone' | translate }}
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 col-xs-12">
          <label for="maritalStatus" class="padding-none-xs text-left openSans-bold text-black-xs text-bold">
            {{ 'subscription.subscriber.form.maritalStatus.label' | translate }}
            :
          </label>

          <select class="form-control input-lg text-14-xs" id="maritalStatus" formControlName="maritalStatus">
            <option value="">
              {{ 'subscription.common.form.select' | translate }}
            </option>
            <option value="SINGLE">
              {{ 'subscription.subscriber.form.maritalStatus.single' | translate }}
            </option>
            <option value="DIVORCED">
              {{ 'subscription.subscriber.form.maritalStatus.divorced' | translate }}
            </option>
            <option value="MARIED">
              {{ 'subscription.subscriber.form.maritalStatus.married' | translate }}
            </option>
            <option value="PACSED">
              {{ 'subscription.subscriber.form.maritalStatus.pacsed' | translate }}
            </option>
            <option value="WIDOWER">
              {{ 'subscription.subscriber.form.maritalStatus.widower' | translate }}
            </option>
          </select>
        </div>

        <div class="col-sm-6 col-xs-12">
          <label for="job" class="padding-none-xs text-left openSans-bold text-black-xs text-bold">
            {{ 'subscription.subscriber.form.job' | translate }} :
          </label>
          <input
            type="text"
            class="form-control input-lg text-14-xs"
            id="job"
            formControlName="job"
            (input)="formatControlValue('job')"
            maxlength="40"
          />
          <p
            *ngIf="subscriberForm.controls.job.errors && (subscriberForm.controls.job.dirty || showErrors)"
            class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs"
          >
            {{ 'subscription.subscriber.form.errors.lettersOnly' | translate }}
          </p>
        </div>
      </div>

    <div class="text-right margin-top-md-xs">
      <button
        type="submit"
        style="width: 200px"
        class="btn btn-default dosis-bold text-16-xs padding-left-lg-xs padding-right-lg-xs padding-top-sm-xs padding-bottom-sm-xs background-red1-xs text-uppercase text-white-xs border-radius-none-xs"
      >
        {{ 'subscription.subscriber.form.validate' | translate }}
      </button>
      <p *ngIf="!subscriberForm.valid && showErrors" class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs">
        {{ 'subscription.subscriber.form.errors.global' | translate }}
      </p>
    </div>
  </form>
</div>
