import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdminComponent } from './admin.component';
import { AdminLoginComponent } from './login/admin-login.component';
import {MaintenanceGuard} from "@core/session/maintenance.guard";

const routes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [MaintenanceGuard],
	children: [
       {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login'
      },
      {
        path: 'login',
        component: AdminLoginComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutesModule {}
