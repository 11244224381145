<div class="col-xs-6 padding-none-xs">
  <span class="col-xs-12 openSans-bold"> Sélectionnez les adhérents d'un département : </span>

  <form class="btn-group col-xs-11" dropdown [insideClick]="true" [formGroup]="parentForm">
    <button
      id="form-control button-basic"
      dropdownToggle
      type="button"
      class="dropdown-toggle form-control text-left"
      aria-controls="dropdown-departments"
    >
      Sélectionner le(s) département(s) parmi la liste
      <span class="caret text-right"></span>
    </button>

    <ul id="dropdown-departments" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
      <li *ngFor="let option of options; let i = index" class="padding-xs-xs" [formArrayName]="controlName">
        <input
          [id]="'opt-' + option.code"
          [name]="'opt-' + option.code"
          type="checkbox"
          class="margin-left-sm-xs margin-right-sm-xs"
          [value]="option.code"
          (change)="onSelect($event)"
        />
        <label [for]="'opt-' + option.code" class="text-14-xs">
          {{ option.code + ' - ' + option.name }}
        </label>
      </li>
    </ul>
  </form>
</div>
