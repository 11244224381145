import { NgModule } from '@angular/core';
import { MaintenanceComponent } from './maintenance.component';
import { Routes, RouterModule } from '@angular/router';

const subscriptionRoutes: Routes = [
  {
    path: 'maintenance',
    component: MaintenanceComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(subscriptionRoutes)],
  exports: [RouterModule]
})
export class MaintenanceRoutingModule {}
