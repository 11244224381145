<ul class="pagination">
  <li (click)="clickPrevious()" class="cursor-hand">
    <a class="background-transparent border-none-xs">
      <i class="fa fa-angle-left text-30-xs" aria-hidden="true"></i>
    </a>
  </li>
  <li *ngFor="let page of pages" (click)="clickOnPage(page)" class="cursor-hand background-transparent border-none-xs">
    <a
      class="background-transparent border-none-xs margin-top-xs-xs text-14-xs text-blue3-xs"
      [ngClass]="{ 'pagination-selected': currentPage === page }"
    >
      {{ page }}
    </a>
  </li>
  <li (click)="clickNext()" class="cursor-hand">
    <a class="background-transparent border-none-xs">
      <i class="fa fa-angle-right text-30-xs" aria-hidden="true"></i>
    </a>
  </li>
</ul>
