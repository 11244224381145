<div class="input-group border-none-xs" [formGroup]="parentForm">
  <input
    class="form-control input-lg text-14-xs"
    id="{{ controlName }}"
    name="dp"
    [formControlName]="controlName"
    placeholder="{{ 'subscription.common.form.dateformat' | translate }}"
    bsDatepicker
    #datepicker="bsDatepicker"
    [minDate]="this.minDate"
    [maxDate]="this.maxDate"
    [bsConfig]="this.bsConfig"
    required
  />
  <div class="input-group-append">
    <button
      class="btn btn-default glyphicon glyphicon-calendar text-blue3-xs"
      (click)="datepicker.toggle()"
      type="button"
    ></button>
  </div>
</div>
