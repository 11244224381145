<div
  id="login-container"
  class="container-fluid background-grey2-xs padding-left-sm-xs padding-right-sm-xs padding-none-sm margin-top-50-xs"
>
  <div
    class="col-xs-12 margin-top-sm-xs margin-bottom-lg-xs dosis-bold text-uppercase text-blue3-xs text-24-md text-18-xs text-center text-bold"
  >
    {{ 'admin.login.title' | translate }}
  </div>
  <div id="login-panel" class="background-white-xs border-grey3-xs-xs">
    <form
      novalidate
      [formGroup]="loginForm"
      (ngSubmit)="onSubmit()"
      class="form form-horizontal col-xs-12 margin-top-md-xs margin-bottom-md-xs"
    >
      <div class="form-group">
        <label for="login" class="col-xs-12 padding-none-xs text-left openSans-bold text-12-xs text-bold text-black-xs">
          Login Carel :
        </label>
        <input class="form-control" id="login" type="login" formControlName="login" />
        <!--
					<div *ngIf="loginForm.controls.email.errors && loginForm.controls.email.dirty"
						 class="alert alert-danger">
						<div [hidden]="!loginForm.controls.email.errors.required">
							{{'login.form.errors.required' | translate}}
						</div>
						<div [hidden]="!loginForm.controls.email.errors.n9Email">
							{{'login.form.errors.email' | translate}}
						</div>
					</div>
					-->
      </div>

      <div class="form-group">
        <label
          for="password"
          class="col-xs-12 padding-none-xs text-left openSans-bold text-12-xs text-bold text-black-xs"
        >
          {{ 'login.auth.labels.password' | translate }}
        </label>
        <input
          class="form-control"
          id="password"
          type="password"
          formControlName="password"
          placeholder="{{ 'login.form.placeholders.password' | translate }}"
        />
      </div>
      <div
        *ngIf="errorMessage && errorMessage.length > 0"
        class="col-xs-12 margin-bottom-sm-xs text-center text-red1-xs text-16-xs"
      >
        {{ errorMessage }}
      </div>
      <button
        type="submit"
        class="btn btn-default dosis-bold col-xs-8 col-xs-offset-2 margin-top-sm-xs padding-left-md-xs padding-right-md-xs padding-top-sm-xs padding-bottom-sm-xs background-red1-xs text-uppercase text-white-xs text-12-xs border-radius-none-xs"
        [disabled]="!loginForm.valid"
        [ngClass]="{ 'button-disabled': !loginForm.valid }"
      >
        {{ 'login.commons.form.actions.submit' | translate }}
      </button>

      <p *ngIf="isRoleInsufficient">
        {{ 'admin.login.errors.role' | translate }}
      </p>
    </form>
  </div>
</div>
