import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { User, Contract } from '@core/models';
import { ThreadService } from '@shared/services/thread.service';
import { SessionService } from '@core/session/session.service';
import { MandateService } from '../../contracts/shared';
import moment from 'moment';
import { ThreadMessage } from '@shared/components/threads/thread-message.models';
import { threadId } from 'worker_threads';

@Component({
  selector: 'n9-contact-form',
  templateUrl: 'contact-form.component.html',
  styleUrls: ['contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
  public objectKeys: any = Object.keys;
  public user: User;
  public contactForm: FormGroup;
  public mandates: Contract[];
  public subjects: string[] = ['Mon contrat', 'Mes cotisations', "Demande d'ouverture des droits", 'Autres'];
  public isSubmitted: boolean;
  public MAX_CHAR: number = 3000;
  uploadedFilesIds: string[] = [];
  uploadedFilesLabels: string[] = [];
  uploadedFiles: File[] = [];
  images: string[] = [];

  private previousMessageState: string;

  constructor(
    private formBuilder: FormBuilder,
    private threadService: ThreadService,
    private router: Router,
    private sessionService: SessionService
  ) {
    this.user = this.sessionService.getUser();
    this.contactForm = this.formBuilder.group({
      mandateId: ['', [Validators.required]],
      subject: ['', Validators.required],
      content: ['', [Validators.required]],
      firstFile: ['', []]
    });

    this.isSubmitted = null;
    this.mandates = [];
    this.previousMessageState = '';
  }

  ngOnInit(): void {
    if (this.user.contracts) this.user.contracts.map((m) => this.mandates.push(m));
  }

  async onSubmit(event: any): Promise<any> {
    const subject: AbstractControl = this.contactForm.get('subject');

    subject.setValue('[' + subject.value + '] ' + this.contactForm.get('subject').value);

    if (this.contactForm.valid) {
      if (this.uploadedFiles.length > 0) await this.uploadFiles();

      const thread = this.contactForm.getRawValue();
      const threadMessage: ThreadMessage = new ThreadMessage({} as ThreadMessage);
      threadMessage.mandateId = thread['mandateId'];
      threadMessage.objectId = thread['subject'];
      threadMessage.discussions = [
        {
          content: thread['content'],
          attachedFileIds: this.uploadedFilesIds,
          attachedFileNames: this.uploadedFilesLabels
        }
      ];

      this.threadService.createThread(threadMessage).subscribe(
        () => {
          this.isSubmitted = true;
          this.resetForm();
        },
        (error) => this.handleError(error)
      );
    }
  }

  async uploadFiles(): Promise<any> {
    for (const file of this.uploadedFiles) {
      await this.uploadOneFile(file);
    }
  }

  async uploadOneFile(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      this.threadService.uploadImageFile(file).subscribe((res) => {
        this.uploadedFilesIds.push(res.id);
        this.uploadedFilesLabels.push(res.label);
        resolve(res);
      });
    });
  }

  public formatDate(date: string): string {
    return moment(date).format('DD/MM/YYYY');
  }

  public getFile(files: FileList): void {
    const newFile = Array.from(files).map((file: File, index: number) => {
      if (file.type.match('image/.+')) this.toBase64(file, index);

      return file;
    });
    newFile.forEach((file) => this.uploadedFiles.push(file));
  }

  public toBase64(file: File, index: number): void {
    const fileReader: FileReader = new FileReader();

    fileReader.onload = (e: any) => {
      this.images[index] = e.target.result;
    };
    fileReader.readAsDataURL(file);
  }

  public deleteFile(index: number): void {
    if (this.uploadedFiles && this.uploadedFiles[index]) {
      this.uploadedFiles = this.uploadedFiles.filter((file, i) => index !== i);
      this.images = this.images.filter((image, i) => i !== index);
    }
  }

  public resetForm(): void {
    this.contactForm.reset();
    this.uploadedFilesLabels = [];
    this.uploadedFilesIds = [];
    this.uploadedFiles = [];
    this.images = [];
  }

  private handleError(error: any): void {
    this.isSubmitted = false;

    if (error.status) {
      if (error.status === 401 || error.status === 403) {
        this.router.navigate(['/']);
        return;
      }
    }
  }
}
