<section class="col-xs-12 text-center padding-lg-xs">
  <div class="preamble-block text-center padding-top-md-xs padding-bottom-md-xs">
    <p class="text-35-sm text-22-xs openSans-semibold margin-bottom-lg-xs" style="max-width: 700px; margin: auto">
      {{ 'subscription.common.title' | translate }}
    </p>
    <p class="text-16-xs openSans-semibold margin-bottom-md-xs">
      {{ 'subscription.common.subtitle' | translate }}
    </p>
    <p class="text-16-xs openSans-regular margin-bottom-md-xs">
      {{ 'subscription.common.firstParagraph' | translate }}
    </p>
    <p class="text-16-xs openSans-regular margin-bottom-lg-xs">
      {{ 'subscription.common.secondParagraph' | translate }}
    </p>

    <div class="text-center">
      <button
        type="button"
        (click)="start()"
        class="btn btn-default dosis-bold text-16-xs margin-top-md-xs padding-left-lg-xs padding-right-lg-xs padding-top-sm-xs padding-bottom-sm-xs background-red1-xs text-uppercase text-white-xs border-radius-none-xs"
      >
        {{ 'subscription.common.start' | translate }}
      </button>
    </div>
  </div>
</section>
