<div class="col-xs-12 padding-none-xs border-xs-grey6-xs background-blue2-xs min-height-300-xs">
  <h5 class="col-xs-12 margin-top-xs-xs text-bold">{{ contract.name }}</h5>

  <div class="col-xs-12 padding-none-xs padding-left-sm-xs margin-top-sm-xs">
    <div class="col-xs-12 padding-left-xs-xs text-bold">
      {{ 'admin.users.detail.contract.status' | translate }}{{ contract.status }}
    </div>
    <div class="col-xs-12 padding-left-xs-xs">
      <strong>{{ 'admin.users.detail.contract.subscriptionDate' | translate }} </strong>
      : {{ contract.subscriptionDate | date: 'dd/MM/yyyy' }}
    </div>
    <div *ngIf="contract.terminationDate" class="col-xs-12 padding-left-xs-xs">
      <strong>{{ 'admin.users.detail.contract.terminationDate' | translate }} </strong>
      : {{ contract.terminationDate | date: 'dd/MM/yyyy' }}
    </div>
  </div>

  <div class="col-xs-12 padding-none-xs padding-left-sm-xs margin-top-xs-xs">
    <div class="clause col-xs-12 padding-left-xs-xs">
      <h5>
        <strong>Clause</strong>
        : {{ contract.clause.fullName }}
      </h5>
      <div *ngIf="contract.clause.type === 'CUSTOM'" class="col-xs-12 padding-none-xs">
        {{ contract.clause.description }}
      </div>
    </div>
    <div class="col-xs-12 margin-top-sm-xs padding-left-xs-xs">
      <strong>{{ 'admin.users.detail.contract.contributionRate' | translate }}</strong>
      : {{ contract.contributionRate }}
    </div>
  </div>
</div>
