import { Component } from '@angular/core';

@Component({
  selector: 'n9-home-subscription',
  templateUrl: './home-subscription.component.html',
  styleUrls: ['./home-subscription.component.scss']
})
export class HomeSubscriptionComponent {
  constructor() {}
}
