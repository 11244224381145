<div class="container-fluid padding-bottom-md-xs padding-left-xs-xs padding-left-md-sm">
  <p class="margin-top-md-xs margin-bottom-md-xs dosis-bold text-left text-bold text-18-xs text-24-sm text-blue3-xs">
    {{ 'admin.accounts.title' | translate }}
  </p>

  <div
    *ngIf="this.isUserAdmin()"
    class="col-xs-12 padding-md-xs margin-top-xs-xs background-white-xs border-grey3-xs-xs"
  >
    <n9-create-carel-account (userCreated)="isFormSubmitted($event)"></n9-create-carel-account>
  </div>

  <pagination
    [boundaryLinks]="false"
    [directionLinks]="true"
    [itemsPerPage]="size"
    [totalItems]="nbElements"
    [(ngModel)]="page"
    previousText="&lsaquo;"
    nextText="&rsaquo;"
    (pageChanged)="getUsersBO($event)"
  >
  </pagination>

  <table class="col-xs-12 margin-top-md-xs">
    <tbody *ngIf="users">
      <tr class="text-center dosis-bold text-white-xs background-blue3-xs">
        <td>{{ 'admin.accounts.form.login' | translate }}</td>
        <td>{{ 'admin.accounts.form.lastname' | translate }}</td>
        <td>{{ 'admin.accounts.form.firstname' | translate }}</td>
        <td>{{ 'admin.accounts.form.email' | translate }}</td>
        <td>{{ 'admin.accounts.form.roles' | translate }}</td>
        <td>{{ 'admin.accounts.list.createdDateTime' | translate }}</td>
        <td>
          {{ 'admin.accounts.list.lastModifiedDateTime' | translate }}
        </td>
        <td>{{ 'admin.accounts.list.interact' | translate }}</td>
      </tr>

      <tr *ngFor="let user of users as User" class="text-center background-white-xs users-table openSans-regular">
        <td>{{ user.login }}</td>
        <td>{{ user.lastname }}</td>
        <td>{{ user.firstname }}</td>
        <td>{{ user.email }}</td>
        <td>{{ availableRoles[user.roles] }}</td>
        <td>{{ user['createdDateTime'] | date: 'dd/MM/yyyy' }}</td>
        <td>{{ user['lastModifiedDateTime'] | date: 'dd/MM/yyyy' }}</td>
        <td>
          <span
            [routerLink]="[user.login]"
            class="glyphicon glyphicon-pencil background-green2-xs border-grey3-xs-xs padding-xs-xs"
          ></span>
          <span
            *ngIf="this.isUserAdmin() && user.login !== this.currentUser.login"
            (click)="openModal(deleteUserModal)"
            class="glyphicon glyphicon-trash background-red1-xs border-grey3-xs-xs padding-xs-xs margin-left-sm-xs"
          ></span>
        </td>

        <ng-template #deleteUserModal>
          <div class="modal-header openSans-regular">
            <h4 class="modal-title pull-left">Confirmation de suppression</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body openSans-regular">
            <p>
              Êtes-vous sûr de vouloir définitivement supprimer l'utilisateur
              <span class="openSans-bold">{{ user.login }}</span>
              ?
            </p>
            <p class="openSans-bold">Cette action est irreversible.</p>
            <button
              class="background-red1-xs padding-md-xs margin-top-lg-xs dosis-bold text-white-xs text-uppercase border-none-xs"
              (click)="deleteUser(user.login); modalRef.hide()"
            >
              Oui, supprimer cet utilisateur
            </button>
          </div>
        </ng-template>
      </tr>
    </tbody>
  </table>
</div>
