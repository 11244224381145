import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ROLE } from '@core/models/role.model';
import { ProfileResolver } from '@core/resolvers/profile.resolver';
import { RoleGuard } from '@core/session/role.guard';
import { AccountDetailsComponent } from './accounts/account-details/account-details.component';
import { AccountsListComponent } from './accounts/accounts-list/accounts-list.component';
import { AccountsComponent } from './accounts/accounts.component';

import { AdminHomeComponent } from './admin-home.component';
import { AssemblyListComponent } from './assembly/assembly-list.component';
import { AssemblyNewComponent } from './assembly/assembly-new/assembly-new.component';
import { LoggedInGuard } from '@core/session/logged-in.guard';
import { MessageNewComponent } from '@shared/components/threads/messages/messages-new/message-new.component';
import { ThreadsDetailComponent } from './discussions/threads/threads-detail/threads-detail.component';
import { ThreadsListComponent } from './discussions/threads/threads-list/threads-list.component';
import { ThreadsComponent } from './discussions/threads/threads.component';
import { HistoryComponent } from './history/history.component';
import { MembershipDetailComponent } from './membership/membership-detail/membership-detail.component';
import { MembershipListComponent } from './membership/membership-list/membership-list.component';
import { MembershipComponent } from './membership/membership.component';
import { ReceptionComponent } from './reception/reception.component';
import { UsersComponent } from './users/users.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { UserDetailComponent } from './users/user-detail/user-detail.component';
import { MessageListComponent } from '@shared/components/threads/messages/messages-list/message-list.component';
import { MessageDetailComponent } from '@shared/components/threads/messages/messages-detail/message-detail.component';
import { MessagesComponent } from '@shared/components/threads/messages/messages.component';

const adminHomeRoutes: Routes = [
   {
    path: 'admin/home',
    component: AdminHomeComponent,
    canActivate: [LoggedInGuard, RoleGuard],
    data: {
      authorizedRoles: [ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER]
    },
    resolve: {
      user: ProfileResolver
    },
    children: [
      {
        path: '',
        component: ReceptionComponent
      },
      {
        path: 'membership',
        component: MembershipComponent,
        children: [
          {
            path: '',
            component: MembershipListComponent
          },
          {
            path: ':id',
            component: MembershipDetailComponent
          }
        ]
      },
      {
        path: 'users',
        component: UsersComponent,
        children: [
          {
            path: '',
            component: UserListComponent
          },
          {
            path: ':id',
            component: UserDetailComponent
          }
        ]
      },
      {
        path: 'messages',
        component: MessagesComponent,
        children: [
          {
            path: '',
            component: MessageListComponent
          },
          {
            path: 'new',
            component: MessageNewComponent
          },
          {
            path: ':id',
            component: MessageDetailComponent
          }
        ]
      },
      /*{
				path: 'threads',
				component: ThreadsComponent,
				children: [
					{
						path: '',
						component: ThreadsListComponent
					},
					{
						path: ':id',
						component: ThreadsDetailComponent
					}
				]
			},
      {
				path: 'assemblies',
				component: AssemblyListComponent
			},*/
      {
        path: 'assemblies/new',
        component: AssemblyNewComponent
      },
      {
        path: 'accounts',
        component: AccountsComponent,
        children: [
          {
            path: '',
            component: AccountsListComponent
          },
          {
            path: ':idGest',
            component: AccountDetailsComponent
          }
        ]
      },
      {
        path: 'history',
        component: HistoryComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(adminHomeRoutes)],
  exports: [RouterModule]
})
export class AdminHomeRoutingModule {}
