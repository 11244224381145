import { Injectable } from '@angular/core';
import { StoreService } from './store.service';

@Injectable()
export class LocalStorageStoreService implements StoreService {
  get(key: string): any {
    try {
      return JSON.parse(window.localStorage.getItem(key));
    } catch (e) {
      return null;
    }
  }

  set(key: string, data?: any): void {
    if (data === undefined) {
      this.del(key);
      return;
    }
    try {
      window.localStorage.setItem(key, JSON.stringify(data));
    } catch (e) {}
  }

  del(key: string): void {
    try {
      window.localStorage.removeItem(key);
    } catch (e) {}
  }

  clear(): void {
    try {
      window.localStorage.clear();
    } catch (e) {}
  }
}
