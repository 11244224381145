<div class="col-xs-12 padding-left-sm-xs padding-right-sm-xs padding-left-lg-sm padding-right-lg-sm">
  <p
    class="col-xs-12 padding-none-xs margin-top-md-xs margin-bottom-sm-xs dosis-bold text-18-xs text-24-sm text-left text-bold text-blue3-xs"
  >
    {{ 'home.personalData.title' | translate }}
  </p>
  <div class="col-xs-12 padding-none-xs">
    <div
      class="col-xs-12 padding-left-xs-xs padding-right-xs-xs padding-left-sm-sm padding-right-sm-sm padding-bottom-sm-xs padding-top-sm-xs background-white-xs border-grey3-xs-xs"
    >
      <n9-personal-data-form [user]="user" (doneEditing)="doneEditing($event)"></n9-personal-data-form>

      <div class="info col-xs-12">
        <p
          *ngIf="dataStatus !== ds.WAITING"
          class="openSans-bold margin-bottom-sm-xs text-center text-16-xs"
          [ngClass]="
            dataStatus == ds.PENDING ? 'text-grey4-xs' : dataStatus == ds.SUCCESS ? 'text-green2-xs' : 'text-red1-xs'
          "
        >
          {{
            'home.personalData.form.submit.' +
              (dataStatus == ds.PENDING
                ? 'pending'
                : dataStatus == ds.SUCCESS
                ? 'success'
                : dataStatus == ds.FORBIDDEN
                ? 'errorDuplicate'
                : 'error') | translate
          }}
        </p>
      </div>
    </div>
  </div>
</div>
