import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { User } from '@core/models';
import { SessionService } from '@core/session/session.service';

@Component({
  selector: 'n9-admin-reception',
  templateUrl: 'reception.component.html'
})
export class ReceptionComponent {
  isProduction: boolean;
  manager: User;

  constructor(private http: HttpClient, private sessionService: SessionService) {
    this.isProduction = environment.production;
    this.manager = this.sessionService.getUser();
  }

  public updateManagers(): void {
    this.http.get('/users/managers/update').subscribe(() => {});
  }
}
