import { NgModule } from '@angular/core';
import { MaintenanceRoutingModule } from './maintenance-routing.module';
import { MaintenanceComponent } from './maintenance.component';

@NgModule({
  providers: [],
  imports: [MaintenanceRoutingModule],
  declarations: [MaintenanceComponent]
})
export class MaintenanceModule {}
