<div
  class="col-xs-12 contract-list margin-bottom-md-xs padding-left-lg-sm padding-right-lg-sm padding-left-md-xs padding-right-md-xs padding-none-xs"
>
  <!-- User information display for xs devices  -->
  <n9-user-info class="col-xs-12 padding-left-xs-xs padding-right-xs-xs margin-top-xs-xs hidden-sm hidden-md hidden-lg">
  </n9-user-info>
  <!-- Page title -->
  <p
    class="col-xs-12 padding-none-xs margin-top-md-xs margin-bottom-sm-xs margin-bottom-xs-md dosis-bold text-14-xs text-24-sm text-left text-bold text-blue3-xs"
  >
    {{ 'home.contracts.list.title' | translate }}
  </p>
  <div *ngIf="user.membershipDocumentId" class="col-xs-12">
    <label for="addmandate" class="button-mandate text-uppercase text-center openSans-semibold cursor-hand">
      <span
        class="glyphicon glyphicon-download-alt borders-20-percent background-white-xs text-blue3-xs padding-xs-xs margin-right-xs-xs"
      ></span>
      Télécharger mon bulletin d'adhésion
      <input
        type="button"
        id="addmandate"
        class="hidden-xs hidden-sm hidden-md hidden-lg"
        style="width: 100%"
        (click)="downloadUserMembershipCertificate()"
      />
    </label>
    <div *ngIf="errorMessage && errorMessage.length > 0" class="col-xs-12 margin-bottom-sm-xs text-red1-xs text-14-xs">
      ( {{ errorMessage }} )
    </div>
  </div>
  <ng-template #error>
    <div class="modal-header openSans-regular">
      <h4 class="modal-title pull-left">Une erreur s'est produite</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body openSans-regular text-center">
      <p class="openSans-bold text-red1-xs text-16-xs">
        {{ errorMessage }}
      </p>
      <button
        class="background-red1-xs padding-sm-xs padding-left-lg-xs padding-right-lg-xs margin-top-lg-xs dosis-bold text-white-xs text-uppercase border-none-xs"
        (click)="modalRef.hide()"
      >
        OK
      </button>
    </div>
  </ng-template>

  <!-- SECTION XS devices -->
  <section class="col-xs-12 padding-none-xs hidden-sm hidden-md hidden-lg">
    <div class="col-xs-12 padding-left-xs-xs padding-right-xs-xs margin-top-md-xs" *ngIf="selectedContractTab === 1">
      <div class="col-xs-12 padding-none-xs">
        <span
          class="col-xs-4 button-content padding-none-xs padding-top-sm-xs padding-bottom-sm-xs openSans-bold text-center text-10-xs border-xs-grey3-xs background-white-xs"
          [ngClass]="{ 'tab-selected': selectedListTab === 1 }"
          (click)="selectListTab(1)"
        >
          {{ 'home.contracts.list.contributedContracts.currentTitle' | translate }}
        </span>
        <span
          class="col-xs-4 button-content padding-none-xs padding-top-sm-xs padding-bottom-sm-xs openSans-bold text-center text-10-xs border-xs-grey3-xs background-white-xs"
          [ngClass]="{ 'tab-selected': selectedListTab === 2 }"
          (click)="selectListTab(2)"
        >
          {{ 'home.contracts.list.contributedContracts.terminatedTitle' | translate }}
        </span>
        <span
          class="col-xs-4 button-content padding-none-xs padding-top-sm-xs padding-bottom-sm-xs openSans-bold text-center text-10-xs border-xs-grey3-xs background-white-xs"
          [ngClass]="{ 'tab-selected': selectedListTab === 3 }"
          (click)="selectListTab(3)"
        >
          {{ 'home.contracts.list.contributedContracts.liquidatedTitle' | translate }}
        </span>
      </div>

      <div class="col-xs-12 padding-none-xs" *ngIf="selectedListTab === 1">
        <n9-contract-card
          class="col-xs-12 padding-none-xs margin-top-sm-xs"
          *ngFor="let contract of currentContracts"
          [contract]="contract"
          [actions]="currentContractActions"
          [isXs]="true"
        >
        </n9-contract-card>
      </div>
      <div class="col-xs-12 padding-none-xs" *ngIf="selectedListTab === 2">
        <n9-contract-card
          class="col-xs-12 padding-none-xs margin-top-sm-xs"
          *ngFor="let contract of terminatedContracts"
          [contract]="contract"
          [actions]="terminatedContractActions"
          [isXs]="true"
        >
        </n9-contract-card>
      </div>
      <div class="col-xs-12 padding-none-xs" *ngIf="selectedListTab === 3">
        <n9-contract-card
          class="col-xs-12 padding-none-xs margin-top-sm-xs"
          *ngFor="let contract of liquidatedContracts"
          [contract]="contract"
          [actions]="liquidatedContractActions"
          [isXs]="true"
        >
        </n9-contract-card>
      </div>
    </div>

    <div class="col-xs-12 padding-left-xs-xs padding-right-xs-xs" *ngIf="selectedContractTab === 2">
      <p
        class="col-xs-12 padding-none-xs margin-top-md-xs line-height-md-xs openSans-bold text-blue3-xs text-bold text-14-xs text-center"
      >
        {{ 'home.contracts.list.noContributionContracts.formConfirm.line1' | translate }}
      </p>
      <p class="col-xs-12 padding-none-xs margin-top-xs-xs line-height-md-xs openSans-semibold text-12-xs text-center">
        {{ 'home.contracts.list.noContributionContracts.formConfirm.line2' | translate }}
      </p>
      <div class="col-xs-12 padding-none-xs margin-top-sm-xs text-center">
        <a
          class="col-xs-10 col-xs-offset-1 button-content padding-sm-xs background-red1-xs openSans-bold text-white-xs text-uppercase text-8-xs"
          (click)="declareMandate()"
        >
          <i class="fa fa-arrow-circle-o-up margin-right-xs-xs text-white text-12-xs" aria-hidden="true"></i>
          {{ 'home.contracts.list.noContributionContracts.formConfirm.addMandateButton' | translate }}
        </a>
      </div>
      <div class="col-xs-12 padding-none-xs margin-top-lg-xs">
        <p class="col-xs-12 padding-none-xs openSans-bold text-left text-12-xs text-blue3-xs text-bold">
          Contrats en attente de validation
        </p>
        <div class="col-xs-12 padding-none-xs">
          <n9-mandate-card
            *ngFor="let mandate of mandates"
            [mandate]="mandate"
            class="col-xs-12 padding-none-xs padding-bottom-md-xs"
          >
          </n9-mandate-card>
        </div>
      </div>
    </div>
  </section>

  <!-- SECTION SM, MD AND LG devices -->
  <section class="col-xs-12 padding-none-xs hidden-xs margin-top-xs-xs">
    <div class="col-xs-12 padding-none-xs margin-top-md-xs" *ngIf="selectedContractTab === 1">
      <div class="row padding-none-xs">
        <div class="col-xs-6 col-lg-4">
          <h5 class="col-xs-12 openSans-bold text-center text-20-xs text-blue3-xs">
            {{ 'home.contracts.list.contributedContracts.currentTitle' | translate }}
          </h5>
          <n9-contract-card
            *ngFor="let contract of currentContracts"
            class="col-xs-12 padding-none-xs margin-top-md-xs"
            [contract]="contract"
            [actions]="currentContractActions"
            [isXs]="false"
            (actionOnContract)="executeAction($event)"
          >
          </n9-contract-card>
        </div>
        <div class="col-xs-6 col-lg-4">
          <h5 class="col-xs-12 openSans-bold text-center text-20-xs text-blue3-xs">
            {{ 'home.contracts.list.contributedContracts.terminatedTitle' | translate }}
          </h5>
          <n9-contract-card
            *ngFor="let contract of terminatedContracts"
            class="col-xs-12 padding-none-xs margin-top-md-xs"
            [contract]="contract"
            [actions]="terminatedContractActions"
            [isXs]="false"
            (actionOnContract)="executeAction($event)"
          >
          </n9-contract-card>
        </div>
        <div class="col-xs-6 col-lg-4">
          <h5 class="col-xs-12 openSans-bold text-center text-20-xs text-blue3-xs">
            {{ 'home.contracts.list.contributedContracts.liquidatedTitle' | translate }}
          </h5>
          <n9-contract-card
            *ngFor="let contract of liquidatedContracts"
            class="col-xs-12 padding-none-xs margin-top-md-xs"
            [contract]="contract"
            [actions]="liquidatedContractActions"
            [isXs]="false"
            (actionOnContract)="executeAction($event)"
          >
          </n9-contract-card>
        </div>
      </div>
    </div>
    <div class="col-xs-12 margin-top-md-xs" *ngIf="selectedContractTab === 2">
      <div class="col-xs-10 col-xs-offset-1 col-lg-8 col-lg-offset-2 padding-md-xs">
        <p
          class="col-xs-12 padding-left-xs-xs padding-right-xs-xs padding-left-sm-lg padding-right-sm-lg margin-top-md-xs openSans-bold text-blue3-xs text-bold text-12-xs text-14-sm text-center"
        >
          {{ 'home.contracts.list.noContributionContracts.formConfirm.line1' | translate }}
        </p>
        <p class="col-xs-12 padding-none-xs margin-top-xs-xs openSans-semibold text-10-xs text-12-xs text-center">
          {{ 'home.contracts.list.noContributionContracts.formConfirm.line2' | translate }}
        </p>
        <div class="col-xs-12 text-center margin-top-md-xs">
          <a
            class="col-xs-10 col-xs-offset-1 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3 openSans-bold padding-sm-xs background-red1-xs text-white-xs text-uppercase cursor-hand"
            (click)="declareMandate()"
          >
            <i class="fa fa-arrow-circle-o-up margin-right-sm-xs text-white" aria-hidden="true"></i>
            {{ 'home.contracts.list.noContributionContracts.formConfirm.addMandateButton' | translate }}
          </a>
        </div>
      </div>
      <div class="col-xs-12 padding-none-xs">
        <p class="col-xs-12 padding-none-xs openSans-bold text-left text-12-xs text-blue3-xs text-bold">
          Contrats en attente de validation
        </p>
        <div class="col-xs-12 padding-left-sm-xs padding-right-sm-xs">
          <n9-mandate-card
            *ngFor="let mandate of mandates"
            [mandate]="mandate"
            class="col-xs-6 col-lg-4 padding-left-sm-xs padding-right-sm-xs"
          >
          </n9-mandate-card>
        </div>
      </div>
    </div>
  </section>
</div>
<div class="col-xs-12 text-center margin-top-lg-xs margin-bottom-lg-xs">
  <a
    routerLink="../../subscribe"
    class="btn border-radius-none-xs padding-left-lg-xs padding-right-lg-xs padding-md-xs background-red1-xs text-white-xs text-12-xs text-16-sm dosis-bold text-uppercase"
  >
    <i class="fa fa-plus-circle padding-right-sm-xs" aria-hidden="true"></i>
    {{ 'home.contracts.list.newMandate' | translate }}
  </a>
</div>
