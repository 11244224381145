<div class="col-xs-12 openSans-regular padding-none-xs margin-top-md-xs">
  <div
    class="col-xs-12 margin-top-md-xs margin-bottom-sm-xs dosis-bold padding-none-xs"
    style="display: inline-flex; align-items: center; justify-content: space-between"
  >
    <div style="display: inline-flex; align-items: center">
      <span class="text-left text-bold text-18-xs text-24-sm text-blue3-xs">
        {{ 'admin.users.multi-contact.title' | translate }}
      </span>

      <div dropdown class="margin-left-sm-xs">
        <a
          href
          id="basic-link"
          dropdownToggle
          aria-controls="basic-link-dropdown"
          (click)="(false)"
          class="background-white-xs padding-left-md-xs padding-right-md-xs padding-top-sm-xs padding-bottom-sm-xs border-grey3-xs-xs rounded"
        >
          {{ 'admin.users.multi-contact.models.label' | translate }}
          <span class="caret"></span>
        </a>

        <ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="basic-link">
          <li
            *ngFor="let notification of this.pageableSavedNotifications['content'] as Notification"
            class="col-xs-12 padding-none-xs"
          >
            <a class="dropdown-item col-xs-11" (click)="fillForm(notification)">
              {{ notification.subject }}
            </a>
            <span
              class="glyphicon glyphicon-trash color-black"
              (click)="this.deleteNotificationById(notification.id)"
              style="vertical-align: bottom"
            ></span>
          </li>
          <li *ngIf="this.pageableSavedNotifications['nbElements'] === 0">
            <span class="padding-xs-xs">{{ 'admin.users.multi-contact.models.noModel' | translate }}</span>
          </li>
        </ul>
      </div>
    </div>

    <div
      class="background-blue3-xs padding-left-md-xs padding-right-md-xs padding-top-sm-xs padding-bottom-sm-xs color-white cursor-hand"
      (click)="this.saveNotification()"
      style="display: inline-flex; align-items: center"
    >
      <span class="glyphicon glyphicon-floppy-disk margin-left-none-xs margin-right-sm-xs text-18-xs"></span>
      <span>{{ 'admin.users.multi-contact.models.save' | translate | uppercase }}</span>
    </div>
  </div>

  <div class="col-xs-12 padding-md-xs background-white-xs border-grey3-xs-xs">
    <form
      [formGroup]="multiContactForm"
      class="row col-xs-12 margin-none-xs margin-top-md-xs padding-none-xs"
      (ngSubmit)="onSubmit()"
    >
      <div class="row padding-left-md-xs padding-right-md-xs">
        <div class="col-xs-6 padding-none-xs">
          <label for="userInput" class="col-xs-11 padding-none-xs openSans-bold">
            {{ 'admin.users.multi-contact.users' | translate }} :
            <input
              id="userInput"
              class="form-control margin-top-xs-xs openSans-regular"
              formControlName="userInput"
              [typeahead]="usersSearch"
              [typeaheadMinLength]="0"
              (typeaheadOnSelect)="onSelect($event)"
              autocomplete="off"
            />
          </label>
        </div>

        <n9-multi-select
          [parentForm]="multiContactForm"
          controlName="filters"
          [options]="mandatesService.referentials"
        ></n9-multi-select>
      </div>

      <div *ngIf="getUsersArray().length > 0" class="col-xs-12 padding-xs-xs margin-top-sm-xs openSans-bold">
        <p>{{ 'admin.users.multi-contact.selected' | translate }} :</p>
        <div *ngFor="let user of getUsersArray().value; let i = index" style="display: inline-flex">
          <span
            *ngIf="i < 8"
            class="background-blue3-xs padding-sm-xs margin-right-xs-xs margin-top-xs-xs text-white-xs"
          >
            {{ user }}
            <span class="close custom-close margin-left-xs-xs text-white-xs" (click)="removeUser(user)">&times;</span>
          </span>

          <div *ngIf="i === 8" class="text-blue3-xs" (click)="userModal.show()">
            <span class="text-18-xs margin-left-xs-xs">+ {{ getUsersArray().length - 8 }}</span>
            <span class="text-14-xs underlined-link margin-left-md-xs cursor-hand">
              {{ 'admin.users.multi-contact.listSelected.0' | translate }}
              {{ getUsersArray().length }}
              {{ 'admin.users.multi-contact.listSelected.1' | translate }}
            </span>
          </div>

          <div class="modal fade" bsModal #userModal="bs-modal">
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header openSans-regular">
                  <h4 class="modal-title pull-left dosis-bold text-22-xs text-blue3-xs">
                    Liste des
                    {{ getUsersArray().length }} adhérents sélectionnés
                  </h4>
                  <button type="button" class="close pull-right" aria-label="Close" (click)="userModal.hide()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div class="modal-body openSans-regular">
                  <table class="col-xs-12 border-grey3-xs-xs">
                    <tbody *ngIf="usersSearch">
                      <tr class="dosis-bold text-white-xs background-blue3-xs">
                        <td>
                          {{ 'admin.users.list.info.eluNumber' | translate }}
                        </td>
                        <td>
                          {{ 'admin.users.list.info.lastname' | translate }}
                        </td>
                        <td>
                          {{ 'admin.users.list.info.firstnames' | translate }}
                        </td>
                        <td>
                          {{ 'admin.users.list.info.modal.department' | translate }}
                        </td>
                        <td class="text-center">
                          {{ 'admin.users.list.info.modal.remove' | translate }}
                        </td>
                      </tr>

                      <tr
                        *ngFor="let user of modalUsers as User; let i = index"
                        class="background-white-xs openSans-regular"
                      >
                        <td>{{ user.eluNumber }}</td>
                        <td>{{ user.lastname }}</td>
                        <td>{{ user.firstname }}</td>
                        <td>{{ user.postalCode }}</td>
                        <td
                          (click)="removeUser(this.fromUserToTypeahead(user))"
                          class="text-blue3-xs dosis-bold text-center text-24-xs cursor-hand"
                        >
                          &times;
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row padding-md-xs">
        <div class="col-xs-6 padding-none-xs">
          <label for="subject" class="col-xs-11 padding-none-xs margin-top-md-xs openSans-bold">
            {{ 'admin.users.multi-contact.subject' | translate }} :
            <input id="subject" class="form-control margin-top-xs-xs openSans-regular" formControlName="subject" />
          </label>
        </div>

        <div class="col-xs-6 padding-none-xs">
          <label for="type" class="col-xs-11 margin-top-md-xs openSans-bold">
            {{ 'admin.users.multi-contact.notificationType.label' | translate }}
            :
            <select id="type" class="form-control margin-top-xs-xs openSans-regular" formControlName="type">
              <option value="DEFAULT">
                {{ 'admin.users.multi-contact.notificationType.default' | translate }}
              </option>
              <option value="LOGIN">
                {{ 'admin.users.multi-contact.notificationType.login' | translate }}
              </option>
              <option value="EMAIL">
                {{ 'admin.users.multi-contact.notificationType.email' | translate }}
              </option>
            </select>
          </label>
        </div>
      </div>

      <div class="row col-xs-12 margin-top-md-xs" style="display: flex">
        <label for="content" class="row col-xs-12 openSans-bold">
          {{ 'admin.users.multi-contact.message' | translate }} :
          <textarea
            id="content"
            class="col-xs-12 form-control margin-top-xs-xs openSans-regular"
            formControlName="content"
          ></textarea>
        </label>
      </div>

      <label
        for="submit"
        class="row margin-top-md-xs cursor-hand margin-left-xs-xs padding-md-xs background-red1-xs text-white-xs text-18-xs dosis-bold"
        [ngClass]="!multiContactForm.valid ? 'disabled' : ''"
      >
        {{ 'admin.users.multi-contact.submit.0' | translate }}
        {{ getUsersArray().length }}
        {{ 'admin.users.multi-contact.submit.1' | translate }}{{ getUsersArray().length > 1 ? 's' : '' }}
        <input
          id="submit"
          type="submit"
          class="hidden-xs hidden-sm hidden-md hidden-lg"
          [disabled]="!multiContactForm.valid"
        />
      </label>
    </form>

    <p
      *ngIf="this.isNotificationSent !== null"
      class="col-xs-12 padding-none-xs margin-top-sm-xs"
      [ngClass]="this.isNotificationSent ? 'text-green2-xs' : 'text-red1-xs'"
    >
      {{ 'admin.users.multi-contact.' + (this.isNotificationSent ? 'sent' : 'error') | translate }}
    </p>
  </div>
</div>
