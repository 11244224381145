import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

export enum SORT_ORDER {
  ASC = 'asc',
  DESC = 'desc',
  NONE = 'none'
}

@Component({
  selector: 'n9-sort-arrows',
  templateUrl: './sort-arrows.component.html',
  styleUrls: ['./sort-arrows.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SortArrowsComponent {
  @Input() order: SORT_ORDER = SORT_ORDER.NONE;
  @Output() sort: EventEmitter<SORT_ORDER> = new EventEmitter<SORT_ORDER>();

  public SORT_ORDER: typeof SORT_ORDER = SORT_ORDER;

  public doSort(sortOrder: SORT_ORDER): void {
    this.order = sortOrder === this.order ? SORT_ORDER.NONE : sortOrder;
    this.sort.emit(this.order);
  }
}
