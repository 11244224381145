<div class="col-xs-12 padding-left-xs-xs padding-right-xs-xs padding-left-md-sm padding-right-md-sm">
  <!-- User information display for xs devices  -->
  <n9-user-info class="col-xs-12 padding-none-xs margin-top-sm-xs hidden-sm hidden-md hidden-lg"> </n9-user-info>

  <!-- Declare mandate form -->
  <p
    class="col-xs-12 padding-none-xs margin-top-sm-xs margin-bottom-sm-xs dosis-bold text-14-xs text-18-sm text-left text-bold text-blue3-xs"
  >
    {{ 'home.contracts.mandate.title' | translate }}
  </p>
  <div
    *ngIf="!hasDeclared"
    class="col-xs-12 padding-xs-xs padding-sm-sm padding-bottom-md-xs background-white-xs border-xs-grey3-xs"
  >
    <div class="introduction col-xs-12 col-md-10 col-md-offset-1 padding-none-xs">
      <div
        class="col-xs-12 padding-left-sm-xs padding-right-sm-xs openSans-bold text-center text-blue6-xs text-bold text-14-xs text-18-md"
      >
        {{ 'home.contracts.list.noContributionContracts.formConfirm.line1' | translate }}
      </div>
      <div
        class="col-xs-12 col-md-10 col-md-offset-1 padding-left-sm-xs padding-right-sm-xs margin-top-sm-xs text-center openSans-semibold text-blue4-xs text-bold text-10-xs text-10-md"
      >
        {{ 'home.contracts.list.noContributionContracts.formConfirm.line2' | translate }}
      </div>
    </div>

    <form
      class="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 form padding-left-xs-xs padding-right-xs-xs padding-left-sm-sm padding-right-sm-sm margin-top-lg-xs margin-bottom-lg-xs"
      novalidate
      [formGroup]="mandateForm"
      (ngSubmit)="onSubmit($event)"
    >
      <div class="col-xs-12 padding-none-xs">
        <label
          for="collectivity-type"
          class="col-xs-12 padding-none-xs text-left openSans-bold text-12-xs text-bold text-blue6-xs"
        >
          {{ 'home.contracts.mandate.form.display.collectivityType' | translate }}
        </label>
        <div class="col-xs-12 padding-none-xs">
          <select id="collectivity-type" class="form-control" formControlName="collectivityType">
            <option *ngFor="let collectivityType of collectivityTypes">
              {{ collectivityType }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-xs-12 padding-none-xs margin-top-md-xs">
        <label
          for="collectivity-name"
          class="col-xs-12 padding-none-xs text-left openSans-bold text-12-xs text-bold text-blue6-xs"
        >
          {{ 'home.contracts.mandate.form.display.collectivityName' | translate }}
        </label>
        <div class="col-xs-12 padding-none-xs">
          <input id="collectivity-name" type="text" class="form-control" formControlName="collectivityName" />
        </div>
        <div
          class="col-xs-12 margin-top-xs-xs"
          *ngIf="mandateForm.controls.collectivityName.errors && mandateForm.controls.collectivityName.dirty"
        >
          <div
            [hidden]="!mandateForm.controls.collectivityName.errors.required"
            class="col-xs-12 padding-none-xs text-left text-8-xs text-10-sm text-red1-xs"
          >
            {{ 'home.contracts.mandate.form.errors.collectivityName.required' | translate }}
          </div>
        </div>
      </div>
      <div class="col-xs-12 padding-none-xs margin-top-md-xs">
        <label
          for="mandate-type"
          class="col-xs-12 padding-none-xs text-left openSans-bold text-12-xs text-bold text-blue6-xs"
        >
          {{ 'home.contracts.mandate.form.display.mandateType' | translate }}
        </label>
        <div class="col-xs-12 padding-none-xs">
          <select id="mandate-type" class="form-control" formControlName="mandateType">
            <option *ngFor="let mandateType of mandateTypes">
              {{ mandateType }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-xs-12 padding-none-xs margin-top-md-xs">
        <label
          for="election-date"
          class="col-xs-12 padding-none-xs text-left openSans-bold text-12-xs text-bold text-blue6-xs"
        >
          {{ 'home.contracts.mandate.form.display.electionDate' | translate }}
        </label>
        <div class="col-xs-12 padding-none-xs">
          <input type="date" id="election-date" class="form-control" formControlName="electionDate" />
        </div>
        <div
          class="col-xs-12 margin-top-xs-xs"
          *ngIf="mandateForm.controls.electionDate.errors && mandateForm.controls.electionDate.dirty"
        >
          <div
            [hidden]="!mandateForm.controls.electionDate.errors.required"
            class="col-xs-12 padding-none-xs text-left text-8-xs text-10-sm text-red1-xs"
          >
            {{ 'home.contracts.mandate.form.errors.electionDate.required' | translate }}
          </div>
          <div
            [hidden]="!mandateForm.controls.electionDate.errors.n9BeforeNow"
            class="col-xs-12 padding-none-xs text-left text-8-xs text-10-sm text-red1-xs"
          >
            {{ 'home.contracts.mandate.form.errors.electionDate.dateMin' | translate }}
          </div>
        </div>
      </div>
      <div class="col-xs-12 padding-none-xs margin-top-md-xs">
        <label
          for="subscribe-date"
          class="col-xs-12 padding-none-xs text-left openSans-bold text-12-xs text-bold text-blue6-xs"
        >
          {{ 'home.contracts.mandate.form.display.subscribeDate' | translate }}
        </label>
        <div class="col-xs-12 padding-none-xs">
          <input id="subscribe-date" type="date" class="form-control" formControlName="subscribeDate" />
        </div>
        <div
          class="col-xs-12 margin-top-xs-xs"
          *ngIf="mandateForm.controls.subscribeDate.errors && mandateForm.controls.subscribeDate.dirty"
        >
          <div
            [hidden]="!mandateForm.controls.subscribeDate.errors.required"
            class="col-xs-12 padding-none-xs text-left text-8-xs text-10-sm text-red1-xs"
          >
            {{ 'home.contracts.mandate.form.errors.subscribeDate.required' | translate }}
          </div>
          <div
            [hidden]="!mandateForm.controls.subscribeDate.errors.n9AfterNow"
            class="col-xs-12 padding-none-xs text-left text-8-xs text-10-sm text-red1-xs"
          >
            {{ 'home.contracts.mandate.form.errors.subscribeDate.dateMin' | translate }}
          </div>
        </div>
      </div>
      <div class="col-xs-12 padding-none-xs margin-top-md-xs">
        <label
          for="salary"
          class="col-xs-12 padding-none-xs text-left openSans-bold text-12-xs text-bold text-blue6-xs"
        >
          {{ 'home.contracts.mandate.form.display.salary' | translate }}
        </label>
        <div class="col-xs-12 padding-none-xs">
          <input id="salary" type="number" class="form-control" formControlName="salary" />
        </div>
        <div
          class="col-xs-12 margin-top-xs-xs"
          *ngIf="mandateForm.controls.salary.errors && mandateForm.controls.salary.dirty"
        >
          <div
            [hidden]="!mandateForm.controls.salary.errors.required"
            class="col-xs-12 padding-none-xs text-left text-8-xs text-10-sm text-red1-xs"
          >
            {{ 'home.contracts.mandate.form.errors.salary.required' | translate }}
          </div>
        </div>
      </div>
      <div class="col-xs-12 padding-none-xs margin-top-md-xs">
        <label class="col-xs-12 padding-none-xs text-left openSans-bold text-12-xs text-bold text-blue6-xs">
          {{ 'home.contracts.mandate.form.display.retroActiveMandates' | translate }}
        </label>
        <div class="col-xs-12 padding-none-xs padding-top-sm-xs text-left">
          <a
            class="dosis-bold padding-left-40 padding-right-40 padding-top-sm-xs padding-bottom-sm-xs background-white-xs border-sm-blue3-xs border-radius-xs-xs text-uppercase text-center text-blue6-xs margin-right-sm-xs cursor-hand"
            [ngClass]="{
              'tab-selected': mandateForm.controls.retroActiveMandates.value
            }"
            (click)="setRetroActiveMandates(true)"
          >
            Oui
          </a>
          <a
            class="dosis-bold padding-left-40 padding-right-40 padding-top-sm-xs padding-bottom-sm-xs background-white-xs border-sm-blue3-xs border-radius-xs-xs text-uppercase text-center text-blue6-xs cursor-hand"
            [ngClass]="{
              'tab-selected': !mandateForm.controls.retroActiveMandates.value
            }"
            (click)="setRetroActiveMandates(false)"
          >
            Non
          </a>
        </div>
      </div>
      <div class="col-xs-12 padding-none-xs margin-top-lg-xs" *ngIf="mandateForm.controls.retroActiveMandates.value">
        <label
          for="start-contribution-date"
          class="col-xs-12 padding-none-xs text-left openSans-bold text-12-xs text-bold text-blue6-xs text-italic"
        >
          {{ 'home.contracts.mandate.form.display.startContribution' | translate }}
        </label>
        <div class="col-xs-12 padding-none-xs">
          <input
            id="start-contribution-date"
            type="date"
            class="form-control"
            formControlName="startContributionDate"
          />
        </div>
        <div
          class="col-xs-12 margin-top-xs-xs"
          *ngIf="mandateForm.controls.startContributionDate.errors && mandateForm.controls.startContributionDate.dirty"
        >
          <div
            [hidden]="!mandateForm.controls.startContributionDate.errors.n9AfterNow"
            class="col-xs-12 padding-none-xs text-left text-8-xs text-10-sm text-red1-xs"
          >
            {{ 'home.contracts.mandate.form.errors.startContributionDate.dateMin' | translate }}
          </div>
        </div>
      </div>
      <div class="col-xs-12 padding-none-xs margin-top-lg-xs text-left text-12-xs text-bold text-blue6-xs">
        <button
          type="submit"
          [disabled]="!mandateForm.valid"
          class="col-xs-6 col-xs-offset-3 btn btn-default dosis-bold padding-sm-xs background-red3-xs text-center text-uppercase text-white-xs border-radius-none-xs"
        >
          {{ 'home.contracts.mandate.form.display.declareButton' | translate }}
        </button>
      </div>
    </form>
  </div>
  <div
    *ngIf="hasDeclared"
    class="col-xs-12 padding-left-sm-xs padding-right-sm-xs padding-bottom-lg-xs background-white-xs border-xs-grey3-xs"
  >
    <p
      class="col-xs-12 col-sm-10 col-sm-offset-1 padding-none-xs margin-top-md-xs margin-bottom-md-xs text-center openSans-bold text-bold text-12-xs text-14-sm text-blue6-xs"
    >
      {{ 'home.contracts.list.noContributionContracts.formConfirm.hasDeclared' | translate }}
    </p>
    <div class="col-xs-12 col-sm-6 col-sm-offset-3 col-lg-4 col-lg-offset-4 margin-top-lg-xs margin-bottom-lg-xs">
      <a
        class="btn btn-default dosis-bold padding-left-md-xs padding-right-md-xs padding-top-sm-xs padding-bottom-sm-xs background-blue3-xs text-uppercase text-12-xs text-white-xs border-radius-none-xs"
        (click)="declareANew()"
      >
        <i class="fa fa-plus-square margin-right-sm-xs text-white-xs"></i>
        {{ 'home.contracts.list.noContributionContracts.formConfirm.addMandateButton' | translate }}
      </a>
    </div>
  </div>
</div>
