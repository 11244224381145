import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@core/models';
import { ROLE } from '@core/models/role.model';
import { SessionService } from '@core/session/session.service';

@Component({
  selector: 'n9-accounts',
  templateUrl: './accounts.component.html'
})
export class AccountsComponent implements OnInit {
  constructor(private sessionService: SessionService, private router: Router) {}

  public ngOnInit(): void {
    const user: User = this.sessionService.getUser();

    if (!user.roles.includes(ROLE.ROLE_ADMIN)) {
      this.router.navigate(['admin', 'home']);
    }
  }
}
