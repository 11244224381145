import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import * as moment from 'moment';

import { Contract } from '@core/models';
import { ContractAction } from '../shared';

@Component({
  selector: 'n9-contract-card',
  templateUrl: 'contract-card.component.html'
})
export class ContractCardComponent implements OnInit {
  @Input() contract: Contract;
  @Input() actions: ContractAction[];
  @Input() isXs: boolean;
  @Output() actionOnContract: EventEmitter<ContractAction>;
  public mandateEndDate: Date;
  public displayContent: boolean;

  constructor() {
    this.actionOnContract = new EventEmitter();
    this.displayContent = false;
  }

  ngOnInit(): void {
    if (!this.contract.terminationDate) {
      this.mandateEndDate = new Date(this.contract.subscriptionDate);
      this.mandateEndDate.setFullYear(this.mandateEndDate.getFullYear() + 5);
    }
  }

  doAction(action: ContractAction): void {
    action.contract = this.contract;
    this.actionOnContract.emit(action);
  }

  public toggleContent(): void {
    this.displayContent = !this.displayContent;
  }

  public formatDate(date: string): string {
    return moment(date).format('DD/MM/YYYY');
  }
}
