import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ROLE } from '@core/models/role.model';
import { AccountsService } from '../../services/accounts.service';
import { AlphabeticValidatorDirective } from '@core/validators/alphabetic-validator.directive';
import { EmailValidatorDirective } from '@core/validators/email-validator.directive';
import { PasswordValidatorDirective } from '@core/validators/password-validator.directive';

const LOGIN_PREFIX = 'CAREL-';

@Component({
  selector: 'n9-create-carel-account',
  templateUrl: './create-carel-account.component.html',
  styleUrls: ['./create-carel-account.component.scss']
})
export class CreateCarelAccountComponent implements OnInit {
  @Output() userCreated: EventEmitter<boolean> = new EventEmitter<boolean>();
  errorMessage: string;
  localUserCreated: boolean = null;
  accountForm: FormGroup;
  availableRoles: object = {};
  keys: any = Object.keys;

  constructor(private accountsService: AccountsService, private fb: FormBuilder) {
    this.accountForm = this.fb.group({
      firstname: ['', [Validators.required, AlphabeticValidatorDirective.validInput]],
      lastname: ['', [Validators.required, AlphabeticValidatorDirective.validInput]],
      email: ['', [Validators.required, EmailValidatorDirective.validEmail]],
      login: [LOGIN_PREFIX + this.generateRandomId()],
      password: ['', [Validators.required, PasswordValidatorDirective.validInput]],
      roles: ['', [Validators.required]],
      city: 'N/A',
      country: 'N/A',
      phoneNumber: '0000000000',
      address: 'N/A',
      postalCode: '000000',
      title: 'Madame'
    });

    // tslint:disable-next-line:forin
    for (const role in ROLE) {
      if (role !== ROLE.ROLE_USER) this.availableRoles[role] = role.substr(5);
    }

    this.localUserCreated = null;
    this.userCreated.emit(this.localUserCreated);
  }

  public ngOnInit(): void {}

  public onSubmit(): void {
    const login = this.accountForm.get('login').value;
    const roles = this.accountForm.get('roles').value;

    typeof roles === 'string'
      ? this.accountForm.get('roles').setValue([roles])
      : this.accountForm.get('roles').setValue(roles);

    this.accountsService
      .createUserBO(this.accountForm.getRawValue())
      .take(1)
      .subscribe(
        () => {
          this.accountsService
            .updateUserBOPassword(login, this.accountForm.get('password').value)
            .take(1)
            .subscribe(
              () => this.updateUserCreationStatus(true),
              (err) => this.userNotCreatedException(err, 'admin.accounts.form.errors.password')
            );
        },
        (err) => this.userNotCreatedException(err, 'admin.accounts.form.errors.create')
      );
  }

  public generateRandomId(): string {
    return (Math.floor(Math.random() * 900000) + 100000).toString();
  }

  public userNotCreatedException(err: any, message?: string): void {
    this.updateUserCreationStatus(false, message);
    Observable.throwError(err);
  }

  public updateUserCreationStatus(status: boolean, message?: string): void {
    this.accountForm.get('login').setValue(LOGIN_PREFIX + this.generateRandomId());
    this.localUserCreated = status;
    this.errorMessage = message;
    this.userCreated.emit(this.localUserCreated);
  }
}
