import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PhoneNumberPipe } from '@core/pipes/phone-number.pipe';
import { EmailValidatorDirective } from '@core/validators/email-validator.directive';
import { SidebarElementComponent } from '@modules/admin/home/admin-sidebar/sidebar-element/sidebar-element.component';
import { CarelDropdownComponent } from './components/carel-dropdown/carel-dropdown.component';
import { InputPhoneComponent } from './components/form-inputs/input-phone/input-phone.component';
import { NgDatepickerComponent } from './components/form-inputs/ng-datepicker';
import { LoaderComponent } from './components/loader/loader.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { MessageDetailComponent } from './components/threads/messages/messages-detail/message-detail.component';
import { MessageListComponent } from './components/threads/messages/messages-list/message-list.component';
import { MessageNewComponent } from './components/threads/messages/messages-new/message-new.component';
import { MessagesComponent } from './components/threads/messages/messages.component';
import { ThreadsComponent } from './components/threads/threads.component';
import { SortArrowsComponent } from './sort-arrows/sort-arrows.component';

/*
 * Here put all the shared directives, components and pipe
 * You should import this module in every module where you want to use these shared directives
 * */
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    PaginationModule.forRoot(),
    CollapseModule,
    RouterModule,
    BsDropdownModule
  ],
  declarations: [
    LoaderComponent,
    EmailValidatorDirective,
    NgDatepickerComponent,
    PaginationComponent,
    MessageDetailComponent,
    MessageListComponent,
    MessagesComponent,
    MessageNewComponent,
    ThreadsComponent,
    SidebarElementComponent,
    CarelDropdownComponent,
    PhoneNumberPipe,
    InputPhoneComponent,
    SortArrowsComponent
  ],
  exports: [
    LoaderComponent,
    EmailValidatorDirective,
    NgDatepickerComponent,
    PaginationComponent,
    ReactiveFormsModule,
    TranslateModule,
    ThreadsComponent,
    MessageDetailComponent,
    MessageListComponent,
    MessagesComponent,
    MessageNewComponent,
    SidebarElementComponent,
    CarelDropdownComponent,
    PhoneNumberPipe,
    InputPhoneComponent
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule
    };
  }
}
