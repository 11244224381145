import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ConfigurationService } from '../services/conf/configuration.service';
import { noop } from '../utils/misc.util';
import { SessionService } from './session.service';

@Injectable()
export class MaintenanceGuard implements CanActivate {
  constructor(private router: Router, private conf: ConfigurationService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean | UrlTree {
    const maintenance: boolean = Boolean(this.conf.getAsString('maintenance'));

    if (maintenance && !state.url.includes('maintenance')) {
      return this.router.createUrlTree(['maintenance']);
    } else if (!maintenance && state.url.includes('maintenance')) {
      return this.router.createUrlTree(['login']);
    }
    return true;
  }
}
