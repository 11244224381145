import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Contract, User } from '../models';
import { SessionService } from '../session/session.service';

@Injectable()
export class ProfileResolver implements Resolve<any> {
  constructor(private http: HttpClient, private sessionService: SessionService) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    if (this.sessionService.getSession() && !this.sessionService.getUser()) {
      return this.http.get('/me').pipe(
        map((user: User) => {
          return user;
        })
      );
    }

    return of();
  }
}
