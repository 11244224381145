import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { SubscriptionService } from './subscription.service';

@Component({
  selector: 'n9-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent {
  @Input() isMembership: boolean;

  labels: string[] = [
    'subscription.common.labels.subscriber',
    'subscription.common.labels.mandates',
    'subscription.common.labels.signature',
    'subscription.common.labels.confirm'
  ];
  subscriber: FormGroup;
  isProduction: boolean;

  constructor(public subscriptionService: SubscriptionService) {
    this.isProduction = environment.production;
  }

  public flushCache(): void {
    localStorage.clear();
    window.location.reload();
  }
}
