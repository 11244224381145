import { Component, ElementRef, OnInit, ViewChild, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ThreadMessage } from './thread-message.models';
import { MessageThreadsService } from './message-threads.service';
import { Message } from './message.models';
import { User } from '@core/models';
import { SessionService } from '@core/session/session.service';
import { saveAs } from 'file-saver';
import { ROLE } from '@core/models/role.model';
import { USER_REQUEST_STATUS } from '@core/models/request.model';

const keyup = {
  Enter: '',
  Ctrl: '',
  Alt: '',
  Shift: ''
};

@Component({
  selector: 'n9-threads',
  templateUrl: './threads.component.html',
  styleUrls: ['./threads.component.scss']
})
export class ThreadsComponent implements OnInit {
  @Input() from: string;
  @Input() to: string;
  @Input() threadId: string;
  @ViewChild('threadMessages') threadMessages: ElementRef;
  @ViewChild('threadTextarea') threadTextarea: ElementRef;
  messageForm: FormGroup;
  user: User;
  uploadedFile: File;
  image: string;
  isThreadClosed: boolean;
  messages$: Observable<Message[]>;
  events: object;
  messageOffsetMilliseconds: number = 10 * 60 * 1000;

  constructor(
    private fb: FormBuilder,
    private threadsService: MessageThreadsService,
    private sessionService: SessionService
  ) {
    this.messageForm = this.fb.group({
      content: ['', [Validators.required]],
      attachments: ['']
    });
    this.user = sessionService.getUser();
  }

  ngOnInit(): void {
    this.messageForm.get('content').valueChanges.subscribe((message: string) => {
      const textarea = this.threadTextarea.nativeElement;
      const container = this.threadMessages.nativeElement;
      const sizediff = textarea.clientHeight - textarea.scrollHeight;
      const containerSize = parseInt(container.clientHeight, 10) + sizediff + 1;

      if (sizediff && containerSize > 300) {
        textarea.style.height = textarea.scrollHeight;
        container.style.height = container.offsetHeight + sizediff;
        this.scrollThreadContainer();
      }
      if (!message) {
        textarea.style.height = 'initial';
        container.style.height = '45vh';
      }
    });

    this.events = {
      Enter: ($event: KeyboardEvent) => {
        $event.preventDefault();
        this.postMessage();
      }
    };

    this.messages$ = this.getMessages(0, 200);
    this.messages$.subscribe(() => setTimeout(() => this.scrollThreadContainer(), 100));
  }

  public getMessages(page: number, size: number): Observable<Message[]> {
    return this.threadsService
      .getMessagesFromThreadId(this.threadId, page, size)
      .map((threadMessage: ThreadMessage) => {
        this.isThreadClosed =
          threadMessage.state === USER_REQUEST_STATUS.DONE || threadMessage.state === USER_REQUEST_STATUS.REJECTED;
        return threadMessage.discussions.map((message) => {
          const messagesList: Message[] = threadMessage.discussions;
          const ref: Message = message as Message;
          const prev: Message = messagesList[messagesList.indexOf(ref) - 1];

          ref.createdDateTime = new Date(ref.createdDateTime);
          ref.lastModifiedDateTime = new Date(ref.lastModifiedDateTime);
          prev ? (ref.sameAuthor = ref.idSender === prev.idSender) : (ref.sameAuthor = false);
          prev
            ? (ref.dateDiff =
                ref.createdDateTime.getTime() - prev.createdDateTime.getTime() > this.messageOffsetMilliseconds)
            : (ref.dateDiff = false);

          return new Message(ref);
        });
      })
      .catch((err) => Observable.throwError(err));
  }

  public postMessage(): void {
    if (this.messageForm.valid) {
      const content: string = this.messageForm.get('content').value;
      if (this.uploadedFile) {
        this.threadsService.uploadImageFile(this.uploadedFile).subscribe((res) => {
          this.threadsService
            .postMessageToThread(this.threadId, {
              content,
              attachedFileIds: [res.id],
              attachedFileNames: [res.label]
            })
            .subscribe(
              () => {
                this.messages$ = this.getMessages(0, 200);
                this.messages$.subscribe(() => setTimeout(() => this.scrollThreadContainer(), 50));
              },
              (err) => Observable.throwError(err)
            );
        });
      } else {
        this.threadsService.postMessageToThread(this.threadId, { content }).subscribe(
          () => {
            this.messages$ = this.getMessages(0, 200);
            this.messages$.subscribe(() => setTimeout(() => this.scrollThreadContainer(), 50));
          },
          (err) => Observable.throwError(err)
        );
      }

      this.messageForm.get('content').setValue('');
      this.image = '';
      delete this.uploadedFile;
      this.threadTextarea.nativeElement.focus();
    }
  }

  public handleTextareaEvents($event: KeyboardEvent): void {
    keyup.Ctrl = $event.ctrlKey ? 'Ctrl' : '';
    keyup.Alt = $event.altKey ? 'Alt' : '';
    keyup.Shift = $event.shiftKey ? 'Shift' : '';

    const eventString: string = keyup.Ctrl + keyup.Alt + keyup.Shift + $event.key;

    if (this.events[eventString]) this.events[eventString]($event);
  }

  public scrollThreadContainer(): void {
    const container = this.threadMessages.nativeElement;

    container.scrollTop = container.scrollHeight;
  }

  public getFile(file: FileList): void {
    this.image = '';
    const fil: File = file.item(0);
    if (fil.type.match('image/.+')) this.toBase64(fil);
    this.uploadedFile = fil;
  }

  public toBase64(file: File): void {
    const fileReader: FileReader = new FileReader();

    fileReader.onload = (e: any) => {
      this.image = e.target.result;
    };
    fileReader.readAsDataURL(file);
  }
  public downloadFile(id: string): void {
    this.threadsService.downloadImageFile(id).subscribe(
      (res) => {
        saveAs(res.body, res.headers.get('x-filename'));
      },
      (err) => {
        Observable.throwError(err);
      }
    );
  }

  public authorIsConnected(message: Message): boolean {
    if (this.user.roles.includes(ROLE.ROLE_USER)) return !message.isCarelSender;

    return message.isCarelSender;
  }
}
