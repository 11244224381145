import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '@core/models';

@Injectable()
export class AccountsService {
  constructor(private http: HttpClient) {}

  public getUsersBO(page: number = 0, size: number = 10): Observable<object> {
    return this.http
      .get(`/users?bo=true&page=${page}&size=${size}`)
      .map((users) => users)
      .catch((err) => Observable.throwError(err));
  }

  public getUserBOByLogin(login: string): Observable<object> {
    return this.http
      .get('/users/bo/' + login)
      .map((user) => user)
      .catch((err) => Observable.throwError(err));
  }

  public updateUserBo(userBo: User): Observable<object> {
    return this.http
      .post('/users/bo/update', userBo)
      .map((user) => user)
      .catch((err) => Observable.throwError(err));
  }

  public createUserBO(user: object): Observable<object> {
    return this.http
      .post('/users/bo', user)
      .map((res) => res)
      .catch((err) => Observable.throwError(err));
  }

  public updateUserBOPassword(login: string, password: string): Observable<object> {
    return this.http
      .post('/users/password?login=' + login, password)
      .map((res) => res)
      .catch((err) => Observable.throwError(err));
  }

  public deleteUserByLogin(login: string, isBo: boolean): Observable<object> {
    return this.http
      .delete('/users/' + login + '?bo=' + isBo)
      .map((res) => res)
      .catch((err) => Observable.throwError(err));
  }
}
