import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

import { Action, ActionType, User } from '@core/models';
import { ActionService } from '@shared/services/action.service';
import { AdminNavigationService } from '@modules/admin/home/shared/admin-navigation.service';

interface Change {
  label: string;
  oldValue: any;
  newValue: any;
}

interface Duration {
  hours: number;
  minutes: number;
}

@Component({
  selector: 'n9-user-actions',
  templateUrl: 'user-actions.component.html'
})
export class UserActionsComponent implements OnInit {
  @Input() public user: User;
  public userChanges: Change[];

  public userEdit: Action;
  public mandateDeclarations: Action[];
  public contractChanges: Action[];
  public connexionDuration: Duration;
  public connexionDate: string;

  // region constructor and init
  constructor(private actionService: ActionService, private navigation: AdminNavigationService) {}

  public ngOnInit(): void {
    this.userEdit = null;
    this.mandateDeclarations = [];
    this.userChanges = [];

    this.actionService.getUserActions(this.user.id, null).subscribe(
      (res: Action[]) => {
        this.userEdit = res.find((action) => action.type === ActionType[ActionType.USER_EDIT]);
        this.mandateDeclarations = res.filter((action) => action.type === ActionType[ActionType.MANDATE_DECLARE]);
        this.contractChanges = res.filter((action) => action.type === ActionType[ActionType.CONTRIBUTION_RATE]);

        const loginActions: Action[] = res.filter((action) => action.type === ActionType[ActionType.LOG_IN]);
        const loginOutActions: Action[] = res.filter((action) => action.type === ActionType[ActionType.LOG_OUT]);
        const mostRecentLogin = loginActions.length ? loginActions[0] : null;
        const mostRecentLogOut = loginOutActions.length ? loginOutActions[0] : null;

        this.connexionDate = moment(mostRecentLogin.createdDateTime).format('DD/MM/YYYY HH:mm');
        this.connexionDuration = this.determineConnexionDuration(mostRecentLogin, mostRecentLogOut);

        if (this.userEdit && this.userEdit !== null) this.determineChanges(this.user, this.userEdit.newUserData);
      },
      (error) => this.handleError(error)
    );
  }
  // endregion

  public formatDate(date: string): string {
    return moment(date).format('DD/MM/YYYY');
  }

  public validateAction(actionId: string): void {
    this.actionService.validateAction(actionId).subscribe((res) => this.ngOnInit());
  }

  public refuseAction(actionId: string): void {
    this.actionService.refuseAction(actionId).subscribe((res) => this.ngOnInit());
  }

  // region private methods
  private determineChanges(currentUser: User, updatedUser: User): void {
    if (currentUser.lastname !== updatedUser.lastname)
      this.userChanges.push({
        label: 'Nom',
        newValue: updatedUser.lastname,
        oldValue: currentUser.lastname
      });

    if (currentUser.marriedName !== updatedUser.marriedName)
      this.userChanges.push({
        label: 'Nom de marriage',
        newValue: updatedUser.marriedName,
        oldValue: currentUser.marriedName
      });

    if (currentUser.firstname !== updatedUser.firstname)
      this.userChanges.push({
        label: 'Prénom',
        newValue: updatedUser.firstname,
        oldValue: currentUser.firstname
      });

    if (currentUser.phoneNumber !== updatedUser.phoneNumber)
      this.userChanges.push({
        label: 'Numéro de telephone',
        newValue: updatedUser.phoneNumber,
        oldValue: currentUser.phoneNumber
      });

    if (currentUser.email !== updatedUser.email)
      this.userChanges.push({
        label: 'Email',
        newValue: updatedUser.email,
        oldValue: currentUser.email
      });

    if (currentUser.address !== updatedUser.address)
      this.userChanges.push({
        label: 'Adresse',
        newValue: updatedUser.address,
        oldValue: currentUser.address
      });

    if (currentUser.complementaryAddress !== updatedUser.complementaryAddress)
      this.userChanges.push({
        label: "Complément d'adresse",
        newValue: updatedUser.complementaryAddress,
        oldValue: currentUser.complementaryAddress
      });

    if (updatedUser.password)
      this.userChanges.push({
        label: 'Mot de passe',
        newValue: updatedUser.marriedName,
        oldValue: ''
      });

    if (currentUser.postalCode !== updatedUser.postalCode)
      this.userChanges.push({
        label: 'Code Postal',
        newValue: updatedUser.postalCode,
        oldValue: currentUser.postalCode
      });

    if (currentUser.city !== updatedUser.city)
      this.userChanges.push({
        label: 'Ville',
        newValue: updatedUser.city,
        oldValue: currentUser.city
      });

    if (currentUser.country !== updatedUser.country)
      this.userChanges.push({
        label: 'Pays',
        newValue: updatedUser.country,
        oldValue: currentUser.country
      });

    if (currentUser.birthDate !== updatedUser.birthDate)
      this.userChanges.push({
        label: 'Date de naissance',
        newValue: moment(updatedUser.birthDate).format('DD/MM/YYYY'),
        oldValue: moment(currentUser.birthDate).format('DD/MM/YYYY')
      });
  }

  private determineConnexionDuration(login: Action, logout: Action): Duration {
    const loginDate: any = moment(login.createdDateTime);
    let logoutDate: any = moment(logout.createdDateTime);

    if (loginDate.isAfter(logoutDate)) logoutDate = moment();

    const difference = logoutDate.toDate() - loginDate.toDate();
    let minutes: number = Math.round(difference / (1000 * 60));
    let hours: number = minutes / 60;

    minutes = Math.round((hours - Math.round(hours)) * 60);
    hours = Math.round(hours);

    if (minutes < 0) {
      hours -= 1;
      minutes = 60 + minutes;
    }

    return {
      hours: Math.round(hours),
      minutes
    };
  }

  private handleError(error: any): void {
    if (error.status) {
      if (error.status === 401 || error.status === 403) {
        this.navigation.navigateToLogin();
        return;
      }
    }

    this.navigation.navigate('users');
  }
  // endregion
}
